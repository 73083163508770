import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchAppUsersViews, GetBranchAppUserTags } from '../../../queries';
import { BranchAppUser } from '../../Store/BranchBilling/types';
import { BranchAppUserTag } from '../../Store/BranchTags/types';
import Common from '../../Store/Common';
import { RecordsContainer } from '../../styled';
import BranchAppUserRecord from './BranchAppUserRecord';
import useURLSearchParams from '../../../hooks/useURLSearchParams';
import PetsClientsHeader from '../PetsClients/PetsClientsHeader';
import useAppUsersListBulkActions from '../../../hooks/useAppUsersListBulkActions';
import { getBranchAppUsersFiltersVariables, useBranchAppUsersFilters } from '../PetsClients/BranchAppUsersFilters';
import { ListCount } from '../../../components/Shared/Forms/Forms';

const BranchAppUsers = () => {
  const [status] = useURLSearchParams('status') || ['ACTIVE', 'ARCHIVED'];
  const filtersControl = useBranchAppUsersFilters();
  const { selectedFilters } = filtersControl;
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<{
    views: BranchAppUser[];
    count: number;
  }>({
    query: GetBranchAppUsersViews,
    otherVariables: {
      branchAppUserConnection_status: status.length ? status : ['ACTIVE', 'ARCHIVED'],
      ...getBranchAppUsersFiltersVariables({
        selectedFilters
      })
    }
  });

  const { data: { getBranchAppUsersViews: branchAppUsers } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchAppUsers.GetBranchAppUsers.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-only',
    variables: { offset: 0, limit: 1000 }
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];
  const tagsVisibleOnItem = allTags?.filter(tag => tag?.visibility?.showOnTaggedItem) || [];

  const { selectAction, selectAllAction, bulkSelect } = useAppUsersListBulkActions({
    getAppUsers: () => branchAppUsers?.views || []
  });

  return (
    <>
      <PetsClientsHeader
        middleHeaderAction={
          <>
            {selectAction}
            {selectAllAction}
          </>
        }
        filtersControl={filtersControl}
      />
      <ListCount>{branchAppUsers?.count || 0} Clients</ListCount>
      <RecordsContainer>
        <InfiniteList
          list={branchAppUsers?.views}
          itemRenderer={(appUser: BranchAppUser) => (
            <BranchAppUserRecord key={appUser.id} branchAppUser={appUser} appUserQuickTags={quickTags} tagsVisibleOnItem={tagsVisibleOnItem} bulkSelect={bulkSelect} />
          )}
          hasMoreItems={hasMoreItems}
          loading={loading}
          fetchMore={fetchMore}
          offset={branchAppUsers?.views?.length}
          setHasMoreItems={setHasMoreItems}
        />
      </RecordsContainer>
    </>
  );
};

export default BranchAppUsers;
