import React from 'react';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { isProductNoCharge, isProductPostpaid } from '../ProductPrice';
import { CHARGE_STATUS, chargeStatuses, productStatuses } from '../types';
import { Booking } from '../../Bookings/components/BookingsBody/styled';
import { BtnContainer } from '../../../components/Shared/ToolBar/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../../hooks/useIcons';
import ModalDialog from '../../../components/Modal/ModalDialog';
import StoreProductModal from '../Operations/StoreProductModal/StoreProductModal';

const ServiceRecord = ({ service, navigateDrawer }) => {
  const icons = useIcons();
  const categories = service?.ProductCategories.map(category => category?.name).join(',');

  const defaultChargeStatus = isProductNoCharge(service) ? CHARGE_STATUS.NO_CHARGE : isProductPostpaid(service) ? CHARGE_STATUS.CHARGE_LATER : CHARGE_STATUS.CHARGE_NOW;

  const chargeStatus = chargeStatuses[defaultChargeStatus]?.label;
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <StoreProductModal productId={service.id} type={service.type} />,
      title: service?.name
    });
  };
  const price = isProductNoCharge(service) ? 'Free' : getBranchCurrencySymbol() + service?.price;
  const status = productStatuses.find(status => status.value === service?.status)?.availablality;
  return (
    <>
      <Booking>
        <RecordBody fontWeight={'800'}>{service?.name}</RecordBody>
        <RecordBody>{price}</RecordBody>
        <RecordBody>{chargeStatus}</RecordBody>
        <RecordBody>{status}</RecordBody>
        <RecordBody>{categories}</RecordBody>
        <HoverRecordOptionsContainer>
          <BtnContainer onClick={handleClick}>
            <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
          </BtnContainer>
        </HoverRecordOptionsContainer>
      </Booking>
      <Divider />
    </>
  );
};

export default ServiceRecord;
