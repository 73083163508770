import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import PetRecord from '../../../components/Pets/PetRecord';
import { Pet } from '../../../components/Pets/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import usePetsListBulkActions from '../../../hooks/usePetsListBulkActions';
import { GetBranchPetRecordTags, GetBranchPetsViews, GetBusUserProfile } from '../../../queries';
import { vars } from '../../../reactive';
import { calculateAgeFromBirthdate } from '../../../utils/dates';
import { areObjectsEqual } from '../../../utils/helpers';
import { BranchPetRecordTag } from '../../Store/BranchTags/types';
import Common from '../../Store/Common';
import { PETS_TABS_TYPES } from '../Pets';
import { getPetsVariables, usePetsFilters } from '../PetsFilters';
import useURLSearchParams from '../../../hooks/useURLSearchParams';
import PetsHeader from '../PetsHeader/PetsHeader';
import AddClientPetAction from '../AddClientAction';
import { FormLabel, ListCount } from '../../../components/Shared/Forms/Forms';

const PetsBody = ({ selected }: { selected: PETS_TABS_TYPES }) => {
  const filtersControl = usePetsFilters();
  const { selectedFilters } = filtersControl;
  const [selctedPetType] = useURLSearchParams('pet_type');
  const isActive = selected === PETS_TABS_TYPES.ACTIVE.toLowerCase();
  const petsVariables = useReactiveVar(vars.petsVariables);
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<{ views: Pet[]; count: number }>({
    query: GetBranchPetsViews,
    limit: 20,
    otherVariables: getPetsVariables({
      defaultVariables: {
        daycare_filter: false,
        vaccination_filter: false,
        treatment_filter: false,
        weight_filter: false,
        neutering_filter: false,
        age_filter: false,
        requisite_queries: [...(petsVariables?.pet_name ? ['pet_name'] : [])],
        alternative_queries: [],
        branchPetRecordConnection_status: isActive ? ['ACTIVE'] : ['ARCHIVED'],
        ...petsVariables
      },
      selectedFilters,
      overriddenFilters: {
        pet_type: selctedPetType?.length ? selctedPetType : undefined
      }
    })
  });

  const { data: { getBranchPetsViews: { count = 0, views: pets = [] } = {} } = {}, loading, fetchMore, refetch } = queryResult;

  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);

  const { data: { getBranchPetRecordTags: allTags = [] } = {} } = useQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];
  const tagsVisibleOnItem = allTags?.filter(tag => tag?.visibility?.showOnTaggedItem) || [];

  useEffect(() => {
    Common.set(`Pets.GetBranchPets.refetch`, (...args: any[]) => {
      return new Promise(async res => {
        await refetch?.(...args).then(() => setHasMoreItems(true));
        res(0);
      });
    });
  }, []);

  const petsList = pets?.map((pet: any) => ({ ...pet, age: calculateAgeFromBirthdate(pet?.birthdate) }));

  const { bulkSelect, selectAction, selectAllAction } = usePetsListBulkActions({
    getPets: () => petsList
  });

  const action = (
    <>
      {selectAction}
      {selectAllAction}
      <AddClientPetAction />
    </>
  );

  return (
    <>
      <PetsHeader filtersControl={filtersControl} selectedTab={selected} middleHeaderAction={action} />
      <ListCount>{count || 0} Pets</ListCount>
      <InfiniteList
        paddingL={20}
        paddingR={20}
        list={petsList}
        itemRenderer={(pet: Pet) => (
          <PetRecord key={pet?.id} pet={pet} profile={profile} quickTags={quickTags} bulkSelect={bulkSelect} selectedFilters={selectedFilters} tagsVisibleOnItem={tagsVisibleOnItem} />
        )}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore as any}
        offset={pets?.length}
        setHasMoreItems={setHasMoreItems}
        mobilePadding="8px 16px 0 16px"
      />
    </>
  );
};

export default React.memo(PetsBody, areObjectsEqual);
