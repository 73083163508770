import { useQuery } from '@apollo/client';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import RRule from 'rrule';
import { LongPressDetectEvents, useLongPress } from 'use-long-press';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Adherence, MedCond, Pet, SuppRecord as SuppRecordType, VaccRecord as VaccRecordType } from '../../../components/Pets/types';
import { BusUserProfile } from '../../../components/Profile/types';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { GetPetRecordRecords } from '../../../queries';
import { compareDatesWithoutHours, convertDateToOverdue, getNextTimestampForSuppRecord, getStartOfToday, toDateInput, toReadableDate } from '../../../utils/dates';
import EndSuppRecordsModal from '../Reports/Medical/EndSuppRecordsModal';
import EndVaccinationsModal from '../Reports/Medical/EndVaccinationsModal';
import MedicationModal from '../Reports/Medical/MedicationModal';
import TreatmentsModal from '../Reports/Medical/TreatmentsModal';
import VaccinationModal from '../Reports/Medical/VaccinationModal';
import VaccinationsModal from '../Reports/Medical/VaccinationsModal';
import { getUniqueSuppRecords } from '../Reports/Medical/helpers';
import {
  ColorTag,
  Divider,
  HealthMeasurementTitle,
  PetRecordContainer,
  PetRecordDate,
  PetRecordInfoContainer,
  PetRecordRecordsOptions,
  PetRecordWrapper,
  PetRecordsContainer,
  PetReportName,
  RecordsSectionContainer,
  SectionContainer,
  SuppRecordContainer,
  VaccRecordContainer
} from './styled';

const frequencies = [
  { id: 3, name: 'days' },
  { id: 2, name: 'weeks' },
  { id: 1, name: 'months' },
  { id: 0, name: 'years' }
];

type HealthPetRecordsProps = {
  pet: Pet;
};

type VaccRecordProps = {
  vaccRecord: VaccRecordType;
  medCond?: MedCond;
  pet?: Pet;
  style?: React.CSSProperties;
};

export const VaccRecord: FC<VaccRecordProps> = ({ medCond, vaccRecord, pet, style }) => {
  const isDateDue = vaccRecord?.nextdate && new Date(vaccRecord?.nextdate).getTime() < new Date().getTime();
  const dateString = isDateDue ? `Overdue by ${convertDateToOverdue(vaccRecord?.nextdate)}` : `Valid until ${toDateInput(vaccRecord?.nextdate || new Date())}`;
  const lastShotDate = toReadableDate(new Date(vaccRecord?.dates?.[vaccRecord?.dates?.length - 1]) || new Date(), { noTime: true, isLocale: true });
  const createdAtDate = toReadableDate(new Date(vaccRecord?.createdAt) || new Date(), { noTime: true, isLocale: true });
  const createdByBranch = !!vaccRecord?.MedConds?.length;

  const defaultDescriptionState = 0;
  const [descriptionState, setDescriptionState] = useState(defaultDescriptionState);
  const descriptionStateLength = 3;

  const longPressBind = useLongPress(() => longPressCallback(), {
    threshold: 3000,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH
  });

  const optionsRef = useRef<HTMLDivElement>(null);

  const longPressCallback = useCallback(() => {
    if (pet) {
      ModalDialog.openModal({
        content: () => <VaccinationModal vaccRecord={vaccRecord} petId={pet?.id} medCond={medCond} />,
        title: 'Edit Vaccination'
      });
    }
  }, [vaccRecord, pet]);

  const toggleDescription = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (optionsRef?.current?.contains(e.target as Node)) {
        return;
      }
      const newDescriptionState = () => {
        if (descriptionState === descriptionStateLength - 1) {
          return defaultDescriptionState;
        }
        if (descriptionState === 0 && !vaccRecord?.nextdate) {
          return descriptionStateLength - 1;
        }

        return descriptionState + 1;
      };

      setDescriptionState(newDescriptionState());
    },
    [descriptionState, descriptionStateLength]
  );

  return (
    <PetRecordContainer due={isDateDue} {...longPressBind()} style={style}>
      <VaccRecordContainer onClick={toggleDescription}>
        <ColorTag color={vaccRecord?.color} />
        <PetRecordInfoContainer>
          <PetReportName>
            <PetReportName>{vaccRecord?.VaccRecordType?.name}</PetReportName>
            <PetReportName fontWeight={500} color="#212121;">
              {vaccRecord?.name}
            </PetReportName>
          </PetReportName>
          {descriptionState === 0 && (
            <>
              {vaccRecord?.nextdate && (
                <PetRecordDate green={!isDateDue} due={isDateDue}>
                  {dateString}
                </PetRecordDate>
              )}
              {!vaccRecord?.nextdate && <PetRecordDate>Last shot: {lastShotDate}</PetRecordDate>}
            </>
          )}

          {descriptionState === 1 && (
            <PetRecordDate>
              {vaccRecord?.batch_number} - {lastShotDate}
            </PetRecordDate>
          )}

          {descriptionState === 2 && (
            <PetRecordDate>
              By {createdByBranch ? vaccRecord?.MedConds?.[0]?.Branch?.name : `${pet?.name}'s owner`} on {createdAtDate}
            </PetRecordDate>
          )}
        </PetRecordInfoContainer>
      </VaccRecordContainer>
    </PetRecordContainer>
  );
};

const getCountText = (suppRecordCount: string) => {
  const count = Number(suppRecordCount);
  if (count === 1) {
    return 'once';
  }

  if (count === 2) {
    return 'twice';
  }

  return `${count} times`;
};

export const SuppRecord = ({ suppRecord, pet, style }: { suppRecord: SuppRecordType; pet: Pet; style?: React.CSSProperties }) => {
  const lastAdherenceDate = suppRecord?.Adherences?.length && new Date(suppRecord?.Adherences?.[0]?.timestamp);
  const nextDate = suppRecord?.nextdate;
  const hasEndDate = !!suppRecord?.end_date;
  const cycleEnded = hasEndDate && new Date(suppRecord?.end_date) <= new Date();
  const rruleTime = RRule.fromString(suppRecord?.time || '');
  const frequency = suppRecord?.time && frequencies.find(freq => freq.id === rruleTime?.options?.freq)?.name;
  const countText = getCountText(suppRecord?.count);
  const unitText = suppRecord?.Product?.unit ? suppRecord?.Product?.unit?.toLowerCase() : 'dose';
  const quantityText = `${suppRecord.quantity} ${Number(suppRecord.quantity) === 1 ? `(${unitText})` : `${unitText}s`}`;

  const isDateDue = useMemo(() => {
    const today = getStartOfToday();
    if (cycleEnded) {
      return false;
    }
    if (!nextDate) {
      if (!frequency || !suppRecord.adherences_allowed) {
        return false;
      }
      const nextDate = getNextTimestampForSuppRecord(suppRecord);
      return compareDatesWithoutHours(today, nextDate);
    }

    return compareDatesWithoutHours(today, nextDate);
  }, [frequency, nextDate, lastAdherenceDate, cycleEnded]);

  const dateString = isDateDue ? `Overdue by ${convertDateToOverdue(nextDate)}` : `Due next on ${toDateInput(nextDate || new Date())}`;
  const createdAtDateString = toReadableDate(new Date(suppRecord.createdAt) || new Date(), { noTime: true, isLocale: true });
  const lastAdherenceDateString = toReadableDate(lastAdherenceDate || new Date(), { noTime: true, isLocale: true });
  const createdByBranch = !!suppRecord?.MedConds?.length;

  const defaultDescriptionState = !hasEndDate && nextDate ? 0 : 1;
  const [descriptionState, setDescriptionState] = useState(defaultDescriptionState);
  const descriptionStateLength = 4;

  const longPressBind = useLongPress(() => longPressCallback(), {
    threshold: 3000,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH
  });

  const longPressCallback = useCallback(() => {
    if (suppRecord?.MedConds?.[0]?.id) {
      ModalDialog.openModal({
        content: () => <MedicationModal pet={pet} suppRecord={suppRecord} />,
        title: 'Edit Medication'
      });
    }
  }, [suppRecord]);

  const createdByString = `By ${createdByBranch ? suppRecord?.MedConds?.[0]?.Branch?.name : `${pet?.name}'s owner`}`;

  const productKindName = suppRecord?.Product?.ProductKinds?.[0]?.name;

  const productName = suppRecord?.Product?.name;
  const frequencyText = frequency ? `${quantityText} ${countText} every ${rruleTime.options.interval} ${frequency}` : suppRecord.adherences_allowed ? 'As Needed' : 'One-Off';

  const cycleEndText = `${cycleEnded ? 'Cycle ended on' : 'Cycle Ends on'}  ${toReadableDate(new Date(suppRecord?.end_date) || new Date(), { noTime: true, isLocale: true })}`;

  const mainDescription = cycleEnded ? cycleEndText : `${frequencyText} ${!!suppRecord?.Adherences?.length ? `- ${lastAdherenceDateString}` : ''}`;

  const toggleDescription = useCallback(() => {
    setDescriptionState(prev => {
      const isLast = hasEndDate && !cycleEnded ? prev === descriptionStateLength - 1 : prev === descriptionStateLength - 2;
      if (isLast) {
        return defaultDescriptionState;
      }

      return prev + 1;
    });
  }, [descriptionState, descriptionStateLength]);

  return (
    <PetRecordContainer due={isDateDue} {...longPressBind()} style={style}>
      <SuppRecordContainer onClick={toggleDescription}>
        <PetRecordInfoContainer>
          <PetReportName>
            <strong>{productKindName}</strong> {productName}
          </PetReportName>

          {descriptionState === 0 && (
            <PetRecordDate green={!isDateDue} due={isDateDue}>
              {dateString}
            </PetRecordDate>
          )}

          {descriptionState === 1 && (
            <PetRecordDate green={!isDateDue} due={isDateDue}>
              {mainDescription}
            </PetRecordDate>
          )}

          {descriptionState === 2 && (
            <PetRecordDate>
              {createdByString} - {createdAtDateString}
            </PetRecordDate>
          )}

          {descriptionState === 3 && <PetRecordDate>{cycleEndText}</PetRecordDate>}
        </PetRecordInfoContainer>
      </SuppRecordContainer>
    </PetRecordContainer>
  );
};

export const PrintSuppRecord = ({
  suppRecord,
  pet,
  busUserProfile,
  onChange,
  data,
  editable
}: {
  suppRecord: SuppRecordType;
  pet: Pet;
  busUserProfile?: BusUserProfile;
  onChange?: (params: (prev: Record<string, any>) => Record<string, any>) => void;
  data?: Record<string, any>;
  editable?: boolean;
}) => {
  const rruleTime = RRule.fromString(suppRecord?.time || '');
  const frequency = suppRecord?.time && frequencies.find(freq => freq.id === rruleTime?.options?.freq)?.name;
  const countText = getCountText(suppRecord?.count);
  const unitText = suppRecord?.Product?.unit ? suppRecord?.Product?.unit?.toLowerCase() : 'dose';
  const quantityText = `${suppRecord.quantity} ${Number(suppRecord.quantity) === 1 ? `(${unitText})` : `${unitText}s`}`;
  const createdByBranch = !!suppRecord?.MedConds?.length;
  const appUserPetNameString = `${pet?.AppUser?.name} for ${pet?.name}`;
  const frequencyString = frequency ? `${quantityText} ${countText} every ${rruleTime.options.interval} ${frequency}` : '';
  const appUserAddress = pet?.AppUser?.addresses?.[0] || {};
  const appUserAddressString = `${appUserAddress.line1} ${appUserAddress.city} ${pet?.AppUser?.postcode}`;
  const createdByString = `By ${createdByBranch ? suppRecord?.MedConds?.[0]?.Branch?.name : `${pet?.name}'s owner`}`;
  const productName = suppRecord?.Product?.name;
  const createdAtDateString = toReadableDate(new Date(suppRecord.createdAt) || new Date(), { noTime: true, isLocale: true });
  const branchAddress = busUserProfile?.Branch?.address || {};
  const branchAddressString = `${branchAddress?.street} ${branchAddress?.city} ${branchAddress?.postcode}`;

  const onInput = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const newValue = e.target?.value;
    onChange?.(prev => ({ ...prev, [key]: newValue }));
  };

  const productDisclaimer = suppRecord?.Product?.additional_info?.payment_description || '';

  const animalDisclaimer = 'For animal treatment only - Keep away from children';
  const tropicalUse = '';

  const dataToShow = {
    appUserPetNameString: data?.appUserPetNameString ?? appUserPetNameString,
    appUserAddressString: data?.appUserAddressString ?? appUserAddressString,
    productName: data?.productName ?? productName,
    frequencyString: data?.frequencyString ?? frequencyString,
    createdByString: data?.createdByString ?? createdByString,
    createdAtDateString: data?.createdAtDateString ?? createdAtDateString,
    branchAddressString: data?.branchAddressString ?? branchAddressString,
    productDisclaimer: data?.productDisclaimer ?? productDisclaimer,
    animalDisclaimer: data?.animalDisclaimer ?? animalDisclaimer,
    tropicalUse: data?.tropicalUse ?? tropicalUse
  };

  if (editable) {
    return (
      <div id="print-container">
        <input id="print-1" defaultValue={dataToShow.appUserPetNameString} onChange={e => onInput(e, 'appUserPetNameString')} />
        <input id="print-3" defaultValue={dataToShow.appUserAddressString} onChange={e => onInput(e, 'appUserAddressString')} />
        <input id="print-1" defaultValue={dataToShow.productName} onChange={e => onInput(e, 'productName')} />
        <input id="print-1" defaultValue={dataToShow.frequencyString} onChange={e => onInput(e, 'frequencyString')} />
        <input id="print-2" defaultValue={dataToShow.createdByString} onChange={e => onInput(e, 'createdByString')} />
        <input id="print-3" defaultValue={dataToShow.branchAddressString} onChange={e => onInput(e, 'branchAddressString')} />
        <input id="print-3" defaultValue={dataToShow.animalDisclaimer} onChange={e => onInput(e, 'animalDisclaimer')} />
        <input id="print-3" defaultValue={dataToShow.productDisclaimer} onChange={e => onInput(e, 'productDisclaimer')} />
        <input id="print-3" defaultValue={dataToShow.tropicalUse} onChange={e => onInput(e, 'tropicalUse')} />
      </div>
    );
  }

  return (
    <div id="print-container">
      <p id="print-1">{dataToShow.appUserPetNameString}</p>
      <p id="print-3">{dataToShow.appUserAddressString}</p>
      <p id="print-1">
        <strong>{dataToShow.productName}</strong>
      </p>
      <p id="print-1">
        <strong>{dataToShow.frequencyString}</strong>
      </p>
      <p id="print-2">
        {dataToShow.createdByString} on{' '}
        <span id="print-3" onInput={e => onInput(e, 'createdAtDateString')}>
          {dataToShow.createdAtDateString}
        </span>
      </p>
      <p id="print-3">{dataToShow.branchAddressString}</p>
      <p id="print-3">{dataToShow.animalDisclaimer}</p>
      <p id="print-3">{dataToShow.productDisclaimer}</p>
      <p id="print-3">{dataToShow.tropicalUse}</p>
    </div>
  );
};

const HealthPetRecords: FC<HealthPetRecordsProps> = ({ pet }) => {
  const { data: { getPetRecordRecords: { SuppRecords: suppRecords = [], VaccRecords: vaccRecords = [], Adherences: adherences = [] } = {} } = {}, loading: loadingRecords } = useQuery<{
    getPetRecordRecords: {
      SuppRecords: SuppRecordType[];
      VaccRecords: VaccRecordType[];
      Adherences: Adherence[];
    };
  }>(GetPetRecordRecords, {
    variables: {
      PetRecordId: pet?.PetRecord?.id,
      filter_by_active: true
    },
    fetchPolicy: 'cache-and-network'
  });

  const uniqueSupps = getUniqueSuppRecords({ adherences, suppRecords });

  const sortedVaccRecords = [...vaccRecords].sort((a, b) => {
    const aDate = new Date(a?.dates?.[a?.dates?.length - 1]);
    const bDate = new Date(b?.dates?.[b?.dates?.length - 1]);
    return bDate.getTime() - aDate.getTime();
  });

  const sortedSuppRecords = uniqueSupps
    ?.sort((a, b) => {
      const aDate = a?.Adherences?.length ? new Date(a?.Adherences?.[0]?.timestamp) : new Date(a?.createdAt);
      const bDate = b?.Adherences?.length ? new Date(b?.Adherences?.[0]?.timestamp) : new Date(b?.createdAt);
      return aDate.getTime() - bDate.getTime();
    })
    ?.filter(({ type }) => type === 'treatment');

  return (
    <>
      <SectionContainer>
        <PetRecordWrapper padding="0" gap={1}>
          <PetRecordWrapper>
            <PetRecordRecordsOptions>
              <HealthMeasurementTitle>{pet?.name}'s active vaccines</HealthMeasurementTitle>
              <OptionDropdown
                menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
                noApplyButton
                options={[
                  {
                    id: 'options',
                    optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                    items: [
                      {
                        name: 'Manage',
                        value: 'edit',
                        onClick: () => {
                          ModalDialog.openModal({
                            content: () => <EndVaccinationsModal PetRecordId={pet?.PetRecord?.id} />,
                            title: 'Manage Vaccines'
                          });
                        },
                        disabled: !sortedVaccRecords?.length
                      },
                      {
                        name: 'History',
                        value: 'history',
                        onClick: () => {
                          ModalDialog.openModal({
                            content: () => <VaccinationsModal PetRecordId={pet?.PetRecord?.id} />,
                            title: 'Vaccination History'
                          });
                        }
                      }
                    ]
                  }
                ]}
              />
            </PetRecordRecordsOptions>
            <RecordsSectionContainer>
              <PetRecordsContainer>
                {!loadingRecords && (
                  <>
                    {sortedVaccRecords?.map(vaccRecord => (
                      <VaccRecord vaccRecord={vaccRecord} pet={pet} key={vaccRecord.id} />
                    ))}
                    {!sortedVaccRecords?.length && <PetRecordDate>No active vaccines</PetRecordDate>}
                  </>
                )}
                {loadingRecords && <CenteredLoader size={40} />}
              </PetRecordsContainer>
            </RecordsSectionContainer>
          </PetRecordWrapper>

          <Divider />

          <PetRecordWrapper>
            <PetRecordRecordsOptions>
              <HealthMeasurementTitle>{pet?.name}'s active treatments</HealthMeasurementTitle>
              <OptionDropdown
                menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
                noApplyButton
                options={[
                  {
                    id: 'options',
                    optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                    items: [
                      {
                        name: 'Manage',
                        value: 'edit',
                        onClick: () => {
                          ModalDialog.openModal({
                            content: () => <EndSuppRecordsModal pet={pet} />,
                            title: 'Manage Treatments'
                          });
                        }
                      },
                      {
                        name: 'History',
                        value: 'history',
                        onClick: () => {
                          ModalDialog.openModal({
                            content: () => <TreatmentsModal pet={pet} />,
                            title: 'Treatments History'
                          });
                        }
                      }
                    ]
                  }
                ]}
              />
            </PetRecordRecordsOptions>
            <RecordsSectionContainer>
              <PetRecordsContainer>
                {!loadingRecords && (
                  <>
                    {sortedSuppRecords.map((suppRecord, index) => (
                      <SuppRecord suppRecord={suppRecord} pet={pet} key={index} />
                    ))}
                    {!sortedSuppRecords?.length && <PetRecordDate>No active treatments</PetRecordDate>}
                  </>
                )}
                {loadingRecords && <CenteredLoader size={40} />}
              </PetRecordsContainer>
            </RecordsSectionContainer>
          </PetRecordWrapper>
        </PetRecordWrapper>
      </SectionContainer>
    </>
  );
};

export default HealthPetRecords;
