import React, { useEffect, useId, useRef, useState } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfDay from 'date-fns/startOfDay';
import enGB from 'date-fns/locale/en-GB';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Calendar, CalendarProps, View, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import Colors from '../../../Colors';
import { dateInUTC, getMonthName, getStartOfDate, isToday, toDay, toThreeLetterWeekDay } from '../../../utils/dates';
import { BlockedCalendarEvent, CalendarEvent } from '../../../views/Bookings/types';
import { BusUserProfile } from '../../Profile/types';
import { CALENDAR_VIEWS, CALENDAR_VIEWS_NAMES, CALENDAR_VIEWS_TO_SHOW } from '../types';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { PetContainer } from '../../../views/Bookings/components/BookingRecord/styled';
import { PetImg } from '../../../views/Bookings/components/BookingsBody/styled';
import { RecordBody } from '../../../views/styled';
import { BusinessUserImageContainer, CalendarToolBarContainer, Divider } from '../../SideBar/styled';
import OptionDropdown from '../../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../Shared/Menus/OptionDropdown/types';
import { BranchSchedule } from '../../../views/Store/BranchSchedules/types';
import useIcons from '../../../hooks/useIcons';
import { useWindowSize } from '../../../hooks/useWindowResize';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GetBranchSchedules, GetBranchSchedulesSlots, GetBusUserProfile } from '../../../queries';
import { vars } from '../../../reactive';
import { logout } from '../../../utils/auth';
import { isUserSuperVisorOrAdminOrSuper } from '../../../utils/helpers';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  WeekDayTitleAndWeekDayContainer,
  WeekDayTitle,
  WeekDay,
  EventLabel,
  EventLabelsContainer,
  MonthLabel,
  StaffContainer,
  ToolbarButton,
  CounterBtn,
  ToolsContainer,
  SlotWrapperContainer,
  SlotWrapperAvailability
} from '../styled';
import ModalDialog from '../../Modal/ModalDialog';
import CalendarRange, { CalendarRangeRef, getRange } from '../CalendarRange';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { TheCalendarEvent, TheCalendarResource } from '../TheCalendar';
import { BranchAvailability } from '../../../views/Store/types';

const locales = {
  'en-GB': enGB
};

const MARGIN_HORIZONTAL = 250;
const ITEM_SIZE = 50;

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (
    date: number | Date,
    options?:
      | {
          locale?: Locale | undefined;
          weekStartsOn?: 0 | 1 | 2 | 3 | 5 | 6 | 4 | undefined;
        }
      | undefined
  ) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
  getDay,
  locales
});

function calcRange(view: string, date: Date) {
  let range = { from: date, to: date };
  if (view === 'day') {
    range = { from: startOfDay(date), to: endOfDay(date) };
  } else if (view === 'week') {
    range = { from: startOfWeek(date, { weekStartsOn: 1 }), to: endOfDay(endOfWeek(date, { weekStartsOn: 1 })) };
  } else if (view === 'month') {
    range = { from: startOfMonth(date), to: endOfDay(endOfMonth(date)) };
  }
  return range;
}

const DnDCalendar = withDragAndDrop<TheCalendarEvent, TheCalendarResource>(Calendar);

type SlotsCalendarProps = {
  dateRange: { from: Date; to: Date };
  setDateRange: (range: { from: Date; to: Date }) => void;
  events: { view: CalendarEvent; title: string; start: Date; end: Date; allDay: boolean; resourceId: any; color: any }[];
  blockedEvents: {
    view: BlockedCalendarEvent;
    title: string;
    start: Date;
    end: Date;
    allDay: boolean;
    resourceId: any;
    isBlocked: boolean;
    time: any;
    variables: { timeStamp: Date; count: number; duration: number; description: string; branch_schedules: any; status: string };
  }[];
  onEventDrop: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventDrop'];
  onEventResize: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventResize'];
  onSelectEvent: CalendarProps<TheCalendarEvent, TheCalendarResource>['onSelectEvent'];
  onSelectSlot: CalendarProps<TheCalendarEvent, TheCalendarResource>['onSelectSlot'];
  onRangeChange: CalendarProps<TheCalendarEvent, TheCalendarResource>['onRangeChange'];
  multiStaffBookingType: boolean;
  adjustCalendarDomView: () => void;
  selectedUser: string;
  setSelectedUser: (value: string) => void;
  setSelectedCategoryTab: (value: string) => void;
  selectedCategoryTab: string;
  fromDateForWeek: Date;
  toDateForWeek: Date;
};
export function SlotsCalendar({
  dateRange,
  setDateRange,
  events,
  blockedEvents,
  onEventDrop,
  onEventResize,
  onSelectEvent,
  onSelectSlot,
  onRangeChange,
  multiStaffBookingType,
  adjustCalendarDomView,
  selectedUser,
  setSelectedUser,
  setSelectedCategoryTab,
  selectedCategoryTab,
  fromDateForWeek,
  toDateForWeek
}: SlotsCalendarProps): React.ReactNode {
  const rangeRef = useRef<CalendarRangeRef>(null);

  const { mobile } = useMediaQuery({ mobile: true });
  const { height } = useWindowSize();
  const bookingTypeId = useId();

  const icons = useIcons();

  const lessIcon = icons.lessMid.childImageSharp.gatsbyImageData;
  const moreIcon = icons.moreMid.childImageSharp.gatsbyImageData;

  const { data: { getBranchSchedules: schedules = [] } = {}, loading: loadingServices } = useQuery<{ getBranchSchedules: BranchSchedule[] }>(GetBranchSchedules, {
    fetchPolicy: 'cache-and-network'
  });
  const { data: { getBusUserProfile: busUserProfile } = {}, refetch: refetchProfile } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile);

  const { data: { getBranchSchedulesSlots: availability } = {}, loading } = useQuery<{
    getBranchSchedulesSlots: {
      branchSchedules: {
        BranchSchedule: BranchSchedule;
        availability: BranchAvailability;
      }[];
      availability: BranchAvailability;
    };
  }>(GetBranchSchedulesSlots, {
    variables: {
      start_date: getStartOfDate(dateRange.from)?.toISOString(),
      slots_length: 7,
      branchScheduleId: selectedCategoryTab ? [selectedCategoryTab] : undefined
    },
    skip: !dateRange.from || !dateRange.to || multiStaffBookingType,
    fetchPolicy: 'cache-and-network'
  });

  const selectedBookingType = useReactiveVar(vars.calendarSelectedBookingType) || CALENDAR_VIEWS.MY_SCHEDULE;
  const profile = (busUserProfile || {}) as BusUserProfile;

  const operationsCalendarBookingType = selectedBookingType === CALENDAR_VIEWS.ALL;

  const allSlots = availability?.availability?.slots?.availableSlots?.flat();

  const getValueColor = ({ max, value }: { max: number; value: number }) => {
    const isLessThanZero = value <= 0;
    const isLessThanHalf = value < max / 2;
    if (isLessThanZero) {
      return Colors.alert;
    }
    if (isLessThanHalf) {
      return Colors.orangeLight;
    }

    return Colors.primary;
  };

  const datesRef = useRef<{ date: Date; id: string }[]>([]);

  const SlotWrapepr = ({ children, value }: { children: React.ReactNode; value: Date }) => {
    const id = useId();
    const valueInAvailablity = allSlots?.find(slot => new Date(slot.timestamp).getTime() === value.getTime());

    const foundInDatesRef = datesRef.current.find(date => date.date === value);

    if (!foundInDatesRef) {
      datesRef.current.push({ date: value, id });
    }

    const shouldRender = !!valueInAvailablity && !foundInDatesRef;

    const color = shouldRender
      ? getValueColor({
          max: valueInAvailablity?.mainSlotAvailablity,
          value: valueInAvailablity?.available
        })
      : 'transparent';

    return (
      <SlotWrapperContainer backgroundColor={color} id={id}>
        <SlotWrapperAvailability backgroundColor={color}>{valueInAvailablity?.available}</SlotWrapperAvailability>
        {children}
      </SlotWrapperContainer>
    );
  };

  const BusUsers = profile?.Branch?.BusUsers || [];

  const usersList: DefaultOptionType[] = [
    {
      label: 'All Staff',
      value: ''
    },
    ...BusUsers.map(user => ({
      label: user.name,
      value: user.id
    }))
  ];

  const userSchedules: DefaultOptionType[] = [{ value: '', label: 'All' }, ...schedules.map(schedule => ({ value: schedule.id, label: schedule.name }))];

  const [calendarView, setCalendarView] = useState<View>('week');

  useEffect(() => {
    if (operationsCalendarBookingType || multiStaffBookingType) {
      setCalendarView('week');
      setDateRange({ from: fromDateForWeek, to: toDateForWeek });
    }
  }, [operationsCalendarBookingType, multiStaffBookingType]);

  useEffect(adjustCalendarDomView, [selectedUser]);

  const isMonth = calendarView === 'month';
  const isDay = calendarView === 'day';
  const isWeek = calendarView === 'week';

  const bookingTypeOptions = {
    id: bookingTypeId,
    optionType: OPTION_DROPDOWN_TYPES.BUTTONS,

    items: mobile
      ? [
          ...CALENDAR_VIEWS_TO_SHOW.map(view => ({
            name: CALENDAR_VIEWS_NAMES[view],
            value: CALENDAR_VIEWS_NAMES[view],
            onClick: () => {
              vars.calendarSelectedBookingType(view);
              // setCurrentCalendarLength(mobile ? 1 : CALENDAR_VIEW_LENGTH[view]);
              // calendarRangeRef.current?.clearRange();
              // if (view === CALENDAR_VIEWS.MULTI_STAFF) {
              //   resetView({ goToToday: true });
              //   return;
              // }
              // resetView({ goToToday: true });
            },
            green: view === selectedBookingType,
            disabled: [CALENDAR_VIEWS.MULTI_STAFF, CALENDAR_VIEWS.ALL].includes(view) && (!isUserSuperVisorOrAdminOrSuper(profile) || !BusUsers?.length)
          })),
          {
            name: 'Log Out',
            value: 'logout',
            onClick: logout,
            danger: true
          }
        ]
      : []
  };

  const previousView = useRef(calendarView);

  const lastRanges = useRef({
    day: calcRange('day', new Date()),
    week: calcRange('week', new Date()),
    month: calcRange('month', new Date())
  });

  const onNavigate: CalendarProps['onNavigate'] = (date, view, action) => {
    let range = { from: date, to: date };

    if (previousView.current !== view && lastRanges.current[view].from) {
      range = lastRanges.current[view];
    } else {
      range = calcRange(view, date);
      lastRanges.current[view] = range;
    }

    previousView.current = view;

    onRangeChange?.([range.from, range.to], view);
  };

  const handleHeaderClick = () => {
    const currentCalendarLength = differenceInDays(dateRange.to, dateRange.from) + 1;
    const from = isWeek ? fromDateForWeek : isMonth || dateRange.from;
    ModalDialog.openModal({
      noHeader: true,
      overlayClose: true,
      mobileBorderRadius: '38px',
      content: () => (
        <CalendarRange
          currentCalendarLength={currentCalendarLength}
          setUTCDate={date => {
            ModalDialog.closeModal();
            onNavigate(date, calendarView, 'DATE');
          }}
          useDatePicker={multiStaffBookingType}
          defaultValues={getRange(from, currentCalendarLength)}
          ref={rangeRef}
        />
      )
    });
  };

  const rangeFrom = multiStaffBookingType ? format(dateRange.from, 'dd MMM') : format(dateRange.from, 'MMM');

  return (
    <div
      style={{
        display: 'flex',
        height: '100%'
      }}
    >
      {isMonth && operationsCalendarBookingType && (
        <div style={{ width: '72px' }}>
          <Divider style={{ marginTop: '64px' }} />
          <div
            onClick={handleHeaderClick}
            style={{
              cursor: 'pointer',
              overflow: 'visible',
              fontWeight: '800',
              fontSize: '16px',
              padding: '10px 15px',
              borderBottom: '1px solod black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '48px',
              width: '72px'
            }}
          >
            {format(dateRange.from, 'MMM')}
          </div>
          <Divider />
        </div>
      )}
      <DnDCalendar
        style={{ width: '100%' }}
        date={dateRange.from}
        defaultView={mobile ? 'day' : 'week'}
        events={[...events, ...blockedEvents]}
        localizer={localizer}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onView={view => {
          setCalendarView(view);
          onNavigate(dateRange.from, view, 'DATE');
        }}
        onNavigate={onNavigate}
        step={30}
        resizable
        selectable
        popup
        showMultiDayTimes={false}
        scrollToTime={new Date()}
        enableAutoScroll
        allDayMaxRows={(height - MARGIN_HORIZONTAL) / ITEM_SIZE}
        {...(multiStaffBookingType &&
          selectedUser === '' && {
            view: 'day'
          })}
        {...(multiStaffBookingType && {
          resources: BusUsers.map(user => ({ resourceId: user.id, resourceTitle: user.name })).filter(user => (selectedUser !== '' ? user.resourceId === selectedUser : true)),
          resourceIdAccessor: r => r.resourceId,
          resourceTitleAccessor: r => r.resourceTitle
        })}
        components={{
          header: props => {
            const dateLabel = new Date(props.date);
            const isToday = dateLabel.toDateString() === new Date().toDateString();
            const isTodayMonth = dateLabel.getDay() === new Date().getDay();

            return (
              <WeekDayTitleAndWeekDayContainer>
                <WeekDayTitle isToday={isTodayMonth && isMonth}>{toThreeLetterWeekDay(dateLabel)}</WeekDayTitle>
                {!isMonth && <WeekDay isToday={isToday}>{toDay(dateLabel)}</WeekDay>}
              </WeekDayTitleAndWeekDayContainer>
            );
          },
          timeGutterHeader: () => {
            return <MonthLabel onClick={handleHeaderClick}>{rangeFrom}</MonthLabel>;
          },
          timeSlotWrapper: SlotWrapepr,
          event: event => {
            const isBlocked = event.event.isBlocked;
            const blockedTitle = event.event.view?.description;
            const pets = event?.event?.view?.petsNames;
            const petsCount = event?.event?.view?.petsCount;
            const petsToReturn = petsCount > 3 ? `${petsCount} pets` : `${pets}`;
            const service = event?.event?.view?.itemName || 'Blocked';
            const eventTime = `${format(event?.event?.start, 'HH:mm')} - ${format(event?.event?.end, 'HH:mm')}`;
            const titleToDisplay = isBlocked ? blockedTitle : service;
            const eventColor = isBlocked ? Colors.blocked : event?.event?.color;

            return (
              <EventLabelsContainer style={{ backgroundColor: eventColor }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  {!isBlocked && (
                    <EventLabel fontWeight={800} height="20px">
                      {titleToDisplay}
                    </EventLabel>
                  )}
                  {isBlocked && (
                    <EventLabel fontWeight={800} whiteSpace="auto" lineHeight="16px">
                      {titleToDisplay}
                    </EventLabel>
                  )}
                  {!isBlocked && <EventLabel fontSize={11}>{petsToReturn}</EventLabel>}
                </div>
                <EventLabel>{eventTime}</EventLabel>
              </EventLabelsContainer>
            );
          },
          resourceHeader: ({ index, label, resource: { resourceId, resourceTitle } }) => {
            const user = BusUsers.find(u => u.id === resourceId);
            return (
              !selectedUser && (
                <StaffContainer onClick={() => setSelectedUser(selectedUser ? '' : user.id)}>
                  {!selectedUser && (
                    <>
                      <PetContainer>
                        {!user?.profile_pic && <GatsbyImage image={icons?.user?.childImageSharp?.gatsbyImageData} alt={resourceTitle} style={{ height: 30, width: 30, borderRadius: 15 }} />}
                        {user?.profile_pic && <PetImg src={user.profile_pic} alt={resourceTitle} height="30px" width="30px" />}
                      </PetContainer>
                      <span
                        style={{
                          wordWrap: 'break-word',
                          overflow: 'hidden'
                        }}
                      >
                        {label}
                      </span>
                    </>
                  )}
                </StaffContainer>
              )
            );
          },

          toolbar: props => {
            const date = props.date;
            const dateLabel = getMonthName(date);
            const dayName = toThreeLetterWeekDay(date);
            const mobileDateLabel = `${dayName}, ${format(date, 'dd  MMM  yyyy')}`;
            return (
              <CalendarToolBarContainer>
                {!multiStaffBookingType && (
                  <Select
                    options={userSchedules}
                    onChange={e => {
                      setSelectedCategoryTab(e);
                    }}
                    value={selectedCategoryTab}
                    variant="filled"
                    style={{ width: '250px', marginLeft: '10px', height: 35 }}
                  />
                )}

                {!multiStaffBookingType && <div />}
                {multiStaffBookingType && (
                  <Select options={usersList} onChange={e => setSelectedUser(e)} value={selectedUser} variant="filled" style={{ width: '250px', marginLeft: '10px', height: 35 }} />
                )}

                {!mobile && (
                  <ToolsContainer>
                    {!(multiStaffBookingType && selectedUser === '') && (
                      <div style={{ display: 'flex', gap: 0 }}>
                        <ToolbarButton onClick={() => props.onView('day')} selected={props.view === 'day'}>
                          Day
                        </ToolbarButton>
                        <ToolbarButton onClick={() => props.onView('week')} selected={props.view === 'week'}>
                          Week
                        </ToolbarButton>
                        {!multiStaffBookingType && (
                          <ToolbarButton onClick={() => props.onView('month')} selected={props.view === 'month'}>
                            Month
                          </ToolbarButton>
                        )}
                      </div>
                    )}
                    <div style={{ display: 'flex', gap: 10 }}>
                      <CounterBtn onClick={() => props.onNavigate('PREV')} height="25px" width="25px">
                        <GatsbyImage image={lessIcon} alt={'Prev'} />
                      </CounterBtn>
                      <ToolbarButton
                        onClick={() => props.onNavigate('TODAY')}
                        selected={isToday(props.date)}
                        isToday
                        style={{ background: 'transparent', fontWeight: `${isToday(props.date) ? 800 : 600}` }}
                      >
                        Today
                      </ToolbarButton>
                      <CounterBtn onClick={() => props.onNavigate('NEXT')} height="25px" width="25px">
                        <GatsbyImage image={moreIcon} alt={'Next'} />
                      </CounterBtn>
                    </div>
                  </ToolsContainer>
                )}
                {mobile && (
                  <div style={{ display: 'flex' }}>
                    <RecordBody onClick={handleHeaderClick} fontWeight="800" fontSize={16} style={{ cursor: 'pointer' }}>
                      {mobileDateLabel}
                    </RecordBody>
                  </div>
                )}
                {mobile && (
                  <BusinessUserImageContainer>
                    <RecordBody style={{ position: 'absolute', right: '3px', height: 0 }}>
                      <OptionDropdown
                        mobileDisplayIcon={mobile}
                        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
                        options={[bookingTypeOptions]}
                        noApplyButton
                        menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
                      />
                    </RecordBody>
                    {/* {!profile?.profile_pic && <DefaultBusinessUserImage image={icons?.user?.childImageSharp?.gatsbyImageData} alt="Business user" />}
                                    {profile?.profile_pic && <BusinessUserImage src={profile.profile_pic} alt="Business user" />} */}
                  </BusinessUserImageContainer>
                )}
              </CalendarToolBarContainer>
            );
          }
        }}
      />
    </div>
  );
}
