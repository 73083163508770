import { useQuery, useReactiveVar } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ReactSelect from '../../../components/Shared/Forms/Select';
import { BackButton, BackButtonText } from '../../../components/DrawerBar/styled';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Branch, BusUserProfile } from '../../../components/Profile/types';
import { selectTheme } from '../../../components/Shared/Forms/Forms';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import useIcons from '../../../hooks/useIcons';
import { GetBusUserProfile, GetProviderBranches } from '../../../queries';
import { vars } from '../../../reactive';
import AppStorage from '../../../utils/AppStorage';
import { getUTCDateMonthName } from '../../../utils/dates';
import { areObjectsEqual, isUserSuperAdmin } from '../../../utils/helpers';
import BranchBillingBranchStats from './BranchBillingBranchStats';
import BranchBillingProviderStats from './BranchBillingProviderStats';
import GenerateInvoicesModal from './GenerateInvoicesModal';
import OrderInvoicesChargeModal from './OrderInvoicesChargeModal';
import OrderInvoicesSendEmailModal from './OrderInvoicesSendEmailModal';
import { ActionButton, ActionButtonsContainer, BillingStatsContainer, BranchBillingContainer, SelectedBillingFiltersContainer } from './styled';
import { useParams, Router } from '@reach/router';
import { navigate } from 'gatsby';
import useURLSearchParams from '../../../hooks/useURLSearchParams';
import { BRANCH_BILLING_FILTERS, BRANCH_BILLING_FILTERS_LABELS } from './types';
import { StyledRouter } from '../../../Routes';
import Select from '../../../components/Shared/Forms/Select';

const BranchBilling = () => {
  const { data: { getBusUserProfile: userProfile } = {}, loading } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile);

  const selectedCycle = useReactiveVar(vars.billingSelectedCycle);

  const allBranchBillingDates = userProfile?.Branch?.generatedBranchBillingDates || [];

  const currentBranchCycle =
    allBranchBillingDates.find(
      ({ billing_date_from, billing_date_to }) => new Date(billing_date_from).getTime() <= new Date().getTime() && new Date(billing_date_to).getTime() >= new Date().getTime()
    ) || allBranchBillingDates[0];

  const activeBillingCycle = allBranchBillingDates.find(({ billing_date_from }) => new Date(billing_date_from).getTime() === new Date(currentBranchCycle.billing_date_from).getTime());

  const defaultBillingCycle = selectedCycle || activeBillingCycle || allBranchBillingDates[0] || { billing_date_from: '', billing_date_to: '' };

  const { control, watch } = useForm({
    defaultValues: {
      currentBillingCycle: defaultBillingCycle
    }
  });

  const currentBillingCycle = watch('currentBillingCycle');

  const { billing_date_from = '', billing_date_to = '' } = currentBillingCycle || {};

  const currentPeriod = { start: billing_date_from, end: billing_date_to };

  const renderDateSelector = () => {
    return (
      <div style={{ width: 300, marginLeft: 'auto' }}>
        <Controller
          name="currentBillingCycle"
          control={control}
          defaultValue={defaultBillingCycle}
          render={({ onChange, value }) => (
            <Select
              options={allBranchBillingDates}
              value={selectedCycle || value}
              onChange={(option: any) => {
                onChange(option);
                vars.billingSelectedCycle(option);
              }}
              getOptionLabel={option =>
                !!option?.billing_date_from && option?.billing_date_to!! ? `${getUTCDateMonthName(option.billing_date_from)} - ${getUTCDateMonthName(option.billing_date_to)}` : ''
              }
              getOptionValue={option => option.billing_date_from}
              blackTheme
            />
          )}
        />
      </div>
    );
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      {loading && <CenteredLoader size={50} />}
      {!loading && (
        <>
          <StyledRouter style={{ height: '90%' }} basepath={'/app/operations/billing'}>
            <BranchBillingProviderStats currentPeriod={currentPeriod} path="/" headerActions={renderDateSelector()} />
            <BranchBillingBranchView path=":branchId/*" currentPeriod={currentPeriod} headerActions={renderDateSelector()} />
          </StyledRouter>
        </>
      )}
    </div>
  );
};

const BranchBillingBranchView = ({ currentPeriod, headerActions }: { currentPeriod: { start: string; end: string }; path: string; headerActions: JSX.Element }) => {
  const selectedBranch = useParams()?.branchId;
  const { data: { getProviderBranches: [branch] = [] } = {} } = useQuery<{ getProviderBranches: Branch[] }>(GetProviderBranches, {
    variables: {
      id: selectedBranch ? [selectedBranch] : []
    },
    skip: !selectedBranch
  });

  const [selectedFilter] = useURLSearchParams<BRANCH_BILLING_FILTERS>('mainFilter');
  const invoiceFilter = selectedFilter === BRANCH_BILLING_FILTERS.MAIN_INVOICES;
  const disableBulkInvoiceSendEmail = useReactiveVar(vars.disableBulkInvoiceSendEmail);
  const disableBulkInvoiceCharge = useReactiveVar(vars.disableBulkInvoiceCharge);
  const shouldDisableBulkInvoiceSendEmail =
    disableBulkInvoiceSendEmail && disableBulkInvoiceSendEmail?.branchId === selectedBranch && new Date(disableBulkInvoiceSendEmail.until!).getTime() > Date.now();
  const shouldDisableBulkInvoiceCharge = disableBulkInvoiceCharge && disableBulkInvoiceCharge?.branchId === selectedBranch && new Date(disableBulkInvoiceCharge.until!).getTime() > Date.now();

  const handleBulkChargeInvoice = () => {
    ModalDialog.openModal({
      content: () => (
        <OrderInvoicesChargeModal
          initialValues={{
            invoice_date_from: currentPeriod.start,
            invoice_date_to: currentPeriod.end
          }}
        />
      ),
      title: 'Charge Invoices'
    });
  };

  const handleBulkSendEmailInvoice = () => {
    ModalDialog.openModal({
      content: () => (
        <OrderInvoicesSendEmailModal
          initialValues={{
            invoice_date_from: currentPeriod.start,
            invoice_date_to: currentPeriod.end
          }}
        />
      ),
      title: 'Send Invoices'
    });
  };

  const handleGenerateInvoices = () => {
    ModalDialog.openModal({
      content: () => <GenerateInvoicesModal />,
      title: 'Generate Invoices'
    });
  };

  useEffect(() => {
    AppStorage.set('providerBranchId', selectedBranch);

    return () => {
      AppStorage.remove('providerBranchId');
    };
  }, [selectedBranch]);

  const icons = useIcons();
  const chevronIcon = icons.chevronLeft.childImageSharp.gatsbyImageData;

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <BranchBillingContainer>
        <BillingStatsContainer noMargin>
          <SelectedBillingFiltersContainer>
            <BackButton onClick={() => navigate('/app/operations/billing')}>
              <GatsbyImage image={chevronIcon} alt="Back" />
              <BackButtonText>
                {BRANCH_BILLING_FILTERS_LABELS[selectedFilter]} - {branch?.name}
              </BackButtonText>
            </BackButton>
          </SelectedBillingFiltersContainer>
        </BillingStatsContainer>
        <ActionButtonsContainer>
          {invoiceFilter && (
            <>
              <ActionButton onClick={handleGenerateInvoices} disabled={false}>
                Generate Invoices
              </ActionButton>
              <ActionButton onClick={handleBulkSendEmailInvoice} disabled={shouldDisableBulkInvoiceSendEmail}>
                Send Invoices
              </ActionButton>
              <ActionButton onClick={handleBulkChargeInvoice} disabled={shouldDisableBulkInvoiceCharge}>
                Charge
              </ActionButton>
            </>
          )}
          {headerActions}
        </ActionButtonsContainer>
      </BranchBillingContainer>
      <BranchBillingBranchStats currentPeriod={currentPeriod} />
    </div>
  );
};

export default BranchBilling;
