import React from 'react';
import useListBulkActions, { ItemsBulkActionsRef } from './useListBulkActions';
import { Pet } from '../components/Pets/types';
import PetRecordOptions from '../components/Pets/PetRecordOptions';
import ModalDialog from '../components/Modal/ModalDialog';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES } from '../components/Shared/Menus/OptionDropdown/types';
import AdjustAppUserOrderBillsBilledOnModal from '../views/Bookings/components/BookingsDrawer/AdjustAppUserOrderBillsBilledOnModal';
import AdjustAppUserOrdersPaymentModal from '../views/Bookings/components/BookingsDrawer/AdjustAppUserOrdersPaymentModal';

const usePetsListBulkActions = ({ getPets, ref, inDrawer }: { getPets: () => Pet[]; ref?: React.ForwardedRef<ItemsBulkActionsRef>; inDrawer?: boolean }) => {
  const options = useListBulkActions<Pet>({
    getItems: getPets,
    ref,
    inDrawer,
    Menu: ({ selectedItems, handleOnClickAction }) => {
      const pets = getPets()?.filter(pet => selectedItems.includes(pet?.id));
      return (
        <PetRecordOptions
          pets={pets}
          loadTags
          showAlways
          addedItems={[
            {
              name: 'Update Pricing',
              value: 'updatePricing',
              onClick: () =>
                ModalDialog.openModal({
                  title: 'Adjust Clients Orders',
                  content: () => <AdjustAppUserOrdersPaymentModal initialValues={{ selectedAppUsers: [...new Set(pets?.map(pet => pet?.AppUser?.id))] }} />,
                  onCloseBySave: handleOnClickAction
                })
            },
            {
              name: 'Update Billing Date',
              value: 'updateBillingDate',
              onClick: () =>
                ModalDialog.openModal({
                  title: 'Adjust Billing Date',
                  content: () => <AdjustAppUserOrderBillsBilledOnModal initialValues={{ selectedAppUsers: [...new Set(pets?.map(pet => pet?.AppUser?.id))] }} />,
                  onCloseBySave: handleOnClickAction
                })
            }
          ]}
          menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
          onClickAction={handleOnClickAction}
          refetchPetsAfterTag
        />
      );
    }
  });

  return options;
};

export default usePetsListBulkActions;
