import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import Colors from '../Colors';
import { FadeInBottom } from '../components/Shared/Animations';

export const RecordsContainer = styled.div<{ mobilePadding?: string }>`
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-left: 32px;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding: ${props => (props.mobilePadding ? props.mobilePadding : '0 16px')};
  }
`;

export const RecordBody = styled.span<{
  width?: string;
  fontWeight?: string;
  minWidth?: number;
  maxWidth?: number;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  flexEnd?: boolean;
  fontSize?: number;
  overflowScroll?: boolean;
  overflow?: string;
  noMargin?: boolean;
  flex?: string;
  MobileGap?: number;
  mobileWidth?: string;
  mobilePadding?: string;
  firstChildGapMobile?: string;
  mobileFlexDirection?: string;
  mobileOverflow?: string;
  mobileDisplay?: string;
  display?: string;
  color?: string;
  disableTruncate?: boolean;
  mobileMarginLeft?: string;
  mobileMarginRight?: string;
  mobileFontWeight?: number;
  gap?: string;
  marginLeftAuto?: boolean;
  flexDirection?: string;
  padding?: string;
}>`
  display: ${props => (props.display ? props.display : '')};
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
  gap: ${props => (props.gap ? props.gap : '0')};
  align-items: center;
  box-sizing: border-box;
  color: ${props => (props.color ? props.color : Colors.black)};
  /* line-height: 20px; */
  width: ${props => (props.width ? (props.width.includes('%') ? props.width : `${props.width}px`) : '200px')};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'auto')};
  /* min-width: ${props => (props.minWidth ? `${props.minWidth}px` : 'auto')}; */
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${props => (props.fontSize ? props.fontSize : 14)}px;
  flex: ${props => (props.flex ? props.flex : '1 1 auto')};
  overflow: ${props => (props.overflowScroll ? 'scroll' : 'hidden')};
  overflow: ${props => (props.overflow ? props.overflow : 'hidden')};
  margin-right: ${props => props.marginRight ?? '0'}px;
  margin-left: ${props => props.marginLeft ?? '0'}px;
  margin-left: ${props => (props.marginLeftAuto ? 'auto' : props.marginLeft)};
  padding: ${props => (props.padding ? props.padding : '2px 0')};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props =>
    props.flexEnd &&
    css`
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      padding: 2px 10px;
    `}

  &:last-child {
    margin-right: 40px;
    ${props =>
      props.noMargin &&
      css`
        margin-right: 0;
      `}
  }

  @media (max-width: 768px) {
    display: ${props => (props.mobileDisplay ? props.mobileDisplay : 'flex')};
    overflow: ${props => (props.mobileOverflow ? props.mobileOverflow : 'unset')};
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: unset;
    justify-content: space-between;
    align-items: center;
    gap: ${props => (props.MobileGap ? props.MobileGap : 0)}px;
    width: ${props => (props.mobileWidth ? (props.mobileWidth.includes('%') ? props.mobileWidth : `${props.mobileWidth}`) : 'auto')};
    &:last-child {
      margin-right: 0;
    }

    padding: ${props => (props.mobilePadding ? props.mobilePadding : 0)};
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)}px;
    flex-direction: ${props => (props.mobileFlexDirection ? props.mobileFlexDirection : 'row')};
    margin-left: ${props => (props.mobileMarginLeft ? props.mobileMarginLeft : '0')};
    margin-right: ${props => (props.mobileMarginRight ? props.mobileMarginRight : '0')} !important;
    font-weight: ${props => (props.mobileFontWeight ? props.mobileFontWeight : 700)};
  }

  ${props =>
    props.disableTruncate &&
    css`
      white-space: normal;
      text-overflow: unset;
      overflow: unset;

      @media (max-width: 768px) {
        white-space: normal;
        text-overflow: unset;
        overflow: unset;
      }
    `}
`;

export const HoverRecordOptionsContainer = styled.div<{ show?: boolean }>`
  visibility: hidden;
  align-items: center;
  justify-content: center;
  ${props =>
    props.show &&
    css`
      visibility: visible;
    `}
`;

export const BookingRecordContainer = styled.div`
  @media (max-width: 1200px) and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export const RecordDataOverflowContainer = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'column')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const RecordDataContainer = styled.li<{
  spaceBeteween?: boolean;
  noMargin?: boolean;
  fullWidth?: boolean;
  streach?: boolean;
  mobilePadding?: string;
  mobileGap?: string;
  MobileMinHeight?: string;
  autoHeight?: boolean;
  gap?: number;
  disableAnimation?: boolean;
  noHover?: boolean;
  padding?: string;
  marginLeft?: string;
}>`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  gap: ${props => (props.gap ? props.gap : 8)}px;
  padding: ${props => (props.padding ? props.padding : '8px 16px')};
  border-radius: 15px;
  ${props =>
    !props.disableAnimation &&
    css`
      animation: ${FadeInBottom} 0.35s cubic-bezier(0.04, 0.98, 0.71, 0.99);
      transition: background-color 0.22s ease-in-out, box-shadow 0.22s ease-in-out;
    `}
  ${props =>
    !props.noHover &&
    css`
      &:hover {
        background-color: ${Colors.toolsBarBackgroundColor};
        border-radius: 15px;
        cursor: pointer;
        ${HoverRecordOptionsContainer} {
          opacity: 1;
          visibility: visible;
        }
      }
    `}



  /* @media (max-width: 1200px) {
    height: 60px;
    min-height: 60px;
    max-height: 60px;
  } */

  /* @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    min-height: ${props => (props.MobileMinHeight ? props.MobileMinHeight : '80px')};
    max-height: unset;
    height: auto;
    margin-bottom: 8px;
    padding: ${props => (props.mobilePadding ? props.mobilePadding : 0)};
    background-color: #f2f2f2;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: ${props => (props.mobileGap ? props.mobileGap : 10)}px;
    ${props =>
    props.noMargin &&
    css`
      margin-bottom: 0;
    `}
    ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  } */

  ${props =>
    props.noMargin &&
    css`
      margin-bottom: 0;
    `}

  &:hover {
    ${HoverRecordOptionsContainer} {
      visibility: visible;
    }
  }
  ${props =>
    props.autoHeight &&
    css`
      height: unset;
      max-height: unset;
      @media (max-width: 768px) {
        height: unset;
        max-height: unset;
      }
      @media (max-width: 1200px) {
        height: unset;
        max-height: unset;
      }
    `}
`;

export const NotificationIndicator = styled.div<{ withIndicator: boolean }>`
  height: 8px;
  width: 16px;
  border-radius: 4px;
  background-color: ${props => (props.withIndicator ? Colors.alert : Colors.grey)};
  ${props =>
    props.withIndicator &&
    css`
      margin-right: 8px;
    `}
`;
export const AppUserImageContainer = styled.div<{ rotate?: boolean; noPadding?: boolean; mobileWidth?: string; paddingLeft?: string }>`
  display: flex;
  position: relative;
  height: 40px;
  perspective: 1000px;
  @media (max-width: 768px) {
    height: auto;
    width: ${props => (props.mobileWidth ? (props.mobileWidth.includes('%') ? props.mobileWidth : `${props.mobileWidth}px`) : '200px')};
  }
`;
export const AppUserImageAnimation = styled.div<{ absolute?: boolean }>`
  height: 40px;
  width: 40px;
  border-radius: 25px;
  backface-visibility: hidden;
  ${props =>
    props.absolute
      ? css`
          transform: rotateY(180deg);
        `
      : css`
          transform: rotateY(0deg);
        `}
  top: 0;
  left: 0;
  border: 2px solid transparent;
  box-sizing: border-box;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AppUserImageContainerAnimation = styled.div<{ rotateOption?: boolean; noPadding?: boolean }>`
  display: flex;
  height: 40px;
  position: relative;
  transition: transform 0.2s;
  transform-style: preserve-3d;
  transform: ${props => (props.rotateOption ? 'rotateY(180deg)' : 'rotateY(0deg)')};

  ${props =>
    props.noPadding &&
    css`
      padding-left: 0;
    `}
  @media (max-width: 768px) {
    height: auto;
    width: 100%;
  }
`;

export const AppUserImage = styled.img<{ index?: number; mobileHeight?: number; mobileWidth?: number }>`
  height: 40px;
  width: 40px;
  border-radius: 25px;
  top: 0;
  left: ${props => (props.index ? props.index * 8 : 0)}px;
  border: 2px solid ${props => (props.index ? Colors.white : 'transparent')};
  box-sizing: border-box;
  object-fit: cover;
  backface-visibility: hidden;
  @media (max-width: 768px) {
    // left: 0;
    // top: ${props => (props.index ? props.index * 8 : 0)}px;
    height: ${props => (props.mobileHeight ? props.mobileHeight : 40)}px;
    width: ${props => (props.mobileWidth ? props.mobileWidth : 40)}px;
  }
`;

export const BlankAppUserImage = styled(GatsbyImage)`
  height: 40px;
  width: 40px;
  border-radius: 25px;
  top: 0;
  left: 0;
  border: 2px solid transparent;
  box-sizing: border-box;
  object-fit: cover;
`;

export const AppUserName = styled.span<{ noMargin?: boolean; fontSize?: number; width?: number }>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;
  margin-left: 12px;
  width: ${props => `${props.width ? props.width : 150}px`};
  min-width: 150px;
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
  ${props =>
    props.noMargin &&
    css`
      margin-left: 0;
    `}

  @media (max-width: 768px) {
    min-width: 0;
    margin-left: 0;
  }
`;

export const AppUserImageNameContainer = styled.div<{ direction?: string }>`
  align-items: center;
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  @media (max-width: 768px) {
    // width: 100%;
    position: relative;
  }
`;
export const GroupHeader = styled.div`
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.secondaryText};
`;

export const TagsTimeOptionalDropdownContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
