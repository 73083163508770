import React, { useEffect, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import CoreTimelineCalendar from '@event-calendar/core';
import Interaction from '@event-calendar/interaction';
import TimelineCalendar from '@event-calendar/resource-timeline';
import Colors from '../../../Colors';
import { GetBranchRooms } from '../../../queries';
import { BlockedCalendarEvent, CalendarEvent } from '../../../views/Bookings/types';
import { CalendarEventColors } from '../../Shared/Shared';
import { BranchRoom } from '../../../views/Store/BranchRooms/types';
import { TheCalendarEvent, TheCalendarResource } from '../TheCalendar';
import { CalendarProps } from 'react-big-calendar';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';

import '@event-calendar/core/index.css';

const getRandomColor = () => {
  const colors = Object.values(CalendarEventColors);
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};
type MultiDayCalendarProps = {
  fromDateForMonth: Date;
  onSelectEvent: CalendarProps<TheCalendarEvent, TheCalendarResource>['onSelectEvent'];
  onEventDrop: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventDrop'];
  onEventResize: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventResize'];
  onSelectSlot: CalendarProps<TheCalendarEvent, TheCalendarResource>['onSelectSlot'];
  onRangeChange: CalendarProps<TheCalendarEvent, TheCalendarResource>['onRangeChange'];
  events: { view: CalendarEvent; title: string; start: Date; end: Date; allDay: boolean; resourceId: any; color: any }[];
  blockedEvents: {
    view: BlockedCalendarEvent;
    title: string;
    start: Date;
    end: Date;
    allDay: boolean;
    resourceId: any;
    isBlocked: boolean;
    time: any;
    variables: { timeStamp: Date; count: number; duration: number; description: string; branch_schedules: any; status: string };
  }[];
  loadingCalendarAppointments: boolean;
};
export function MultiDayCalendar({
  fromDateForMonth,
  onSelectEvent,
  onEventDrop,
  onEventResize,
  onSelectSlot,
  onRangeChange,
  events,
  blockedEvents,
  loadingCalendarAppointments
}: MultiDayCalendarProps) {
  const lastDateSet = useRef({ start: new Date(), end: new Date() });
  const randomColorsRef = useRef([]);
  const ec = useRef(null);

  const {
    data: { getBranchRooms: branchRooms = [] } = {},
    refetch: refetchRooms,
    loading
  } = useQuery<{ getBranchRooms: BranchRoom[] }>(GetBranchRooms, {
    fetchPolicy: 'cache-and-network'
  });

  const multiDayRooms = useMemo(() => branchRooms?.map(room => ({ id: room.id, title: room.name })), [branchRooms?.[0]?.id]);

  useEffect(() => {
    if (loading) {
      return;
    }
    console.warn('recreating Calendar');
    ec.current = new CoreTimelineCalendar({
      target: document.getElementById('timeline-calendar'),
      props: {
        plugins: [Interaction, TimelineCalendar],
        options: {
          slotEventOverlap: false,
          date: fromDateForMonth,
          titleFormat: start => {
            const options = { month: 'short' };
            const formattedTitle = new Intl.DateTimeFormat('en-US', options).format(start);
            const [month, year] = formattedTitle.split(' ');
            return `${month.substring(0, 3)} `;
          },
          duration: { month: 1 },
          firstDay: 1,
          view: 'resourceTimelineWeek',
          resources: multiDayRooms,
          height: '100%',
          dayHeaderFormat: { weekday: 'short', day: 'numeric' },
          viewDidMount: () => {
            document.querySelectorAll('.ec-day').forEach(dayElement => {
              const timeElement = dayElement.querySelector('time[datetime]');
              const date = timeElement?.getAttribute('datetime');
              if (timeElement && date) {
                const headerDate = new Date(date);
                if (headerDate.toDateString() === new Date().toDateString()) {
                  const dayName = headerDate.toLocaleDateString('en-US', { weekday: 'short' });
                  const dayNumber = headerDate.getDate();

                  const formattedContent = `<span class="today">${dayName}</span> <span class="today-number">${dayNumber}</span>`;

                  timeElement.innerHTML = formattedContent;
                }
              }
            });
          },
          headerToolbar: {
            start: 'title',
            center: '',
            end: 'prev today next'
          },
          eventClick: ({ event }) => {
            onSelectEvent({ view: event.extendedProps, title: event.title });
          },
          eventDrop: ({ event, newResource, oldResource }) => {
            const dtStart = new Date(event.start.setDate(event.start.getDate() + 1));
            const dtEnd = new Date(event.end.setDate(event.end.getDate()));
            onEventDrop({ start: dtStart, end: dtEnd, event: { view: event.extendedProps, isBlocked: event.isBlocked, resourceId: oldResource?.id }, resourceId: newResource?.id });
          },
          eventResize: ({ event }) => {
            const dtStart = new Date(event.start.setDate(event.start.getDate() + 1));
            const dtEnd = new Date(event.end.setDate(event.end.getDate()));
            onEventResize({ start: dtStart, end: dtEnd, event: { view: event.extendedProps, isBlocked: event.isBlocked, resourceId: event.resourceId } });
          },
          dateClick: ({ date, resource }) => {
            const dt = new Date(date.setDate(date.getDate()));
            onSelectSlot({ slots: [dt], action: 'select', resourceId: resource.id });
          },
          datesSet: ({ start, end, view }) => {
            if (start.getTime() === lastDateSet.current.start.getTime() && end.getTime() === lastDateSet.current.end.getTime()) {
              return;
            }
            lastDateSet.current = { start, end };
            onRangeChange({ start, end });
          }
        }
      }
    });

    return () => {
      ec.current?.destroy?.();
    };
  }, [multiDayRooms, loading]);

  useEffect(() => {
    if (randomColorsRef.current.length === 0) {
      const numberOfColors = events.length + blockedEvents.length;
      randomColorsRef.current = Array.from({ length: numberOfColors }, getRandomColor);
    }
  }, [events, blockedEvents]);

  useEffect(() => {
    ec.current?.setOption?.(
      'events',
      [...events, ...blockedEvents].map((e, index) => ({
        resourceId: e.resourceId,
        start: e.start,
        end: e.end,
        title: e.title,
        allDay: e.isBlocked ? false : true,
        backgroundColor: e.isBlocked ? Colors.blocked : e.view?.color || randomColorsRef.current[index],
        extendedProps: e.view
      }))
    );
    ec.current?.setOption?.('slotDuration', { days: 1 });
  }, [events, blockedEvents, loadingCalendarAppointments, multiDayRooms]);

  return <div id={'timeline-calendar'} style={{ minHeight: '100%', minWidth: '100%' }} />;
}
