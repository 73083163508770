import styled from 'styled-components';
import Colors from '../../../Colors';

export const ListContainer = styled.ul<{ inversed?: boolean; mobilePadding?: string; paddingL?: number; paddingR?: number; gap: number; backgroundColor?: string }>`
  font-size: 14px;
  height: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
  margin: 0;
  flex-direction: ${props => (props.inversed ? 'column-reverse' : 'column')};
  display: flex;
  gap: ${props => (props.gap ? props.gap : 8)}px;
  overflow-x: hidden;
  padding-right: ${props => `${props.paddingR ? props.paddingR : 0}px`};
  padding-left: ${props => `${props.paddingL ? props.paddingL : 0}px`};
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor || ''};
  @media (max-width: 768px) {
    padding: ${props => (props.mobilePadding ? props.mobilePadding : '8px 0 0 0')};
    gap: 12px;
  }
`;

export const ListSeparator = styled.div<{ backgroundColor?: string }>`
  /* &:first-child {
    padding-top: 4px;
  } */
  position: sticky;
  top: -8px;
  background-color: ${props => props.backgroundColor || Colors.white};
  z-index: 1;
  width: 100%;
  padding: 12px 0;
`;

export const LoadersContainer = styled.div`
  flex: 1;
`;

export const LineLoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;
