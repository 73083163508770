import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useMemo } from 'react';
import Colors from '../../Colors';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import { vars } from '../../reactive';
import { clearDrawerBars, setDrawerBar, togglePinDrawerBar } from '../../reactive/actions';
import { getRandomPetPlaceholder } from '../../utils/helpers';
import { RecordBody } from '../../views/styled';
import { DrawerLabelContainer, DrawerPetSubLabel, Profile, ProfileNameContainer, ProfilePhoto } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { Pet } from '../Pets/types';
import { ProfileContainer } from './styled';
import { BusUserProfile } from './types';
import { GetBusUserProfile } from '../../queries';

const ProfileListRecord = ({
  profile,
  booking,
  drawerId,
  petCount,
  children,
  renderUnderProfile,
  onClickPetProfile
}: {
  profile: Pet;
  booking?: Record<string, any>;
  drawerId: DRAWER_IDS;
  petCount?: number;
  children?: React.ReactNode;
  renderUnderProfile?: () => JSX.Element | null | undefined;
  onClickPetProfile?: (pet: Pet) => void;
}) => {
  const { data: { getBusUserProfile: userProfile } = {}, refetch: refetchProfile } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const icons = useIcons();
  const drawerBars = useReactiveVar(vars.drawerBars);
  const drawer = drawerBars.find(({ drawerId: id }) => id === drawerId);
  const isBookingConfirmed = booking?.status === 'CONFIRMED';
  const { mobile } = useMediaQuery({ mobile: true });

  const navigateDrawer = useNavigateDrawer({
    drawerData: { drawerId: DRAWER_IDS.PETS_DRAWER }
  });

  const branchConnectStatus = profile?.PetRecord?.BranchPetRecordConnections?.find(({ BranchId }) => BranchId === userProfile?.Branch?.id);

  const isArchived = !branchConnectStatus || branchConnectStatus?.status === 'ARCHIVED';

  const handleClick = () => {
    if (!branchConnectStatus) {
      return;
    }
    if (onClickPetProfile) {
      onClickPetProfile(profile);
      return;
    }
    if (!drawer?.isPinned) {
      togglePinDrawerBar(drawerId);
    }
    if (mobile) {
      clearDrawerBars();
    }
    setDrawerBar({ drawerId: DRAWER_IDS.PETS_DRAWER, recordData: profile?.id });
    navigateDrawer({ drawerLinkId: profile.id });
  };
  const profilePic = useMemo(() => profile?.profile_pic || getRandomPetPlaceholder(icons), [icons, profile]);

  const hasBooking = !!booking && !!isBookingConfirmed;

  return (
    <>
      <Profile key={profile?.id}>
        <DrawerLabelContainer isArchived={isArchived}>
          <ProfileContainer autoWidth onClick={handleClick}>
            <ProfilePhoto bgImage={profilePic} onClick={handleClick} />
            <ProfileNameContainer>
              <DrawerPetSubLabel weight={800} color="#212121" marginBottom={1} contentWidth={60} size={14}>
                {profile?.name}
              </DrawerPetSubLabel>
              <DrawerPetSubLabel weight={600} color="#212121" contentWidth={hasBooking ? 110 : 120} size={14}>
                {profile?.Breed?.name}
              </DrawerPetSubLabel>
              {petCount && petCount > 1 && (
                <DrawerPetSubLabel weight={500} color={Colors.black} contentWidth={hasBooking ? 60 : 100}>
                  (x{petCount})
                </DrawerPetSubLabel>
              )}
            </ProfileNameContainer>
          </ProfileContainer>
        </DrawerLabelContainer>
        {children}
      </Profile>
      {renderUnderProfile && renderUnderProfile()}
    </>
  );
};
export default ProfileListRecord;
