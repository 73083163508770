import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Notifier from '../../../../Notifier';
import useIcons from '../../../../hooks/useIcons';
import useMemoizedFullDates from '../../../../hooks/useMemoizedFullDates';
import {
  GetAllBranchDiscountsWithVouchers,
  GetAllProductTypeProducts,
  GetAllSubscriptionsAndServices,
  GetAppUserProfileById,
  GetBusUserProfile,
  GetProductById,
  StartMultiOrderForAppUser,
  StartOrderForAppUser,
  StartRecurringOrderForAppUser
} from '../../../../queries';
import { vars } from '../../../../reactive';
import client from '../../../../utils/client';
import { checkIfSlotsCompleted } from '../../../../utils/dates';
import { Discount } from '../../../../views/Store/Discounts/types';
import { isProductPostpaid } from '../../../../views/Store/ProductPrice';
import { AddNewBtn, AddNewBtnIcon } from '../../../../views/Store/Products/styled';
import { Product } from '../../../../views/Store/types';
import ModalDialog from '../../../Modal/ModalDialog';
import { Pet } from '../../../Pets/types';
import { BusUserProfile } from '../../../Profile/types';
import { CenteredLoader } from '../../Spinner';
import NewBookingModalForm from './NewBookingModalForm';
import { NewBookingModalLoaderContainer } from './styled';
import {
  NewBookingFormType,
  filterSelectedOrderGroupSlots,
  getMultiNewOrderVariables,
  getMultiProductNewOrderVariables,
  getNewOrderVariables,
  getProductsItems,
  getRecurringNewOrderVariables,
  getSelectedCustomizations
} from './utils';

const NewBookingModal = ({
  defaultValues,
  productModalType = false,
  defaultProductsIds = [],
  selectedId = ''
}: {
  defaultValues?: {
    appUserId?: string;
    pets?: string[];
    busUserId?: string;
    branchRoomId?: string;
  };
  productModalType?: boolean;
  defaultProductsIds?: string[];
  selectedId?: string;
}) => {
  const icons = useIcons();

  const appointments = useReactiveVar(vars.newBookingDefaultAppointments);
  const { data: { getProducts: products = [] } = {}, loading: loadingProducts } = useQuery<{ getProducts: Product[] }>(productModalType ? GetAllProductTypeProducts : GetAllSubscriptionsAndServices, {
    fetchPolicy: 'cache-and-network'
  });

  const [getProductById, { data: { productGet: selectedProducts = [] } = {}, loading: loadingSelectedProducts }] = useLazyQuery<{ productGet: Product[] }>(GetProductById, {
    fetchPolicy: 'cache-and-network'
  });

  const [getAppUserProfile, { data: { getAppUserById: appUserProfile = {} } = {}, loading: loadingAppUserProfile }] = useLazyQuery(GetAppUserProfileById, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBusUserProfile: profile = {} } = {}, loading: loadingProfile } = useQuery<{
    getBusUserProfile: BusUserProfile;
  }>(GetBusUserProfile);

  const busUsers = (profile as BusUserProfile).Branch?.BusUsers || [];

  const [startOrderForAppUser, { error, loading }] = useMutation(StartOrderForAppUser);

  const [startRecurringOrderForAppUser, { error: startRecurringOrderForAppUserError, loading: loadingStartRecurringOrder }] = useMutation(StartRecurringOrderForAppUser);

  const [startMultiOrderForAppUser, { error: startMultiOrderForAppUserError, loading: loadingStartMultiOrder }] = useMutation(StartMultiOrderForAppUser);

  const startOrderForAppUserError = error || startRecurringOrderForAppUserError || startMultiOrderForAppUserError;
  const loadingStartOrder = loading || loadingStartRecurringOrder || loadingStartMultiOrder;

  const defaultAppUserId = appointments?.[0]?.OrderItem?.Order?.AppUser?.id || defaultValues?.appUserId || '';
  const defaultPets = (appointments?.length ? [...new Set((appointments || []).map(appointment => appointment?.PetRecord?.Pet?.id))] : defaultValues?.pets) || [];
  const defaultAppUserProfile = appUserProfile?.id === defaultAppUserId ? appUserProfile : {};

  const defaultOrderGroup = [
    {
      productId: appointments?.[0]?.OrderItem.item.id || '',
      slots: [{}],
      customizations: {},
      recurrenceType: 'one-off' as const,
      bookingDays: [],
      quantity: 1,
      repetition: 1
    }
  ];

  const defaultOrderGroupsValue =
    productModalType && defaultProductsIds?.length
      ? defaultProductsIds.map(productId => ({
          ...defaultOrderGroup[0],
          productId
        }))
      : defaultOrderGroup;

  const formOptions = useForm<NewBookingFormType>({
    defaultValues: {
      orderGroups: defaultOrderGroupsValue,
      appUserId: defaultAppUserId || '',
      voucherId: '',
      discountId: '',
      overrideTotal: false,
      total: 0,
      pets: defaultPets || [],
      autoConfirm: false,
      markProductNoCharge: false,
      disableNotifications: false
    }
  });

  const { setValue, watch, control, formState } = formOptions;

  const orderGroupsFieldArray = useFieldArray<NewBookingFormType['orderGroups']['0'], 'id'>({
    control: control,
    name: 'orderGroups',
    keyName: 'id'
  });

  const watchedOrderGroups = watch('orderGroups');

  const watchedValues = watch(['appUserId', 'overrideTotal', 'total', 'discountId', 'voucherId']);

  const watchedPetsValue = watch('pets');

  const productsItems = getProductsItems({ orderGroups: watchedOrderGroups, products: selectedProducts });

  const allPets = appUserProfile?.Pets || appUserProfile?.ChappedPets ? [...appUserProfile?.Pets, ...appUserProfile?.ChappedPets] : [];
  const selectedOrderGroupSlots = filterSelectedOrderGroupSlots({ products, watchedOrderGroups });

  const isMultiOrder = selectedOrderGroupSlots?.length > 1;

  const selectedSlots = selectedOrderGroupSlots?.[0]?.slots;

  const groupsFullDates = useMemoizedFullDates({
    selectedOrderGroupSlots: selectedOrderGroupSlots.map(slot => ({ ...slot, productId: selectedOrderGroupSlots[0].productId })),
    selectedProducts
  });

  const fullDates = groupsFullDates[0] || [];

  const { data: { getBranchDiscounts: discounts = [] } = {} } = useQuery<{ getBranchDiscounts: Discount[] }>(GetAllBranchDiscountsWithVouchers, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ProductId: selectedOrderGroupSlots?.[0]?.productId,
      AppUserId: watchedValues.appUserId
    }
  });

  const selectedOrderGroupsProducts = [...new Set(watchedOrderGroups.map(({ productId }) => productId).filter(item => !!item))];
  useEffect(() => {
    if (
      selectedOrderGroupsProducts.length &&
      !loadingSelectedProducts &&
      (selectedProducts?.length !== selectedOrderGroupsProducts.length || selectedProducts?.some(({ id }) => !selectedOrderGroupsProducts.includes(id)))
    ) {
      getProductById({
        variables: {
          id: selectedOrderGroupsProducts
        }
      });
    }
  }, [selectedOrderGroupsProducts]);

  useEffect(() => {
    if (defaultAppUserId) {
      setValue('pets', defaultPets || []);
      getAppUserProfile({ variables: { id: defaultAppUserId } });
    }
  }, [defaultAppUserId]);

  useEffect(() => {
    if (watchedValues.appUserId && watchedValues.appUserId !== defaultAppUserId) {
      setValue('pets', []);
      getAppUserProfile({ variables: { id: watchedValues.appUserId } });
    }
  }, [watchedValues.appUserId]);

  const completedOrderGroupSlots = selectedOrderGroupSlots.filter(({ slots: selectedSlots }) => {
    const productItem = productsItems.find(({ product }) => product?.id === selectedOrderGroupSlots[0].productId)!;
    const { isRecurring, product } = productItem || {};
    if (product) {
      return checkIfSlotsCompleted({ isRecurring: !!isRecurring, selectedSlots, product });
    }
    return false;
  }).length;

  const firstSelectedProduct = selectedProducts.find(product => product.id === watchedOrderGroups?.[0]?.productId);

  const { isRecurring, sessions, subscriptionProduct, hasCustomizations, customizationsMap } = productsItems?.[0] || {};

  const plusButtonDisabled = completedOrderGroupSlots < selectedOrderGroupSlots.length || firstSelectedProduct?.max_bulk_orders === completedOrderGroupSlots;

  const plusButtonShown = productModalType ? true : !isRecurring && firstSelectedProduct?.allows_bulk_orders && selectedOrderGroupSlots.length < firstSelectedProduct?.max_bulk_orders;

  const handleRemoveSlotGroup = (index: number) => {
    orderGroupsFieldArray.remove(index);
  };

  const handleAddSlotGroup = () => {
    if (plusButtonDisabled) {
      return;
    }
    orderGroupsFieldArray.append({ slots: [{ date: new Date(), dateUntil: new Date(), time: '' }] });
  };

  const validateValues = ({ pets }: { pets: string[] } = { pets: watchedPetsValue }) => {
    const disabledSlots = completedOrderGroupSlots < selectedOrderGroupSlots?.length;
    const hasFullDates = !!fullDates?.length && fullDates?.length >= sessions?.length;
    const hasPets = pets && !!pets?.length;
    const hasAppUser = !!watchedValues.appUserId;
    const selectedAllSlots = selectedOrderGroupSlots?.every(({ slots }) => slots.length === sessions.length);

    const hasProduct = firstSelectedProduct?.id;
    const orderGroupsHaveProduct = (watchedOrderGroups || [])?.every(({ productId }) => !!productId);
    const slotsHaveDays = selectedOrderGroupSlots?.every(({ slots }) => slots.every(slot => slot.days?.length));
    const selectedTimes = watchedOrderGroups?.flatMap(({ slots }) => slots)?.length === orderGroupsFieldArray.fields?.length * sessions?.length;

    const selectedProductsCustomizations = watchedOrderGroups.map(orderGroup =>
      getSelectedCustomizations({ customizations: orderGroup?.customizations || {}, customizationsMap: selectedProducts?.find(({ id }) => id === orderGroup.productId)?.customizations || {} })
    );
    const selectedAllCustomizations = selectedProductsCustomizations.every(selectedCustomizations => Object.values(selectedCustomizations?.fields || {}).every(field => !!field?.title));

    const invalidServiceOrder = !hasFullDates || !hasPets || !selectedAllSlots || !hasProduct || disabledSlots || !selectedTimes;

    const invalidRecurringOrder = isRecurring && !slotsHaveDays;

    const invalidSubscriptionOrder = !hasPets || !hasProduct;

    const invalidService = invalidServiceOrder || invalidRecurringOrder;

    const invalidProductOrder = !hasAppUser || !orderGroupsHaveProduct || !selectedAllCustomizations;

    const invalid = productModalType ? invalidProductOrder : subscriptionProduct ? invalidSubscriptionOrder : invalidService;
    return !invalid;
  };

  const onSubmit = async (values: NewBookingFormType) => {
    const { appUserId, pets = [], orderGroups, voucherId, autoConfirm, markProductNoCharge } = values;
    const [{ customizations }] = orderGroups;
    if (!validateValues({ pets })) {
      return;
    }

    const {
      data: { getAppUserById: appUserProfile = {} }
    } = await client.query({
      query: GetAppUserProfileById,
      variables: { id: appUserId }
    });

    const allPets = [...appUserProfile.Pets!, ...appUserProfile.ChappedPets!];
    const petsProfiles: Pet[] = allPets.filter(({ id }) => pets.includes(id));

    if (Object.values(formState.errors).length) {
      return;
    }

    const selectedCustomizations = hasCustomizations ? getSelectedCustomizations({ customizations, customizationsMap }) : {};

    const otherValues = {
      selectedSlots,
      petsProfiles,
      fullDates,
      selectedProduct: firstSelectedProduct!,
      selectedCustomizations,
      voucherId,
      autoConfirm: isProductPostpaid(selectedProducts[0]) ? true : autoConfirm,
      markProductNoCharge
    };

    const recurringOrderVariables = getRecurringNewOrderVariables({ ...values, ...otherValues });

    const orderVariables = getNewOrderVariables({ ...values, ...otherValues });

    const multiOrderVariables = getMultiNewOrderVariables({ ...values, ...otherValues, groupsFullDates });

    const multiProductOrderVariables = getMultiProductNewOrderVariables({
      ...values,
      ...otherValues,
      paymentOffSession: true,
      selectedProductsValues: orderGroups.map(orderGroup => {
        const { hasCustomizations, customizationsMap = {} } = productsItems.find(({ product }) => product?.id === orderGroup.productId) || {};
        return {
          quantity: orderGroup.quantity,
          selectedCustomizations: hasCustomizations ? getSelectedCustomizations({ customizations: orderGroup.customizations, customizationsMap }) : {},
          selectedProduct: selectedProducts.find(product => product.id === orderGroup.productId)!
        };
      })
    });

    const startNewOrderForAppUser = isMultiOrder ? startMultiOrderForAppUser : isRecurring ? startRecurringOrderForAppUser : startOrderForAppUser;

    const variables = isMultiOrder ? multiOrderVariables : isRecurring ? recurringOrderVariables : productModalType ? multiProductOrderVariables : orderVariables;

    await startNewOrderForAppUser({ variables });

    if (!startOrderForAppUserError) {
      Notifier.info({ message: 'Your request is being processed' });
      ModalDialog.closeModal();
    }
  };

  const getPreOrderSummaryVariables = useCallback(() => {
    if (!validateValues({ pets: watchedPetsValue }) || loadingSelectedProducts) {
      return;
    }

    const petsProfiles = allPets.filter(({ id }) => watchedPetsValue!.includes(id));
    const selectedCustomizations = hasCustomizations ? getSelectedCustomizations({ customizations: watchedOrderGroups[0].customizations!, customizationsMap }) : {};

    const values = {
      appUserId: watchedValues.appUserId!,
      ignoreStock: false,
      overrideTotal: false,
      autoConfirm: false,
      petsProfiles,
      selectedCustomizations,
      selectedProduct: firstSelectedProduct!,
      total: null,
      disableNotifications: false,
      selectedSlots,
      voucherId: watchedValues.voucherId,
      markProductNoCharge: false
    };

    const orderVariables = getNewOrderVariables({ ...values, fullDates });

    const multiOrderVariables = getMultiNewOrderVariables({ ...values, groupsFullDates, options: { summary: true } });

    const recurringOrderVariables = getRecurringNewOrderVariables({ ...values, fullDates });

    const multiProductOrderVariables = getMultiProductNewOrderVariables({
      ...values,
      paymentOffSession: true,
      selectedProductsValues: watchedOrderGroups.map(orderGroup => {
        const { hasCustomizations, customizationsMap = {} } = productsItems.find(({ product }) => product?.id === orderGroup.productId) || {};
        return {
          quantity: orderGroup.quantity,
          selectedCustomizations: hasCustomizations ? getSelectedCustomizations({ customizations: orderGroup.customizations, customizationsMap }) : {},
          selectedProduct: selectedProducts.find(product => product.id === orderGroup.productId)!
        };
      })
    });

    return isMultiOrder ? multiOrderVariables : isRecurring ? recurringOrderVariables : productModalType ? multiProductOrderVariables : orderVariables;
  }, [watchedOrderGroups?.[0]?.customizations, watchedPetsValue, groupsFullDates, isRecurring, isMultiOrder, watchedValues.voucherId, loadingSelectedProducts]);

  return (
    <>
      {(loadingProducts || loadingProfile) && (
        <NewBookingModalLoaderContainer>
          <CenteredLoader size={28} />
        </NewBookingModalLoaderContainer>
      )}

      {!loadingProducts && (
        <NewBookingModalForm
          busUsers={busUsers}
          formOptions={formOptions}
          selectedProductsResponse={{ selectedProducts, loadingSelectedProducts }}
          products={products}
          onSubmit={onSubmit}
          loadingStartOrder={loadingStartOrder}
          allPets={allPets}
          appUserProfileResponse={{ appUserProfile, loadingAppUserProfile: loadingAppUserProfile }}
          preOrderSummaryForAppUserItems={{ getPreOrderSummaryVariables, validateValues }}
          selectedOrderGroups={selectedOrderGroupSlots}
          defaultValues={{ defaultAppUserId, defaultPets, defaultAppUserProfile }}
          groupsFullDates={groupsFullDates}
          orderGroups={orderGroupsFieldArray}
          onRemoveSlotGroup={handleRemoveSlotGroup}
          productModalType={productModalType}
          discounts={discounts}
          vouchers={discounts.find(({ id }) => id === watchedValues.discountId)?.Vouchers || []}
          selectedId={selectedId}
        >
          {plusButtonShown && (
            <AddNewBtn onClick={handleAddSlotGroup} noSpaceArround disabled={plusButtonDisabled}>
              <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
              Add New {productModalType ? 'Item' : 'Slot'}
            </AddNewBtn>
          )}
        </NewBookingModalForm>
      )}
    </>
  );
};

export default NewBookingModal;
