import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from '../../../components/Shared/Forms/Select';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import DurationSelector from '../../../components/Shared/DurationSelector';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, RadioBtnsGroup, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import AppUsersList from '../../../components/Shared/Lists/AppUsersList';
import { Container, FlexRow } from '../../../components/Shared/Shared';
import { AddBranchVoucher, EditBranchVoucher, GenerateBranchUniqueVoucherCode, GetAllBranchDiscounts, GetAllSubscriptionsAndServices } from '../../../queries';
import { VoucherModalContainer } from '../Operations/styled';
import { Product } from '../types';
import { Discount, VoucherForm, VoucherModalProps } from './types';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import Select from '../../../components/Shared/Forms/Select';

const VoucherModal: FC<VoucherModalProps> = ({
  onSubmit: overridenSubmit,
  voucher,
  discount,
  handleRemoveVoucher,
  otherVouchers = [],
  disableAppUserSelect = false,
  disableCodeGeneration = false,
  disableProductsSelect = false,
  disablePeriodSelect = false,
  disablePeriodCreatedAtDuration = false
}) => {
  const [generateBranchUniqueVoucherCode, { data: { generateBranchUniqueVoucherCode: { code = '' } = {} } = {}, loading: loadingGenerate }] = useLazyQuery<{
    generateBranchUniqueVoucherCode: { code: string };
  }>(GenerateBranchUniqueVoucherCode, {
    fetchPolicy: 'network-only'
  });

  const defaultValues = {
    appUsers: voucher?.appUsers || [],
    code: voucher?.code || '',
    discountId: voucher?.discountId || discount?.id || '',
    limit: voucher?.limit || 0,
    user_limit: voucher?.user_limit || 0,
    status: voucher?.status || 'ACTIVE',
    period: {
      end: voucher?.period?.end || '',
      start: voucher?.period?.start || '',
      createdAt_duration: voucher?.period?.createdAt_duration || ''
    },
    products: voucher?.products || [],
    auto_apply: voucher?.auto_apply || false,
    enableDuration: !!voucher?.period?.createdAt_duration || false
  };

  const formOptions = useForm<VoucherForm>({
    defaultValues: {
      voucher: defaultValues
    },
    shouldUnregister: false
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = formOptions;

  const watchedVoucher = watch('voucher');

  const { data: { getProducts: products = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetAllSubscriptionsAndServices, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchDiscounts: discounts = [] } = {} } = useQuery<{ getBranchDiscounts: Discount[] }>(GetAllBranchDiscounts, {
    fetchPolicy: 'cache-and-network'
  });

  const [addVoucher, { loading: loadingAdd }] = useMutation(AddBranchVoucher, {
    refetchQueries: ['getBranchVouchers']
  });
  const [editVoucher, { loading: loadingEdit }] = useMutation(EditBranchVoucher, {
    refetchQueries: ['getBranchVouchers']
  });

  const submitVoucher = async (data: VoucherForm['voucher']) => {
    const variables = {
      DiscountId: data.discountId,
      code: data.code,
      limit: +data.limit,
      user_limit: +data.user_limit,
      period: {
        start: data.period.start,
        end: data.period.end,
        createdAt_duration: data.period.createdAt_duration
      },
      auto_apply: data.auto_apply,
      ProductId: data.products,
      AppUserId: data.appUsers,
      status: data.status
    };

    if (voucher?.id) {
      await editVoucher({ variables: { id: voucher.id, ...variables } });
    } else {
      await addVoucher({ variables });
    }

    ModalDialog.closeModal();
  };

  const onSubmit = overridenSubmit ?? submitVoucher;

  const submitForm = handleSubmit(async data => {
    if (!data.voucher.code && !disableCodeGeneration) {
      await generateBranchUniqueVoucherCode({ variables: { codesToAvoid: otherVouchers.map(voucher => voucher.code) } });
      return;
    }

    const voucher = {
      ...data.voucher,
      code: data.voucher.code
    };

    onSubmit?.(voucher);
  });

  useEffect(() => {
    if (code) {
      const voucher = {
        ...watchedVoucher,
        code
      };

      onSubmit?.(voucher);
    }
  }, [code]);

  const loading = loadingAdd || loadingEdit || loadingGenerate;

  return (
    <ModalLayout
      onSubmit={submitForm}
      compact
      buttons={[
        <FormSubmitButton type="submit" loading={loading}>
          Save
        </FormSubmitButton>,
        handleRemoveVoucher ? (
          <FormSubmitButton type="button" onClick={() => handleRemoveVoucher()} danger>
            Delete
          </FormSubmitButton>
        ) : (
          <></>
        )
      ]}
    >
      <WideInputGroup>
        <FormLabel>Code (leave empty to auto-generate)</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <FormInput error={!!errors?.voucher?.code} height={20} fontSize={16} onChange={e => onChange(e.target.value.toUpperCase())} value={value} disabled={disableCodeGeneration} />
          )}
          control={control}
          name={`voucher.code`}
          defaultValue={defaultValues.code}
          rules={{
            validate: value => {
              if (value && (value.length < 3 || !/^[a-zA-Z0-9]+$/.test(value) || otherVouchers.map(voucher => voucher.code).includes(value))) {
                return 'Code must be unique, at least 3 characters long and contain only letters and numbers';
              }
            }
          }}
          disabled={disableCodeGeneration}
        />
        {!!errors?.voucher?.code && <FormError>{errors?.voucher?.code?.message || 'Code is invalid'}</FormError>}
      </WideInputGroup>
      {!disableAppUserSelect && (
        <WideInputGroup>
          <FormLabel>Clients</FormLabel>
          <AppUsersList formOptions={formOptions} defaultValues={defaultValues.appUsers} name={`voucher.appUsers`} isMulti />
        </WideInputGroup>
      )}
      {!disableProductsSelect && (
        <WideInputGroup>
          <FormLabel>Services</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                isMulti
                options={products.map(product => ({ label: product.name, value: product.id }))}
                onChange={value => {
                  const ids = value.map(item => item.value);
                  const selectedProducts = products.filter(product => ids.includes(product.id));
                  onChange(selectedProducts.map(product => product.id));
                }}
                value={value.map((item: string) => ({ label: products.find(product => product.id === item)?.name || '', value: item }))}
                theme={selectTheme}
              />
            )}
            control={control}
            name={`voucher.products`}
            defaultValue={defaultValues.products}
            rules={{ required: true }}
          />
          {!!errors?.voucher?.products && <FormError>{errors?.voucher?.products?.[0]?.message || 'Services are required'}</FormError>}
        </WideInputGroup>
      )}
      {voucher?.id && (
        <WideInputGroup>
          <FormLabel>Status</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                options={[
                  { label: 'Active', value: 'ACTIVE' },
                  { label: 'Archived', value: 'ARCHIVED' }
                ]}
                onChange={value => {
                  onChange(value?.value);
                }}
                value={{ label: value === 'ACTIVE' ? 'Active' : 'Archived', value }}
                theme={selectTheme}
              />
            )}
            control={control}
            name={`voucher.status`}
            defaultValue={defaultValues.status}
            rules={{ required: true }}
          />
          {!!errors?.voucher?.status && <FormError>{errors?.voucher?.status?.message || 'Status is required'}</FormError>}
        </WideInputGroup>
      )}
      <WideInputGroup>
        <FormLabel>Quota</FormLabel>
        <Controller
          as={<FormInput type={'number'} error={!!errors?.voucher?.limit} height={20} fontSize={16} />}
          control={control}
          name={`voucher.limit`}
          defaultValue={defaultValues.limit}
          rules={{ required: true, min: 1 }}
        />
        {!!errors?.voucher?.limit && <FormError>{errors?.voucher?.limit?.message || 'Limit is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Usage Per User</FormLabel>
        <Controller
          as={<FormInput type={'number'} error={!!errors?.voucher?.user_limit} height={20} fontSize={16} />}
          control={control}
          name={`voucher.user_limit`}
          defaultValue={defaultValues.user_limit}
          rules={{ required: true, min: 1 }}
        />
        {!!errors?.voucher?.user_limit && <FormError>{errors?.voucher?.user_limit?.message || 'User Limit is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Auto Apply</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false }
              ]}
              onChange={value => {
                onChange(value?.value);
              }}
              value={{ label: value ? 'Yes' : 'No', value }}
              theme={selectTheme}
            />
          )}
          control={control}
          name={`voucher.auto_apply`}
          defaultValue={defaultValues.auto_apply}
        />

        {!!errors?.voucher?.auto_apply && <FormError>{errors?.voucher?.auto_apply?.message || 'Auto Apply is required'}</FormError>}
      </WideInputGroup>
      {!disablePeriodSelect && (
        <WideInputGroup marginBottom={0}>
          <FormLabel>Period</FormLabel>
          <FlexRow gap={8}>
            <WideInputGroup>
              <FormLabel>From</FormLabel>
              <Controller
                as={<FormInput type={'datetime-local'} error={!!errors?.voucher?.period?.start} height={20} fontSize={16} />}
                control={control}
                name={`voucher.period.start`}
                defaultValue={defaultValues.period.start}
              />
              {!!errors?.voucher?.period?.start && <FormError>{errors?.voucher?.period?.start?.message || 'Start date is required'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>To</FormLabel>
              <Controller
                as={<FormInput type={'datetime-local'} error={!!errors?.voucher?.period?.end} height={20} fontSize={16} />}
                control={control}
                name={`voucher.period.end`}
                defaultValue={defaultValues.period.end}
                rules={{
                  validate: value => {
                    if (value && value < watchedVoucher?.period?.start) {
                      return 'End date must be after start date';
                    }
                  }
                }}
              />
              {!!errors?.voucher?.period?.end && <FormError>{errors?.voucher?.period?.end?.message || 'End date is required'}</FormError>}
            </WideInputGroup>
          </FlexRow>
        </WideInputGroup>
      )}
      <WideInputGroup>
        <FormLabel>Expiry Rules</FormLabel>
        <Controller
          control={control}
          name="voucher.enableDuration"
          render={({ onChange }) => (
            <RadioBtnsGroup
              name="voucher.enableDuration"
              options={['Enable Expiry of Voucher']}
              defaultValue={defaultValues.enableDuration}
              inputType={'checkbox'}
              onChange={e => {
                onChange(e.target.checked);
              }}
            />
          )}
          defaultValue={defaultValues.enableDuration}
        />
      </WideInputGroup>
      {!disablePeriodCreatedAtDuration && watchedVoucher?.enableDuration && (
        <WideInputGroup>
          <DurationSelector
            defaultDuration={Number(defaultValues.period.createdAt_duration)}
            formOptions={formOptions}
            options={{
              includeDays: true,
              name: 'voucher.period.createdAt_duration',
              rules: { required: true },
              title: 'Expire after (days) (hours) (minutes)'
            }}
          />
        </WideInputGroup>
      )}
      {!discount && (
        <WideInputGroup>
          <FormLabel>Discount</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <FormSelect error={!!errors?.voucher?.discountId} height={48} fontSize={16} onChange={e => onChange(e.target.value)} value={value}>
                <option value={''}>-- Select Discount --</option>
                {discounts.map(discount => (
                  <option key={discount.id} value={discount.id}>
                    {discount.name}
                  </option>
                ))}
              </FormSelect>
            )}
            control={control}
            name={`voucher.discountId`}
            defaultValue={defaultValues.discountId}
            rules={{ required: true }}
          />
          {!!errors?.voucher?.discountId && <FormError>{errors?.voucher?.discountId?.message || 'Discount is required'}</FormError>}
        </WideInputGroup>
      )}
    </ModalLayout>
  );
};

export default VoucherModal;
