import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from '../../../components/Shared/Forms/Select';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, FormTextArea, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { AddBranchMessageTemplate, DeleteBranchMessageTemplate, EditBranchMessageTemplate, GetBusUserProfile, GetProductsByBranchId } from '../../../queries';
import { vars } from '../../../reactive';
import { setDrawerBar, unsetDrawerBars } from '../../../reactive/actions';
import Common from '../Common';
import { BRANCH_MESSAGE_TYPES, BRANCH_MESSAGE_TYPES_LABELS, BranchMessage } from './BranchMessages';
import Select from '../../../components/Shared/Forms/Select';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import MarkupEditor, { MarkupEditorRef } from '../../../components/Shared/MarkupEditor';

type BranchMessageModalFormProps = {
  loading: boolean;
  products: Record<string, any>[];
  branchMessage?: BranchMessage;
  formOptions: ReturnType<typeof useForm>;
  saveError: boolean;
  saveLoading: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  deleteMessage: () => void;
};

const BranchMessageModalForm = ({
  loading,
  products,
  branchMessage,
  formOptions,
  saveError,
  saveLoading,
  onSubmit,
  deleteMessage,
  markupEditorProps
}: BranchMessageModalFormProps & { markupEditorProps: { ref: React.RefObject<MarkupEditorRef> } }) => {
  const { control, errors } = formOptions;
  const types = Object.values(BRANCH_MESSAGE_TYPES);

  return (
    <ModalLayout
      compact
      buttons={[
        branchMessage ? (
          <FormSubmitButton type="button" onClick={deleteMessage} loading={saveLoading} error={!!saveError} danger>
            Delete
          </FormSubmitButton>
        ) : (
          <></>
        ),
        <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
          Save
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Title</FormLabel>
        <Controller as={<FormInput error={errors.name} />} control={control} name={'name'} defaultValue={branchMessage?.name || 'New Template'} rules={{ required: true }} />
        {errors.name && <FormError>{errors.name.message || 'Title is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Message Body</FormLabel>
        <Controller as={<FormTextArea fieldHeight={120} error={errors.message} />} control={control} name={'message'} defaultValue={branchMessage?.message || ''} rules={{ required: true }} />
        {errors.message && <FormError>{errors.message.message || 'Message Text is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel> Message Body - HTML </FormLabel>

        <MarkupEditor ref={markupEditorProps?.ref} defaultValue={branchMessage?.html_message || ''} />

        {errors.html_message && <FormError>{errors.html_message.message || 'Message HTML Text is required'}</FormError>}
      </WideInputGroup>

      <Divider marginBottom="20px" />
      <FormLabel bold>Rules</FormLabel>
      <WideInputGroup>
        <FormLabel>Delivery Condition</FormLabel>
        <Controller
          control={control}
          name="type"
          defaultValue={branchMessage?.type || types[0]}
          render={({ value, onChange }) => {
            const options = types.map(type => ({
              value: type,
              label: BRANCH_MESSAGE_TYPES_LABELS[type]
            }));

            return (
              <Select
                options={options}
                value={options.find(option => option.value === value) || { value: types[0], label: BRANCH_MESSAGE_TYPES_LABELS[types[0]] }}
                onChange={selectedOption => onChange(selectedOption?.value || '')}
              />
            );
          }}
        />
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Products or Services</FormLabel>
        <Controller
          control={control}
          name="productId"
          defaultValue={branchMessage?.Products?.map(product => ({ value: product.id, label: product.name })) || [{ value: products[0]?.id, label: products[0]?.name }]}
          rules={{ required: true }}
          render={({ value, onChange }) => {
            const options = products.map(product => ({
              value: product.id,
              label: product.name
            }));

            return (
              <Select
                isMulti
                name="productId"
                options={options}
                value={value || [{ value: products[0]?.id, label: products[0]?.name }]}
                onChange={selectedOptions => onChange(selectedOptions || [{ value: products[0]?.id, label: products[0]?.name }])}
                theme={selectTheme}
              />
            );
          }}
        />
      </WideInputGroup>
    </ModalLayout>
  );
};
const BranchMessageModal = ({ branchMessage }: { branchMessage?: BranchMessage }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;
  const [getBusUser] = useLazyQuery(GetBusUserProfile);
  const [getProducts, { data: { productGet: products = [] } = {}, loading: loadingProducts }] = useLazyQuery(GetProductsByBranchId);
  const markupEditorRef = useRef<MarkupEditorRef>(null);
  const markupEditorProps = {
    ref: markupEditorRef
  };

  const [saveBranchMessage, { data: addedMessage, loading: addLoading, error: addError }] = useMutation(AddBranchMessageTemplate);

  const [editBranchMessage, { data: editedMessage, loading: editLoading, error: editError }] = useMutation(EditBranchMessageTemplate);

  const [deleteBranchMessage, { data: deletedMessage, loading: deleteLoading, error: deleteError }] = useMutation(DeleteBranchMessageTemplate);

  const onSubmit = handleSubmit(data => {
    const { name, message, html_message, type, productId } = data;
    const variables = {
      name,
      message,
      type,
      ProductId: productId?.map((product: { value: string }) => product.value) || null,
      html_message: markupEditorRef.current?.getText() || ''
    };

    if (branchMessage) {
      editBranchMessage({
        variables: {
          id: branchMessage.id,
          ...variables
        }
      });

      return;
    }

    saveBranchMessage({ variables });
  });

  const loading = loadingProducts;
  const saveError = !!addError?.message || !!editError?.message || !!deleteError?.message;
  const saveLoading = addLoading || editLoading || deleteLoading;
  const savedMessage = addedMessage?.addBranchMessageTemplate || editedMessage?.editBranchMessageTemplate;
  const messageDeleted = deletedMessage?.branchMessageTemplateDelete;

  const deleteMessage = () => {
    if (branchMessage) {
      deleteBranchMessage({
        variables: {
          id: branchMessage.id
        }
      });
    }
  };

  useEffect(() => {
    if (branchMessage?.id) {
      getProducts({ variables: { branchId: branchMessage?.Branch?.id } });
    } else {
      getBusUser().then(({ data }) => {
        const branchId = data.getBusUserProfile?.Branch?.id;
        getProducts({ variables: { branchId } });
      });
    }
  }, [branchMessage]);

  useEffect(() => {
    if (savedMessage || messageDeleted) {
      ModalDialog.closeModal();
      Common.get<() => Promise<void>>(`BranchMessages.GetBranchMessages.refetch`)?.();
      if (messageDeleted) {
        unsetDrawerBars();
      }
      const drawerBar = vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.BRANCH_MESSAGE_DRAWER);
      if (drawerBar) {
        setDrawerBar({ drawerId: DRAWER_IDS.BRANCH_MESSAGE_DRAWER, recordData: savedMessage });
      }
    }
  }, [savedMessage, messageDeleted]);

  return (
    <BranchMessageModalForm
      loading={loading}
      products={products}
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      branchMessage={branchMessage}
      onSubmit={onSubmit}
      deleteMessage={deleteMessage}
      markupEditorProps={markupEditorProps}
    />
  );
};

export default BranchMessageModal;
