import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { RecordBody, RecordDataContainer } from '../../styled';
import BranchScheduleModal from './BranchScheduleModal/BranchScheduleModal';
import { typesOptions } from './BranchScheduleModal/types';
import { BranchSchedule } from './types';
import { Booking } from '../../Bookings/components/BookingsBody/styled';

const BranchScheduleRecord = ({ branchSchedule }: { branchSchedule: BranchSchedule }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <BranchScheduleModal branchSchedule={branchSchedule} />,
      title: 'Edit Branch Schedule'
    });
  };

  return (
    <>
      <Booking onClick={handleClick}>
        <RecordBody fontWeight={'800'}>{branchSchedule?.name}</RecordBody>
        <RecordBody width="20%" marginRight="20">
          {typesOptions.find(({ id }) => id === branchSchedule?.booking_type)?.name}
        </RecordBody>
        <RecordBody>{branchSchedule?.slots?.length} Slots</RecordBody>
      </Booking>
      <Divider />
    </>
  );
};

export default BranchScheduleRecord;
