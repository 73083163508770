import React, { useMemo, useState } from 'react';
import {
  BillingStatsContainer,
  BillingStatsTitle,
  FilterItem,
  FilterItemContainer,
  FilterItemCount,
  FilterItemText,
  SelectedBillingFiltersContainer,
  SelectedBillingFiltersWrapper,
  VerticalDivider
} from './styled';
import { useQuery } from '@apollo/client';
import { GetProviderBranchesCounts } from '../../../queries';
import { Branch } from '../../../components/Profile/types';
import { toDateInput } from '../../../utils/dates';
import { BRANCH_BILLING_FILTERS, defaultCounts, ProviderCountsOutput } from './types';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { Divider } from '../../Pets/Health/styled';
import BranchBillingStatsFilterItems from './BranchBillingStatsFilterItems';
import { ViewContainer } from '../../../components/Shared/Shared';
import Colors from '../../../Colors';
import { getCountForSelectedFilter, getCurrentBillingFilters } from './utils';
import { navigate } from 'gatsby';
import useURLSearchParams from '../../../hooks/useURLSearchParams';

const BranchBillingProviderStatsSection = ({
  title,
  providerCounts,
  branches,
  filters,
  secondary,
  currentPeriod
}: {
  title?: string;
  providerCounts: Branch['branchCounts'];
  branches: ProviderCountsOutput['getProviderBranchesCounts']['branches'];
  filters: BRANCH_BILLING_FILTERS[];
  secondary?: boolean;
  currentPeriod: { start: string; end: string };
}) => {
  const { currentBookingFilter, currentInvoiceFilter, currentSubscriptionFilter, currentUnpaidFilter } = getCurrentBillingFilters(currentPeriod);
  const selectedFilterState = useURLSearchParams<BRANCH_BILLING_FILTERS>('filter');
  const [selectedFilter] = selectedFilterState;

  const sortedBranches = useMemo(() => {
    if (!branches) {
      return [];
    }
    return [...(branches || [])].sort((a, b) => {
      const selectedFilterCountBranchA = getCountForSelectedFilter({ counts: a.counts!, selectedFilter });
      const selectedFilterCountBranchB = getCountForSelectedFilter({ counts: b.counts!, selectedFilter });

      return selectedFilterCountBranchB - selectedFilterCountBranchA;
    });
  }, [branches, selectedFilter]);

  const billingSectionsTitles = {
    period: 'Branches',
    date: `${branches.length}`
  };

  const setSelectedBranch = (branchId: string, filter: BRANCH_BILLING_FILTERS) => {
    const filterValue =
      filter === BRANCH_BILLING_FILTERS.MAIN_BOOKINGS
        ? currentBookingFilter[0]
        : filter === BRANCH_BILLING_FILTERS.MAIN_INVOICES
        ? currentInvoiceFilter[0]
        : filter === BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS
        ? currentSubscriptionFilter[0]
        : currentUnpaidFilter[0];
    navigate(`/app/operations/billing/${branchId}?mainFilter=${filter}&filter=${filterValue}`, { replace: true });
  };

  return (
    <div style={{ flex: 1 }}>
      <BillingStatsContainer noMargin>
        <BillingStatsTitle>{title}</BillingStatsTitle>
        <SelectedBillingFiltersContainer>
          <SelectedBillingFiltersWrapper noBottomRadius>
            {!secondary && (
              <>
                <FilterItemContainer secondary>
                  <FilterItem minWidth={120}>
                    <FilterItemText>{billingSectionsTitles.period}</FilterItemText>
                    <FilterItemCount>{billingSectionsTitles.date}</FilterItemCount>
                  </FilterItem>
                </FilterItemContainer>
              </>
            )}
            <BranchBillingStatsFilterItems
              counts={providerCounts!}
              filters={filters}
              selectedFilterState={selectedFilterState}
              secondary
              options={{
                renderActiveBar: false,
                renderVerticalDivider: false,
                hideSecondaryTotals: true
              }}
            />
          </SelectedBillingFiltersWrapper>
        </SelectedBillingFiltersContainer>
        <Divider color={'#F6F6F6'} height={2} />
      </BillingStatsContainer>
      {sortedBranches.map(({ Branch, counts }, index) => {
        const isLastBranch = index === branches.length - 1;
        return (
          <BillingStatsContainer noMargin={!isLastBranch} key={Branch.id}>
            <SelectedBillingFiltersContainer>
              <SelectedBillingFiltersWrapper small noTopRadius noBottomRadius={!isLastBranch}>
                {!secondary && (
                  <FilterItemContainer secondary>
                    <FilterItem marginToRight clickable minWidth={120}>
                      <FilterItemText inactiveColor={Colors.black}>{Branch.name}</FilterItemText>
                    </FilterItem>
                  </FilterItemContainer>
                )}
                <BranchBillingStatsFilterItems
                  counts={counts!}
                  filters={filters}
                  selectedFilterState={selectedFilterState}
                  options={{ renderActiveBar: false, renderVerticalDivider: false, renderTitles: false, small: true, hideSecondaryTotals: true }}
                  onClickItem={filter => setSelectedBranch(Branch.id, filter)}
                  secondary
                />
              </SelectedBillingFiltersWrapper>
            </SelectedBillingFiltersContainer>
            {!isLastBranch && <Divider color={'#F6F6F6'} />}
          </BillingStatsContainer>
        );
      })}
    </div>
  );
};

const BranchBillingProviderStats = ({ currentPeriod, headerActions }: { currentPeriod: { start: string; end: string }; path: string; headerActions?: React.ReactNode }) => {
  const { data: { getProviderBranchesCounts: { providerCounts = defaultCounts, branches = [] } = {} } = {}, loading } = useQuery<ProviderCountsOutput>(GetProviderBranchesCounts, {
    variables: {
      timestamp_from: currentPeriod.start,
      timestamp_to: currentPeriod.end
    },
    fetchPolicy: 'cache-and-network'
  });

  const mainFilters = [BRANCH_BILLING_FILTERS.MAIN_BOOKINGS, BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS, BRANCH_BILLING_FILTERS.MAIN_ORDERS, BRANCH_BILLING_FILTERS.MAIN_ORDERS_TOTAL];

  const secondFilters = [BRANCH_BILLING_FILTERS.MAIN_INVOICES];

  const thirdFilters = [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_SUBSCRIPTION_INVOICES, BRANCH_BILLING_FILTERS.MAIN_UNBILLED_BOOKINGS];

  return (
    <ViewContainer style={{ overflow: 'scroll', padding: 20 }}>
      {loading && <CenteredLoader size={50} />}
      {!loading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {headerActions}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
            <BranchBillingProviderStatsSection branches={branches} providerCounts={providerCounts} title={'Active Billing'} filters={mainFilters} currentPeriod={currentPeriod} />
            <div style={{ width: 200 }}>
              <BranchBillingProviderStatsSection branches={branches} providerCounts={providerCounts} filters={secondFilters} secondary currentPeriod={currentPeriod} />
            </div>
          </div>

          <BranchBillingProviderStatsSection branches={branches} providerCounts={providerCounts} filters={thirdFilters} secondary currentPeriod={currentPeriod} title={'All time unpaid'} />
        </div>
      )}
    </ViewContainer>
  );
};

export default BranchBillingProviderStats;
