import React from 'react';
import useListBulkActions, { ItemsBulkActionsRef } from './useListBulkActions';
import { Booking } from '../views/Bookings/types';
import BookingDrawerButtons from '../views/Bookings/components/BookingsDrawer/BookingDrawerButtons';

const useBookingsListBulkActions = ({
  getAppointments,
  ref,
  inDrawer,
  refetch
}: {
  getAppointments: () => Booking[];
  ref?: React.ForwardedRef<ItemsBulkActionsRef>;
  inDrawer?: boolean;
  refetch?: () => void;
}) => {
  const getAllGroupOrders = ({ selectedItems }: { selectedItems: string[] }) => {
    const allSelectedAppointments = getAppointments().filter(appointment => selectedItems.includes(appointment.id));
    const allOrderIds = allSelectedAppointments.map(appointment => appointment.OrderItem?.Order?.id);
    const uniqueOrderIds = [...new Set(allOrderIds)];

    return uniqueOrderIds.map(orderId => {
      return allSelectedAppointments.filter(appointment => appointment.OrderItem?.Order?.id === orderId);
    });
  };

  const options = useListBulkActions<Booking>({
    getItems: getAppointments,
    ref,
    inDrawer,
    Menu: ({ selectedItems, handleOnClickAction }) => (
      <BookingDrawerButtons
        appointments={getAppointments()}
        allGroupOrders={getAllGroupOrders({ selectedItems })}
        group
        refetch={refetch}
        containerStyle={{ width: 'auto' }}
        groupOptionsToShow={{
          shouldDisplayGroupAssign: true,
          shouldDisplayGroupCancel: true,
          shouldDisplayGroupConfirmationAndRejection: true,
          shouldDisplayGroupTagsOptions: true,
          shouldDisplayGroupUpdateOrderBills: true
        }}
        onClickAction={handleOnClickAction}
      />
    )
  });

  return options;
};

export default useBookingsListBulkActions;
