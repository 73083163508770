import { useMutation, useQuery } from '@apollo/client';
import React, { useRef } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { Branch, BusUserProfile } from '../../../components/Profile/types';
import {
  ColorPicker,
  FormButtonsContainer,
  FormCheckbox,
  FormError,
  FormInput,
  FormLabel,
  FormSecondaryText,
  FormSubmitButton,
  FormTextArea,
  InputGroup,
  InputsWrapper
} from '../../../components/Shared/Forms/Forms';
import { FlexRow } from '../../../components/Shared/Shared';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { EditProviderBranch, GetBusUserProfile } from '../../../queries';
import { isEmail } from '../../../utils/validators';
import { Divider } from '../../Pets/Health/styled';
import BinaryUpload, { BinaryUploadProps, BinaryUploadRef } from '../../Pets/Reports/BinaryUpload';
import usePhotoUpload, { useDeletePhotos } from '../../../hooks/usePhotoUpload';
import Select from '../../../components/Shared/Forms/Select';
import { FULL_DAYS } from '../../../utils/dates';
import { petTypesOptions } from '../Operations/StoreProductModal/types';
import { billingCycleOptions, statusOptions } from './types';

type ProviderBranchModalFormType = {
  name: string;
  email: string;
  contact_number: string;
  address: {
    country: string;
    city: string;
    street: string;
    post_code: string;
  };
  // longitude: string;
  // latitude: string;
  colors: {
    primary: string;
    secondary: string;
  };
  payment: {
    billing_cycle: Partial<{
      frequency: string;
      date_of_month: number;
      day_of_week: number;
    }>;
    vat_number: string;
    vat_percentage: number;
    disable_automatic_billing: boolean;
    invoice_payment_disabled: boolean;
    invoice_footer: string;
  };
  photos: {
    logo: string;
  };
  branch_targeting: {
    visibility: string;
    status: string;
    pet_type: string[];
  };
};

type ProviderBranchModalFormProps = {
  loading: boolean;
  formOptions: UseFormMethods<ProviderBranchModalFormType>;
  saveError: boolean;
  saveLoading: boolean;
  defaultValues?: Partial<ProviderBranchModalFormType>;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  binaryUploadProps: BinaryUploadProps;
};

const ProviderBranchModalForm = ({ loading, formOptions, saveError, saveLoading, onSubmit, defaultValues, binaryUploadProps }: ProviderBranchModalFormProps) => {
  const { control, errors, watch } = formOptions;

  const watchedBillingCycleFrequency = watch('payment.billing_cycle.frequency');

  return (
    <ModalLayout
      compact
      buttons={
        <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
          Save
        </FormSubmitButton>
      }
    >
      <>
        <InputGroup>
          <FormLabel>Name</FormLabel>
          <FormInput type={'text'} value={defaultValues?.name} disabled />
        </InputGroup>
        <Divider verticalMargin={20} />
        <FormLabel bold>Branding</FormLabel>
        <InputGroup>
          <BinaryUpload {...binaryUploadProps} ref={binaryUploadProps.ref} />
        </InputGroup>
        <InputGroup>
          <FormLabel>Primary Color</FormLabel>
          <Controller
            render={({ onChange, value }) => <ColorPicker type={'color'} value={value} onChange={e => onChange(e.target.value)} />}
            control={control}
            name={'colors.primary'}
            defaultValue={defaultValues?.colors?.primary}
          />
        </InputGroup>
        <InputGroup>
          <FormLabel>Secondary Color</FormLabel>
          <Controller
            render={({ onChange, value }) => <ColorPicker type={'color'} value={value} onChange={e => onChange(e.target.value)} />}
            control={control}
            name={'colors.secondary'}
            defaultValue={defaultValues?.colors?.secondary}
          />
        </InputGroup>
        <Divider verticalMargin={20} />
        {/* <InputGroup>
          <BinaryUpload
            {...binaryUploadProps}
            ref={binaryUploadProps.ref}
          />
        </InputGroup> */}
        <FormLabel bold>Contact Details</FormLabel>
        <InputGroup>
          <FormLabel>Email</FormLabel>
          <Controller
            render={({ onChange, value }) => <FormInput type={'email'} value={value} onChange={e => onChange(e.target.value)} />}
            control={control}
            name={'email'}
            rules={{ required: true, validate: (value: string) => isEmail.test(value) || 'Invalid email' }}
            defaultValue={defaultValues?.email}
          />
          <FormError>{errors.email?.message}</FormError>
        </InputGroup>
        <InputGroup>
          <FormLabel>Phone Number</FormLabel>
          <Controller
            render={({ onChange, value }) => <FormInput type={'text'} value={value} onChange={e => onChange(e.target.value)} />}
            control={control}
            name={'contact_number'}
            rules={{ required: 'Please add your phone number' }}
            defaultValue={defaultValues?.contact_number}
          />
          <FormError>{errors.contact_number?.message}</FormError>
        </InputGroup>
        <Divider verticalMargin={20} />
        <FormLabel bold>Address & Location</FormLabel>
        <InputGroup>
          <FormLabel>Country</FormLabel>
          <FormInput type={'text'} value={defaultValues?.address?.country} disabled />
        </InputGroup>
        <InputGroup>
          <FormLabel>City</FormLabel>
          <Controller
            as={<FormInput error={!!errors?.address?.city} type={'text'} name={'address.city'} />}
            control={control}
            name={'address.city'}
            defaultValue={defaultValues?.address?.city || ''}
            rules={{ required: 'Please add your city', pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
          />
          {errors?.address?.city && <FormError>{errors?.address?.city?.message || 'Please add a valid city'}</FormError>}
        </InputGroup>
        <InputGroup>
          <FormLabel>Street</FormLabel>
          <Controller
            as={<FormInput error={!!errors?.address?.street} type={'text'} name={'address.street'} />}
            control={control}
            name={'address.street'}
            defaultValue={defaultValues?.address?.street || ''}
          />
        </InputGroup>
        <InputGroup>
          <FormLabel>Post Code</FormLabel>
          <Controller
            as={<FormInput error={!!errors?.address?.post_code} type={'text'} name={'address.post_code'} />}
            control={control}
            name={'address.post_code'}
            defaultValue={defaultValues?.address?.post_code || ''}
            rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
          />
        </InputGroup>
        <Divider verticalMargin={20} />
        {/* <InputGroup>
          <FormLabel>Longitude</FormLabel>
          <Controller as={<FormInput error={!!errors?.longitude} type={'text'} name={'longitude'} />} control={control} name={'longitude'} defaultValue={defaultValues?.longitude || ''} />
        </InputGroup>
        <InputGroup>
          <FormLabel>Latitude</FormLabel>
          <Controller as={<FormInput error={!!errors?.latitude} type={'text'} name={'latitude'} />} control={control} name={'latitude'} defaultValue={defaultValues?.latitude || ''} />
        </InputGroup> */}

        <Divider verticalMargin={20} />
        <FormLabel bold>Billing</FormLabel>
        <FlexRow gap={10} width="400px">
          <InputGroup width={160}>
            <FormLabel error={!!errors.payment?.billing_cycle?.frequency}>Billing cycle</FormLabel>
            <Controller
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={billingCycleOptions}
                    value={{ value: value, label: billingCycleOptions.find(option => option.value === value)?.label }}
                    onChange={option => {
                      const newValue = option?.value;
                      onChange(newValue);
                    }}
                  />
                );
              }}
              control={control}
              name={'payment.billing_cycle.frequency'}
              rules={{ required: true }}
              defaultValue={defaultValues?.payment?.billing_cycle?.frequency}
            />
          </InputGroup>
          {watchedBillingCycleFrequency === 'MONTHLY' && (
            <InputGroup flex1>
              <FormLabel error={!!errors.payment?.billing_cycle?.date_of_month}>Date of the month</FormLabel>

              <Controller
                render={({ onChange, value }) => {
                  return (
                    <Select
                      options={Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: i + 1 }))}
                      value={{ value: value, label: value }}
                      onChange={option => {
                        const newValue = option?.value;
                        onChange(newValue);
                      }}
                    />
                  );
                }}
                control={control}
                name={'payment.billing_cycle.date_of_month'}
                rules={{ required: true }}
                defaultValue={defaultValues?.payment?.billing_cycle?.date_of_month}
              />
            </InputGroup>
          )}

          {watchedBillingCycleFrequency === 'WEEKLY' && (
            <InputGroup flex1>
              <FormLabel error={!!errors.payment?.billing_cycle?.day_of_week}>Day of the week</FormLabel>
              <Controller
                render={({ onChange, value }) => {
                  return (
                    <Select
                      options={FULL_DAYS.map((day, index) => ({ value: index, label: day }))}
                      value={{ value: value, label: FULL_DAYS[value] }}
                      onChange={option => {
                        const newValue = option?.value;
                        onChange(newValue);
                      }}
                    />
                  );
                }}
                rules={{ required: true }}
                control={control}
                name={'payment.billing_cycle.day_of_week'}
                defaultValue={defaultValues?.payment?.billing_cycle?.day_of_week}
              />
            </InputGroup>
          )}
        </FlexRow>
        {watchedBillingCycleFrequency === 'MONTHLY' && (
          <FormSecondaryText>
            Collar will automatically generate the invoices on the selected day of the month unless the month ends before the selected day. Then the invoice will be generated on the last day of the
            month.
          </FormSecondaryText>
        )}
        <Divider verticalMargin={20} />
        <InputGroup>
          <FormLabel>Vat number</FormLabel>
          <Controller
            as={<FormInput error={!!errors?.payment?.vat_number} type={'text'} name={'payment.vat_number'} />}
            control={control}
            name={'payment.vat_number'}
            defaultValue={defaultValues?.payment?.vat_number || ''}
          />
        </InputGroup>
        <InputGroup>
          <FormLabel>Vat percentage</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <FormInput
                error={!!errors?.payment?.vat_percentage}
                name={'payment.vat_percentage'}
                min={0}
                max={100}
                onChange={e => {
                  const value = parseInt(e.target.value) || 0;
                  onChange(value);
                }}
                value={value}
              />
            )}
            control={control}
            name={'payment.vat_percentage'}
            rules={{ validate: value => value >= 0 && value <= 100 }}
            defaultValue={defaultValues?.payment?.vat_percentage || ''}
          />
        </InputGroup>
        <Divider verticalMargin={20} />
        <InputGroup>
          <Controller
            control={control}
            name="payment.disable_automatic_billing"
            render={({ onChange, value }) => (
              <FormCheckbox
                itemsArray={[
                  {
                    id: 'payment.disable_automatic_billing',
                    name: 'Disable automatic billing'
                  }
                ]}
                onChange={newVal => {
                  onChange(!!newVal[0]);
                }}
                value={value ? ['payment.disable_automatic_billing'] : []}
                column
                fontSize={14}
              />
            )}
            defaultValue={!!defaultValues?.payment?.disable_automatic_billing}
          />
        </InputGroup>
        <InputGroup>
          <Controller
            control={control}
            name="payment.invoice_payment_disabled"
            render={({ onChange, value }) => (
              <FormCheckbox
                itemsArray={[
                  {
                    id: 'payment.invoice_payment_disabled',
                    name: 'Disable In-App invoice payment'
                  }
                ]}
                onChange={newVal => {
                  onChange(!!newVal[0]);
                }}
                value={value ? ['payment.invoice_payment_disabled'] : []}
                column
                fontSize={14}
              />
            )}
            defaultValue={!!defaultValues?.payment?.invoice_payment_disabled}
          />
        </InputGroup>
        <Divider verticalMargin={20} />
        <InputGroup>
          <FormLabel>Invoice footer</FormLabel>
          <Controller
            as={<FormTextArea error={!!errors?.payment?.invoice_footer} name={'payment.invoice_footer'} />}
            control={control}
            name={'payment.invoice_footer'}
            defaultValue={defaultValues?.payment?.invoice_footer || ''}
          />
        </InputGroup>
        <Divider verticalMargin={20} />
        <FormLabel bold>Marketplace Targeting</FormLabel>
        {/* <InputGroup>
              <FormLabel>Visibility</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    options={visibilityOptions}
                    value={value ? visibilityOptions.find(option => option.value === value) : visibilityOptions[0]}
                    onChange={option => {
                      const newValue = option?.value;
                      onChange(newValue);
                    }}
                  />
                )}
                control={control}
                name={'branch_targeting.visibility'}
                rules={{ required: true }}
                defaultValue={defaultValues?.branch_targeting?.visibility}
              />
            </InputGroup> */}
        <InputGroup>
          <FormLabel>Status</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                options={statusOptions}
                value={value ? statusOptions.find(option => option.value === value) : statusOptions[0]}
                onChange={option => {
                  const newValue = option?.value;
                  onChange(newValue);
                }}
              />
            )}
            control={control}
            name={'branch_targeting.status'}
            rules={{ required: true }}
            defaultValue={defaultValues?.branch_targeting?.status}
          />
        </InputGroup>
        <InputGroup>
          <FormLabel>Allowed Pet(s)</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                isMulti
                options={petTypesOptions}
                value={value ? petTypesOptions.filter(option => value.includes(option.value)) : [petTypesOptions[0]]}
                onChange={values => {
                  const newValues = values.map(option => option.value);
                  onChange(newValues);
                }}
              />
            )}
            control={control}
            name={'branch_targeting.pet_type'}
            rules={{ required: true }}
            defaultValue={defaultValues?.branch_targeting?.pet_type}
          />
        </InputGroup>
      </>
    </ModalLayout>
  );
};

const ProviderBranchModal = ({ branch }: { branch?: Branch }) => {
  const { data: { getBusUserProfile: currentUser } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile);

  const defaultValues: ProviderBranchModalFormType = {
    name: branch?.name || '',
    email: branch?.email || '',
    contact_number: branch?.contact_number || '',
    address: {
      country: branch?.address?.country || 'United Kingdom',
      city: branch?.address?.city || '',
      street: branch?.address?.street || '',
      post_code: branch?.address?.post_code || ''
    },
    // longitude: branch?.longitude || '',
    // latitude: branch?.latitude || '',
    colors: {
      primary: branch?.colors?.primary || '',
      secondary: branch?.colors?.secondary || ''
    },
    payment: {
      billing_cycle: {
        frequency: branch?.payment?.billing_cycle?.frequency || '',
        ...(branch?.payment?.billing_cycle?.frequency === 'MONTHLY' ? { date_of_month: branch?.payment?.billing_cycle?.date_of_month || 1 } : {}),
        ...(branch?.payment?.billing_cycle?.frequency === 'WEEKLY' ? { day_of_week: branch?.payment?.billing_cycle?.day_of_week || 0 } : {})
      },
      vat_number: branch?.payment?.vat_number || '',
      vat_percentage: branch?.payment?.vat_percentage || 0,
      disable_automatic_billing: branch?.payment?.disable_automatic_billing || false,
      invoice_payment_disabled: branch?.payment?.invoice_payment_disabled || false,
      invoice_footer: branch?.payment?.invoice_footer || ''
    },
    photos: {
      logo: branch?.photos?.logo || ''
    },
    branch_targeting: {
      visibility: branch?.BranchTargeting?.visibility || 'VISIBLE',
      status: branch?.BranchTargeting?.status || 'ACTIVE',
      pet_type: branch?.BranchTargeting?.pet_type || []
    }
  };

  const formOptions = useForm<ProviderBranchModalFormType>({ defaultValues });

  const { handleSubmit } = formOptions;
  // const [saveBranch, { data: addedBranch, loading: addLoading, error: addError }] = useMutation(AddProviderBranch, {
  //   refetchQueries: ['getProviderBranches'],
  //   awaitRefetchQueries: true
  // });

  const [editBranch, { loading: editLoading, error: editError }] = useMutation(EditProviderBranch, {
    refetchQueries: ['getProviderBranches', 'getBusUserProfile'],
    awaitRefetchQueries: true
  });

  const photosRef = useRef<BinaryUploadRef>(null);
  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const onSubmit = handleSubmit(async data => {
    const {
      name,
      email,
      contact_number,
      address,
      // longitude,
      // latitude,
      colors,
      payment,
      branch_targeting
    } = data;

    const variables = {
      name,
      email,
      contact_number,
      // longitude,
      // latitude,
      address: {
        ...(branch?.address || {}),
        ...address,
        country: branch?.address?.country || 'United Kingdom'
      },
      colors: {
        ...(branch?.colors || {}),
        ...colors
      },
      payment: {
        ...(branch?.payment || {}),
        ...payment
      },
      branch_targeting: {
        ...(branch?.BranchTargeting || {}),
        ...branch_targeting
      }
    };

    let branchId = branch?.id;

    if (!branch) {
      // cosnt { data } = awiat saveBranch({ variables });
      // branchId = data.addProviderBranch.id;
    }

    const photos = await photosRef.current?.getPhotos(`providers/${currentUser?.Provider.id}/branches/${branchId}/logo`);
    const shouldEdit = (branch && branch.id) || photos?.length;

    if (shouldEdit) {
      await editBranch({
        variables: {
          id: branchId,
          ...variables,
          photos: {
            ...(branch?.photos || {}),
            logo: photos?.[0]
          }
        }
      });
    }
    ModalDialog.closeModal();
  });

  const loading = false;
  const saveError = !!editError?.message || !!errorUploadPhotos?.message;
  const saveLoading = editLoading || loadingUploadPhotos;

  const binaryUploadProps: BinaryUploadProps = {
    deletePhotos,
    uploadPhotos,
    ref: photosRef,
    defaultBinaries: {
      photos: defaultValues?.photos?.logo ? [defaultValues.photos.logo] : [],
      attachments: []
    },
    options: {
      enableAttachments: false
    },
    maxNumberOfPhotos: 1
  };

  return (
    <ProviderBranchModalForm
      loading={loading}
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      binaryUploadProps={binaryUploadProps}
    />
  );
};

export default ProviderBranchModal;
