import React from 'react';
import { Branch } from '../../../components/Profile/types';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { numberWithCommas } from '../../../utils/helpers';
import { ActiveFilterBar, ActiveFilterBarContainer, FilterItem, FilterItemContainer, FilterItemCount, FilterItemText, VerticalDivider } from './styled';
import { BRANCH_BILLING_FILTERS, BRANCH_BILLING_FILTERS_FILTERABLE, BRANCH_BILLING_FILTERS_LABELS, BRANCH_BILLING_FILTERS_SECONDARY_TOTALS, BRANCH_BILLING_FILTERS_TOTALS } from './types';
import { getCountForSelectedFilter } from './utils';

const BranchBillingStatsFilterItems = ({
  counts,
  filters,
  selectedFilterState,
  loading,
  options,
  secondary,
  onClickItem
}: {
  counts: NonNullable<Branch['branchCounts']>;
  filters: BRANCH_BILLING_FILTERS[];
  selectedFilterState: [BRANCH_BILLING_FILTERS, (filter: BRANCH_BILLING_FILTERS) => void];
  loading?: boolean;
  secondary?: boolean;
  options?: { renderActiveBar?: boolean; renderVerticalDivider?: boolean; renderTitles?: boolean; small?: boolean; showActive?: boolean; hideSecondaryTotals?: boolean };
  onClickItem?: (filter: BRANCH_BILLING_FILTERS) => void;
}) => {
  const { renderActiveBar = true, renderVerticalDivider = true, renderTitles = true, small = false } = options || {};
  const [selectedFilter, setSelectedFilter] = selectedFilterState;
  const activeFilterIndex = filters.findIndex(filter => filter === selectedFilter);

  return (
    <>
      {filters.map((filter, index) => {
        const active = selectedFilter === filter;
        const count = loading ? 'Loading' : getCountForSelectedFilter({ counts, selectedFilter: filter });
        const label = BRANCH_BILLING_FILTERS_LABELS[filter];
        const totals = BRANCH_BILLING_FILTERS_TOTALS[filter];
        const secondaryTotals = BRANCH_BILLING_FILTERS_SECONDARY_TOTALS[filter];
        const secondaryTotalsCount = getCountForSelectedFilter({ counts, selectedFilter: filter, useSecondaryTotals: true });
        const useTotalsCurrency = totals?.some(total => total.toLowerCase().includes('amount'));
        const useSecondaryTotalsCurrency = secondaryTotals?.some(total => total.toLowerCase().includes('amount'));
        const isFilterable = BRANCH_BILLING_FILTERS_FILTERABLE[filter];
        return (
          <FilterItemContainer key={filter} secondary={secondary}>
            {!secondary && index === 0 && renderActiveBar && (
              <ActiveFilterBarContainer index={activeFilterIndex}>
                <ActiveFilterBar />
              </ActiveFilterBarContainer>
            )}
            <FilterItem
              secondary={secondary}
              active={active}
              onClick={() => {
                onClickItem?.(filter);
                if (!isFilterable) {
                  return;
                }
                setSelectedFilter(filter);
              }}
              clickable={isFilterable}
            >
              {renderTitles && (
                <FilterItemText secondary={secondary} active={active}>
                  {label}
                </FilterItemText>
              )}
              <FilterItemCount secondary={secondary} small={small} active={active}>
                {useTotalsCurrency && !loading ? getBranchCurrencySymbol() : ''}
                {numberWithCommas(count) || 0}
                {!options?.hideSecondaryTotals && !!secondaryTotals?.length && (
                  <>
                    {' '}
                    ({useSecondaryTotalsCurrency && !loading ? getBranchCurrencySymbol() : ''}
                    {numberWithCommas(secondaryTotalsCount) || 0})
                  </>
                )}
              </FilterItemCount>
            </FilterItem>
            {renderVerticalDivider && index < filters.length - 1 && !secondary && <VerticalDivider />}
          </FilterItemContainer>
        );
      })}
    </>
  );
};

export default BranchBillingStatsFilterItems;
