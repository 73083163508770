import styled, { css } from 'styled-components';

export const TabGroupContainer = styled.div<{ fullWidth?: boolean; noMargin?: boolean }>`
  width: 446px;
  margin-top: 24px;
  margin-bottom: 38px;
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const DatesContainer = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  gap: 10px;
  width: 100%;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 17px 12px;
  width: 100%;
  min-height: 100px;
  border-radius: 8px;
  background: #f4f4f4;
  margin-bottom: 20px;
`;
export const SummaryLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const Description = styled.p<{ crossed?: boolean; bold?: boolean }>`
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  ${({ crossed }) =>
    crossed &&
    css`
      text-decoration: line-through;
      font-weight: 800;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 800;
`;

export const PriceContainer = styled.div`
  display: flex;
  gap: 8px;
  font-size: 18px;
`;
