import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import { Product } from '../../views/Store/types';
import { Branch } from '../Profile/types';
import { Note } from '../Shared/BranchNotes/types';

export type Meal = {
  id: string;
  quantity: string;
  time: string;
  unit: string;
  name: string;
  BranchCategoryId: string;
};

export type MedCond = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  Branch: Branch;
  PetRecordId: string;
};

export type Adherence = {
  id: string;
  time: string;
  createdAt: string;
  quantity: string;
  type: string;
  start_date: string;
  nextdate: string;
  adherences_allowed: boolean;
  end_date: string;
  count: string;
  SuppRecordId: string;
  Branch: Branch;
  Product: Product;
  SuppRecord: SuppRecord;
  timestamp: string;
};

export type SuppRecord = {
  id: string;
  time: string;
  createdAt: string;
  quantity: string;
  type: string;
  start_date: string;
  nextdate: string;
  adherences_allowed: boolean;
  end_date: string;
  count: string;
  Adherences: Adherence[];
  MedConds: MedCond[];
  Product: Product;
};

export type VaccRecord = {
  id: string;
  name: string;
  dates: string[];
  nextdate: Date;
  createdAt: string;
  color: string;
  batch_number: string;
  end_date: string;
  VaccRecordType: {
    id: string;
    name: string;
  };
  MedConds: {
    id: string;
    name: string;
    Branch: Branch;
  }[];
};

export type PetRecord = {
  disabilities(disabilities: any): unknown;
  id: string;
  neutred: boolean;
  BranchPetRecordTags: BranchPetRecordTag[];
  allergies: Record<string, any>[];
  chronic_conditions: Record<string, any>;
  BranchCategories: BranchCategory[];
  BranchPetRecordConnections: {
    id: string;
    status: 'ACTIVE' | 'ARCHIVED';
    BranchId: string;
  }[];
  Branch: Branch;
  Pet: Pet;
  Meals: Meal[];
  SuppRecords: SuppRecord[];
  VaccRecords: VaccRecord[];
  vet: {
    id: string;
    practice: string;
    address: {
      line1: string;
      line2: string;
      city: string;
      country: string;
    };
    phone: BranchAppUser['phone_numbers'][0];
    email: string;
  };
  insurer: {
    id: string;
    name: string;
    policy_number: string;
    start_date: string;
    end_date: string;
    email: string;
    phone: string;
    address: { id: string; line1: string; line2: string; city: string; country: string };
  };
  Notes: Note[];
  PetBehavior: {
    id: string;
    feeding_routine: string;
    treatment_routine: string;
  };
  measurements: {
    chests: { chest: number }[];
    necks: { neck: number }[];
    backs: { back: number }[];
    heights: { height: number }[];
    weights: { weight: number }[];
  };
};

export enum PetType {
  DOG = 'DOG',
  CAT = 'CAT',
  RABBIT = 'RABBIT'
}

export type Pet = {
  id: string;
  name: string;
  profile_pic: string;
  gender: 'MALE' | 'FEMALE';
  type: keyof typeof PetType;
  PetColor: {
    id: string;
    name: string;
  };
  Breed: {
    id: string;
    name: string;
    type: keyof typeof PetType;
  };
  birthdate: string;
  AppUser: BranchAppUser;
  AppUserId: string;
  PetRecord: PetRecord;
  routeTags: string[];
  address: string;
};

export type PetBehavior = {
  id: string;
  behavior_around_same_type: string;
  behavior_around_other_pets: string;
  behavior_around_children: string;
  behavior_around_adults: string;
  behavior_negative: string;
  behavior_positive: string;
  sleep_place: string;
  favorite_treats: string;
  favorite_activities: string;
  walking_places: string;
  daily_routine: string;
  left_alone_duration: string;
};
