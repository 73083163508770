import { sentenceCase } from 'sentence-case';
import { toReadableDate } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { getKeys } from '../../utils/helpers';
import { ORDER_STATUS_TITLES_COLORS, ORDER_SUBSCRIPTION_REPEAT_STATUS_TITLES } from '../Orders/types';
import { OrderVoucher } from '../Store/Discounts/types';
import { ORDER_REPEAT_STATUS, Subscription } from './types';

export const getSubscriptionOrder = (subscription: Subscription, repeatsFilter?: { from: string; to: string }) => {
  const subscriptionRepeats = subscription?.OrderSubscriptionRepeats || [];
  const lastRepeat =
    typeof repeatsFilter === 'object' && repeatsFilter?.from && repeatsFilter?.to
      ? subscriptionRepeats
          ?.filter(repeat => new Date(repeat?.createdAt).getTime() >= new Date(repeatsFilter?.from).getTime() && new Date(repeat?.createdAt).getTime() <= new Date(repeatsFilter?.to).getTime())
          ?.sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())?.[0]
      : subscriptionRepeats?.[subscriptionRepeats?.length - 1];
  const orderItem = subscriptionRepeats?.[0]?.OrderItem;
  const order = orderItem?.Order;
  return { order, orderItem, subscriptionRepeats, lastRepeat, subscription };
};

export const getSubscriptionTitles = ({ subscription, orderVouchers, repeatsFilter }: { subscription: Subscription; orderVouchers?: OrderVoucher[]; repeatsFilter?: { from: string; to: string } }) => {
  const { order, orderItem, subscriptionRepeats, lastRepeat } = getSubscriptionOrder(subscription, repeatsFilter);
  const status = subscription?.status;
  const product = orderItem?.item;
  const subscriptionNumber = order?.number;
  const subscriptionName = product?.name;
  const time = toReadableDate(subscription?.createdAt, { isLocale: true });
  const date = new Date(subscription?.createdAt);
  const nextDate = new Date(subscription?.next_date);
  const lastOrderDate = new Date(subscription?.last_order_date);
  const user = order?.AppUser || { id: '1', name: 'Unknown user' };

  const pets = subscriptionRepeats
    ?.map(repeat => repeat?.PetRecord?.Pet)
    ?.reduce((acc: any[], pet) => {
      if (!acc.find(p => p.id === pet.id)) {
        acc.push(pet);
      }
      return acc;
    }, []);
  const petsNames = pets?.map(pet => pet?.name).join(' & ');
  const petsProfilePics = pets.map(pet => pet?.profile_pic);
  const petsBreeds = pets.map(pet => pet?.Breed?.name);
  const period = sentenceCase(subscription?.period || '');
  const customizations = orderItem?.customizations;
  const orderItems = subscriptionRepeats?.map(repeat => repeat?.OrderItem) || [];
  const orderTotal = `${getBranchCurrencySymbol()}${order?.total}`;
  const lastRepeatTotal = `${getBranchCurrencySymbol()}${lastRepeat?.total}`;
  const totalPrices = orderItems.map(orderItem => {
    if (orderItem?.customizations?.length) {
      const nextPrice = orderItem?.customizations?.reduce(
        (totalCustom, customization) =>
          totalCustom +
          (Number(orderItem?.item?.price || 0) + getKeys(customization?.fields || {}).reduce((extra, customKey) => extra + Number(customization.fields[customKey].price), 0)) * customization.quantity,
        0
      );
      return nextPrice;
    }
    const nextPrice = Number(orderItem.item.price) * orderItem.quantity;
    return nextPrice;
  });
  const total: number = totalPrices[0] || 0;
  const voucher = orderVouchers?.[0]?.voucher;
  const discount = voucher?.discount?.type === 'percentage' ? `%${voucher?.discount?.amount}` : `${getBranchCurrencySymbol()}${voucher?.discount?.amount}`;
  const isFree = total < 0.5;
  const isCanceled = status === 'CANCELED';
  const isOnTrial = subscription?.trial_end && new Date(subscription?.trial_end).getTime() > new Date().getTime();
  const activeText = isOnTrial ? 'Trial' : isCanceled ? 'Canceled' : 'Active';
  return {
    subscriptionNumber,
    subscriptionName,
    time,
    user,
    petsNames,
    petsProfilePics,
    petsBreeds,
    pets,
    period,
    orderTotal,
    date,
    nextDate,
    lastOrderDate,
    subscriptionRepeats,
    orderItem,
    lastRepeatTotal,
    product,
    order,
    total,
    discount,
    status,
    customizations,
    isFree,
    voucher,
    isCanceled,
    activeText
  };
};

export const getSubscriptionRepeatTitles = (subscriptionRepeat: Subscription['OrderSubscriptionRepeats'][0]) => {
  const createdAt = toReadableDate(subscriptionRepeat?.payment?.invoice_date || subscriptionRepeat?.createdAt, { withDay: true, isLocale: true });
  const subscriptionRepeatId = subscriptionRepeat?.id;
  const subscriptionRepeatOrderNumber = subscriptionRepeat?.OrderItem?.Order?.number;
  const isSubscriptionRepeatBilled = subscriptionRepeat.status === ORDER_REPEAT_STATUS.PAYMENT_SUCCEEDED;
  const isConfirimed = subscriptionRepeat.status === ORDER_REPEAT_STATUS.CONFIRMED;
  const isSubscriptionRepeatRefunded = subscriptionRepeat.status === ORDER_REPEAT_STATUS.REFUNDED;
  const isSubscriptionRepeatRejected = subscriptionRepeat.status === ORDER_REPEAT_STATUS.PAYMENT_REJECTED;
  const isSubscriptionRepeatPending = subscriptionRepeat.status === ORDER_REPEAT_STATUS.PAYMENT_PENDING;
  const isSubscriptionRepeatCanceled = subscriptionRepeat.status === ORDER_REPEAT_STATUS.CANCELED;
  const isSubscriptionRepeatUnbilled = !subscriptionRepeat?.OrderItem?.Order?.id && !isSubscriptionRepeatCanceled && !isSubscriptionRepeatRefunded && !isSubscriptionRepeatRejected;

  const statusTitleString = isSubscriptionRepeatUnbilled
    ? 'Unpaid'
    : isSubscriptionRepeatPending
    ? 'Pending'
    : isSubscriptionRepeatRejected
    ? 'Rejected'
    : isSubscriptionRepeatRefunded
    ? 'Refunded'
    : isSubscriptionRepeatCanceled
    ? 'Canceled'
    : isSubscriptionRepeatBilled
    ? 'Paid'
    : '';
  const isPartiallyRefunded = [ORDER_REPEAT_STATUS.PARTIALLY_REFUNDED].includes(subscriptionRepeat?.status);
  const statusTitle = ORDER_SUBSCRIPTION_REPEAT_STATUS_TITLES[subscriptionRepeat?.status];
  const isMarkedAsPaid = subscriptionRepeat?.payment?.status === 'mark_as_paid';
  const isUncollectible = subscriptionRepeat?.payment?.status === 'uncollectible';
  const isBilled = subscriptionRepeat.status === ORDER_REPEAT_STATUS.PAYMENT_SUCCEEDED;
  const isConfirmed = subscriptionRepeat.status === ORDER_REPEAT_STATUS.CONFIRMED;
  const isDraft = subscriptionRepeat.status === ORDER_REPEAT_STATUS.REQUESTED;

  const color = ORDER_STATUS_TITLES_COLORS[subscriptionRepeat?.status];
  const isRed = (!isBilled && !isConfirmed) || color === 'red';
  const isGreen = isBilled && color === 'green';
  const isBlue = isConfirmed || color === 'blue';
  const repeatTotal = `${getBranchCurrencySymbol()}${subscriptionRepeat?.total}`;
  const failReason = subscriptionRepeat?.payment?.fail_reason;
  return {
    createdAt,
    statusTitle,
    isRed,
    isGreen,
    isBlue,
    isBilled,
    repeatTotal,
    failReason,
    isConfirmed,
    isMarkedAsPaid,
    isDraft,
    isUncollectible,
    subscriptionRepeatId,
    isSubscriptionRepeatBilled,
    subscriptionRepeatOrderNumber,
    statusTitleString,
    isSubscriptionRepeatCanceled,
    isSubscriptionRepeatPending,
    isSubscriptionRepeatRejected,
    isSubscriptionRepeatRefunded,
    isSubscriptionRepeatUnbilled,
    isPartiallyRefunded
  };
};
