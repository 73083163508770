import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import Colors from '../../Colors';

export const SideTabContainer = styled.div<{ windowHeight: number }>`
  background-color: ${Colors.white};
  height: ${props => props.windowHeight}px;
  max-height: ${props => props.windowHeight}px;
  flex-direction: column;
  display: flex;

  width: 90px;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    z-index: 100;
    flex-direction: row;
    height: 70px;
  }
`;
export const LogoImage = styled.img`
  height: 75px;
  width: 75px;
`;
export const LogoContainer = styled.div`
  margin-left: 32px;
  margin-top: 14px;
  margin-left: 0;
`;
export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-left: 0;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 0;
  }
`;

export const SideBarMenu = styled.div`
  overflow-y: scroll;
  padding-top: 28px;
  padding-bottom: 10px;
  flex: 1;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding: 14px 0;
    height: 70px;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: ${Colors.grey};
  }
`;
export const MenuItem = styled.div<{ selected: boolean }>`
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${props => (props.selected ? Colors.black : Colors.secondaryGrey)};
  width: 70px;
  &:hover {
    color: ${Colors.black};
    background-color: ${Colors.sideTabBackgroundColor};
    cursor: pointer;
    transition: 0.1s ease-in all;
  }

  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const Name = styled.span`
  font-size: 10px;
  font-weight: 600;
`;

export const MenuItemNotification = styled.div`
  height: 16px;
  width: 16px;
  padding: 2px;
  border-radius: 50%;
  background-color: #ff0000;
  position: absolute;
  font-size: 10px;
  color: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  top: -8px;
  right: 14px;
  z-index: 100;
`;

export const Icon = styled(GatsbyImage)<{ selected: boolean }>`
  ${props =>
    !props.selected &&
    css`
      opacity: 0.6;
    `}

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;
export const Divider = styled.div`
  height: 1px;
  background-color: ${Colors.divider};
`;
export const DefaultBusinessUserImage = styled(GatsbyImage)<{ width?: string; height?: string }>`
  height: ${props => props.height || '40px'};
  width: ${props => props.width || '40px'};
  border-radius: 30px;
  object-fit: cover;
  /* margin-bottom: 16px; */
`;

export const BusinessUserImage = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    margin: 0;
    position: absolute;
    height: 40px;
    width: 40px;
    right: 0;
    top: -20px;
  }
`;

export const BusinessUserImageContainer = styled.div`
  margin: 32px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media (max-width: 768px) {
    margin: 0;
    width: auto;
    margin-left: auto;
    cursor: pointer;
    position: relative;
  }
`;
export const BusinessUserName = styled.p`
  font-size: 16px;
  color: ${Colors.subtitle2};
  font-weight: bold;
  margin: 0;
  margin-bottom: 8px;
  display: none;
`;
export const ServiceName = styled.p`
  font-size: 14px;
  color: ${Colors.subtitle2};
  margin: 0;
  margin-bottom: 8px;
  display: none;
`;
export const BusinessUserContainer = styled.div`
  justify-content: center;
  align-items: center;
`;

export const LogOutButton = styled.label`
  color: ${Colors.secondaryGrey};
  &:hover {
    cursor: pointer;
  }
  font-size: 10px;
  font-weight: 600;
`;

export const CalendarToolBarContainer = styled.div<{ padding?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.padding || '10px'};
  height: 64px;
`;
