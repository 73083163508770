import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FlexContainer } from '../../../components/Pets/styled';
import { Pet } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, SectionLabel, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { EditPetRecord, GetAllCountries } from '../../../queries';
import { Country } from '../../Store/types';

type VetInsurerPetRecordModalFormProps = {
  onSubmit: () => void;
  loading: boolean;
  error: boolean;
  formOptions: UseFormMethods<FormType>;
  defaultValues: Partial<FormType>;
  countries: Country[];
};
const InsurancePetRecordModalForm: FC<VetInsurerPetRecordModalFormProps> = ({ loading, error, formOptions, onSubmit, defaultValues, countries }) => {
  const { control, errors, setValue } = formOptions;

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
          Update
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Insurer</FormLabel>
        <Controller
          as={<FormInput error={errors.name} type={'text'} flexBasis={78} />}
          control={control}
          name={'name'}
          defaultValue={defaultValues.name || ''}
          rules={{ required: 'Please add a name' }}
        />
        {errors.name && <FormError>{errors.name.message || 'insurer name is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Policy Number</FormLabel>
        <Controller
          as={<FormInput error={errors.policyNumber} type={'text'} flexBasis={78} />}
          control={control}
          name={'policyNumber'}
          defaultValue={defaultValues.policyNumber || ''}
          rules={{ required: 'Please add a policy number' }}
        />
        {errors.policyNumber && <FormError>{errors.policyNumber.message || 'Policy number is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Start Date</FormLabel>
        <Controller as={<FormInput error={errors.startDate} type={'date'} flexBasis={78} />} control={control} name={'startDate'} defaultValue={defaultValues.startDate || ''} />
        {errors.startDate && <FormError>{errors.startDate.message || 'Start date is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>End Date</FormLabel>
        <Controller as={<FormInput error={errors.endDate} type={'date'} flexBasis={78} />} control={control} name={'endDate'} defaultValue={defaultValues.endDate || ''} />
        {errors.endDate && <FormError>{errors.endDate.message || 'End date is required'}</FormError>}{' '}
      </WideInputGroup>

      <WideInputGroup>
        <SectionLabel>Address</SectionLabel>
        <FormLabel>Country</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <FormSelect error={!!errors?.address?.country} height={48} fontSize={16} value={value} onChange={onChange}>
              {countries.map(country => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </FormSelect>
          )}
          control={control}
          name={'address.country'}
          defaultValue={defaultValues?.address?.country || String(countries[0]?.id) || ''}
        />
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>City</FormLabel>
        <Controller
          as={<FormInput error={!!errors?.address?.city} type={'text'} name={'address.city'} />}
          control={control}
          name={'address.city'}
          defaultValue={defaultValues?.address?.city || ''}
          rules={{ pattern: /^[a-zA-Z\s]{3,}$/ }}
        />
        {errors?.address?.city && <FormError>{errors?.address?.city?.message || 'Please add a valid city'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Address Line 1</FormLabel>
        <Controller
          as={<FormInput error={!!errors?.address?.line1} type={'text'} name={'address.line1'} />}
          control={control}
          name={'address.line1'}
          defaultValue={defaultValues?.address?.line1 || ''}
          rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
        />
        {errors?.address?.line1 && <FormError>{errors?.address?.line1?.message || 'Please add a valid address'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Address Line 2</FormLabel>
        <Controller
          as={<FormInput error={!!errors?.address?.line2} type={'text'} name={'address.line2'} />}
          control={control}
          name={'address.line2'}
          defaultValue={defaultValues?.address?.line2 || ''}
          rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
        />
        {errors?.address?.line2 && <FormError>{errors?.address?.line2?.message || 'Please add a valid address'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <SectionLabel>Phone</SectionLabel>

        <FlexContainer gap={6}>
          <Controller
            as={<FormInput error={!!errors?.phoneNumber?.country_code} type={'text'} name={'phoneNumber.country_code'} width={80} />}
            control={control}
            name={'phoneNumber.country_code'}
            defaultValue={defaultValues?.phoneNumber?.country_code}
            rules={{ pattern: /^(\+?\d{1,3}|\d{1,4})$/ }}
          />
          <Controller
            as={<FormInput error={!!errors?.phoneNumber?.number} type={'text'} name={'phoneNumber.number'} fullWidth />}
            control={control}
            name={'phoneNumber.number'}
            defaultValue={defaultValues?.phoneNumber?.number || ''}
            rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
          />
        </FlexContainer>
        {errors?.phoneNumber?.country_code && <FormError>{errors?.phoneNumber?.country_code?.message || 'Please add a valid country code'}</FormError>}
        {errors?.phoneNumber?.number && <FormError>{errors?.phoneNumber?.number?.message || 'Please add a valid number'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Email</FormLabel>
        <Controller as={<FormInput error={errors.email} type={'text'} flexBasis={78} />} control={control} name={'email'} defaultValue={defaultValues.email || ''} rules={{ required: false }} />

        {errors.email && <FormError>{errors.email.message || 'Email is required'}</FormError>}
      </WideInputGroup>
    </ModalLayout>
  );
};

type FormType = {
  name: string;
  address: {
    country: string;
    city: string;
    line1: string;
    line2: string;
  };
  email: string;
  phoneNumber: {
    country_code: string;
    number: string;
  };
  policyNumber: string;
  startDate: string;
  endDate: string;
};

const InsurancePetRecordModal = ({ petVetInsurer }: { petVetInsurer: Pet }) => {
  const { data: { countryGet: countries = [] } = {} } = useQuery<{ countryGet: Country[] }>(GetAllCountries, {
    fetchPolicy: 'cache-first'
  });

  const getCountryIdFromName = (name: string) => String(countries.find(country => country.name === name)?.id) || '';
  const getCountryNameFromId = (id: string) => countries.find(country => String(country.id) === id)?.name || '';

  const insurer = petVetInsurer?.PetRecord?.insurer;
  const name = insurer?.name;
  const address = insurer?.address;
  const phone = insurer?.phone;
  const email = insurer?.email;
  const start_date = insurer?.start_date;
  const end_date = insurer?.end_date;
  const policyNumber = insurer?.policy_number;

  const defaultValues: FormType = {
    address: {
      country: getCountryIdFromName(address?.country || 'United Kingdom') || (countries[0]?.id ? String(countries[0]?.id) : '1'),
      city: address?.city || 'London',
      line1: address?.line1 || '',
      line2: address?.line2 || ''
    },

    email: email || '',
    phoneNumber: {
      country_code: phone?.country_code || '+44',
      number: phone?.number || ''
    },
    name: name || '',
    startDate: start_date || '',
    endDate: end_date || '',
    policyNumber: policyNumber || ''
  };

  const formOptions = useForm<FormType>({ defaultValues });
  const { handleSubmit } = formOptions;

  const [handleEditPetRecord, { data: editedPetRecord, loading: loadingEditPetRecord, error: errorEditPetRecord }] = useMutation(EditPetRecord);

  const onSubmit = handleSubmit(form => {
    const addressToSend = { ...form.address, country: getCountryNameFromId(form.address.country) };

    handleEditPetRecord({
      variables: {
        id: petVetInsurer?.PetRecord?.id,
        insurer: {
          name: form?.name,
          policy_number: form?.policyNumber,
          start_date: form?.startDate,
          end_date: form?.endDate,
          email: form?.email,
          phone: form?.phoneNumber,
          address: addressToSend
        }
      }
    });
  });

  useEffect(() => {
    if (editedPetRecord?.editPetRecord?.id) {
      ModalDialog?.closeModal();
    }
  }, [editedPetRecord]);

  const loading = loadingEditPetRecord;
  const error = !!errorEditPetRecord?.message;

  return <InsurancePetRecordModalForm onSubmit={onSubmit} loading={loading} error={error} formOptions={formOptions} defaultValues={defaultValues} countries={countries} />;
};

export default InsurancePetRecordModal;
