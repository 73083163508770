import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import React, { useCallback, useEffect } from 'react';
import Notifier from '../Notifier';
import NotificationBox from '../components/Shared/NotificationBox';
import { BranchUnreadItemsSubscription, GetBranchUnreadItemsCount, GetBusUserProfile } from '../queries';

export type GetBranchUnreadItemsCountReturn = {
  unreadProductOrdersCount: number;
  unreadServiceOrdersCount: number;
  unreadSubscriptionOrdersCount: number;
  unreadChatRoomsCount: number;
  shouldNotify: boolean;
};

enum NotificationType {
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  CHAT = 'CHAT'
}

const NotificationTypeItems: {
  [key in NotificationType]: {
    title: string;
    message: string;
    onClick: () => void;
  };
} = {
  [NotificationType.PRODUCT]: {
    title: 'New Order',
    message: 'You have a new order request',
    onClick: () => navigate('/app/orders')
  },
  [NotificationType.SERVICE]: {
    title: 'New Booking',
    message: 'You have a new booking request',
    onClick: () => navigate('/app/bookings/new')
  },
  [NotificationType.SUBSCRIPTION]: {
    title: 'New Membership',
    message: 'You have a new membership subscription',
    onClick: () => navigate('/app/memberships')
  },
  [NotificationType.CHAT]: {
    title: 'New Message',
    message: 'You have a new message',
    onClick: () => navigate('/app/chat')
  }
};

const useBranchUnreadItemsCount = () => {
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);

  const { data: unreadOrdersCount, subscribeToMore } = useQuery<{ getBranchUnreadItemsCount: GetBranchUnreadItemsCountReturn }>(GetBranchUnreadItemsCount, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const { unreadProductOrdersCount, unreadServiceOrdersCount, unreadSubscriptionOrdersCount, unreadChatRoomsCount, shouldNotify } = unreadOrdersCount?.getBranchUnreadItemsCount || {};

  const showNotifier = useCallback(
    (type: NotificationType) => {
      if (!shouldNotify) {
        return;
      }
      const { title, message, onClick } = NotificationTypeItems[type];
      const notification = Notifier.success({
        duration: 4000,
        message: title,
        description: message,
        action: {
          label: 'View',
          onClick: () => {
            onClick();
            Notifier.remove(notification);
          }
        }
      });
    },
    [shouldNotify]
  );

  const showNotifierOnDiff = useCallback(
    (type: NotificationType, diff: number) => {
      const arr = new Array(diff).fill(0);
      arr.forEach(() => {
        showNotifier(type);
      });
    },
    [showNotifier]
  );

  useEffect(() => {
    const sub = subscribeToMore<{ branchUnreadItemsUpdated: GetBranchUnreadItemsCountReturn }>({
      document: BranchUnreadItemsSubscription,
      variables: { BranchId: [profile?.Branch?.id], BusUserId: [profile?.id] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newCounts = subscriptionData?.data?.branchUnreadItemsUpdated;

        const { unreadChatRoomsCount, unreadProductOrdersCount, unreadServiceOrdersCount, unreadSubscriptionOrdersCount, shouldNotify } = prev?.getBranchUnreadItemsCount || {};

        const serviceOrdersDiff = newCounts.unreadServiceOrdersCount - unreadServiceOrdersCount;
        const productOrdersDiff = newCounts.unreadProductOrdersCount - unreadProductOrdersCount;
        const subscriptionOrdersDiff = newCounts.unreadSubscriptionOrdersCount - unreadSubscriptionOrdersCount;
        const chatRoomsDiff = newCounts.unreadChatRoomsCount - unreadChatRoomsCount;

        if (shouldNotify) {
          if (serviceOrdersDiff > 0) {
            showNotifierOnDiff(NotificationType.SERVICE, serviceOrdersDiff);
          }

          if (productOrdersDiff > 0) {
            showNotifierOnDiff(NotificationType.PRODUCT, productOrdersDiff);
          }

          if (subscriptionOrdersDiff > 0) {
            showNotifierOnDiff(NotificationType.SUBSCRIPTION, subscriptionOrdersDiff);
          }

          if (chatRoomsDiff > 0) {
            // disabled for now
            // showNotifierOnDiff(NotificationType.CHAT, chatRoomsDiff);
          }
        }

        return Object.assign({}, prev, {
          getBranchUnreadItemsCount: {
            unreadProductOrdersCount: newCounts.unreadProductOrdersCount,
            unreadServiceOrdersCount: newCounts.unreadServiceOrdersCount,
            unreadSubscriptionOrdersCount: newCounts.unreadSubscriptionOrdersCount,
            unreadChatRoomsCount: newCounts.unreadChatRoomsCount,
            shouldNotify: newCounts.shouldNotify,
            __typename: 'BranchUnreadItemsCount'
          }
        });
      }
    });
    return () => sub();
  }, [subscribeToMore, profile?.Branch?.id]);

  return {
    unreadProductOrdersCount,
    unreadServiceOrdersCount,
    unreadSubscriptionOrdersCount,
    unreadChatRoomsCount,
    shouldNotify
  };
};

export default useBranchUnreadItemsCount;
