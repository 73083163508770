import React, { ReactElement, RefAttributes } from 'react';
import ReactSelect, { GroupBase } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { selectTheme } from './Forms';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import SelectType from 'react-select/dist/declarations/src/Select';
import Colors from '../../../Colors';

const Select: <Option = unknown, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: StateManagerProps<Option, IsMulti, Group> &
    RefAttributes<SelectType<Option, IsMulti, Group>> & { blackTheme?: boolean; isCreatable?: boolean } & CreatableProps<Option, IsMulti, Group> &
    AsyncProps<Option, IsMulti, Group>
) => ReactElement = props => {
  const { isCreatable } = props;

  const Component = isCreatable ? CreatableSelect : ReactSelect;
  return (
    <Component
      theme={selectTheme}
      menuPosition="fixed"
      styles={{
        container: (provided, state) => ({
          ...provided,
          border: 'none',
          width: '100%'
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          fontSize: 14,
          fontWeight: 600,
          border: 'none'
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: 14,
          color: state.isSelected ? Colors.white : Colors.black,
          backgroundColor: state.isSelected ? Colors.secondaryGrey : Colors.white,
          '&:hover': {
            backgroundColor: Colors.toolsBarBackgroundColor,
            color: Colors.black
          }
        })
      }}
      placeholder="Select..."
      {...(props.blackTheme && {
        theme: theme => ({
          ...theme,
          borderRadius: 6,
          spacing: {
            baseUnit: 2,
            controlHeight: 34,
            menuGutter: 4
          },
          colors: {
            ...theme.colors,
            primary25: Colors.black,
            primary: Colors.black,
            danger: Colors.error,
            neutral0: Colors.black,
            primary50: Colors.black,
            neutral50: Colors.grey
          }
        }),
        components: {
          ClearIndicator: () => null
        },
        styles: {
          option: provided => ({
            ...provided,
            color: Colors.white,
            fontWeight: 700,
            fontSize: 12
          }),
          singleValue: provided => ({
            ...provided,
            color: Colors.white,
            fontWeight: 700,
            fontSize: 12
          }),
          placeholder: provided => ({
            ...provided,
            color: Colors.white,
            fontWeight: 400,
            fontSize: 12
          }),
          control: provided => ({
            ...provided,
            borderWidth: 0
          }),
          container: provided => ({
            ...provided,
            borderWidth: 0
          })
        }
      })}
      {...props}
    />
  );
};

export default Select;
