import React from 'react';
import { FormError, FormLabel, InputGroup, InputsWrapper } from '../../../../components/Shared/Forms/Forms';
import ProductOptions, { ProductOptionsTypes } from '../../ProductOptions';
import ProductPrice from '../../ProductPrice';
import { StoreProductModalSectionsCommonProps, subscriptionPeriodOptions } from './types';
import { Controller } from 'react-hook-form';
import Select from '../../../../components/Shared/Forms/Select';

const StoreProductModalPricing = ({ formOptions, defaultValues, type }: StoreProductModalSectionsCommonProps) => {
  const { control, errors } = formOptions;
  const isSubscription = type === 'subscription';
  return (
    <>
      <ProductPrice formOptions={formOptions} defaultValues={defaultValues} type={type} />

      {isSubscription && (
        <InputGroup>
          <FormLabel>Membership Interval</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                options={subscriptionPeriodOptions}
                value={{ value: value, label: subscriptionPeriodOptions.find(option => option.value === value)?.label }}
                onChange={option => {
                  onChange(option?.value || subscriptionPeriodOptions[0].value);
                }}
              />
            )}
            control={control}
            name={'period'}
            defaultValue={defaultValues?.subscription_period}
            rules={{ required: true }}
          />
          {errors.subscription_period && <FormError>{errors.subscription_period.message || 'period is required'}</FormError>}
        </InputGroup>
      )}
      <ProductOptions
        formOptions={formOptions}
        type={type}
        defaultValues={defaultValues?.productOptions}
        optionsToShow={[
          ProductOptionsTypes.AUTOMATICALLY_PICK_BEST_DISCOUNT,
          ProductOptionsTypes.FROM,
          ProductOptionsTypes.SHOW_CUSTOMIZATIONS_PRICE,
          ProductOptionsTypes.USE_PETS_COUNT,
          ProductOptionsTypes.AUTO_CONFIRM_ORDER,
          ProductOptionsTypes.ENABLE_CUSTOMIZATIONS_QUANTITY_FACTOR,
          ProductOptionsTypes.OPTIONAL_CARD_DETAILS
        ]}
      />
    </>
  );
};

export default StoreProductModalPricing;
