import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GetBranchPetProfile } from '../../queries';
import { vars } from '../../reactive';
import { ActionButton, ActionButtonsContainer, Divider, DrawerContainer, DrawerSection } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { Pet } from '../Pets/types';
import PetProfileInfo from '../Profile/PetProfileInfo';
import ProfileActionBar, { SMALL_DESCRIPTION_POSITIONS } from '../Profile/ProfileActionBar';
import ProfileCover from '../Profile/ProfileCover';
import { LoadingOverlay } from '../Shared/Spinner';
import PetExtension from './PetExtension';
import { PetContainer, PetDrawerTag, PetDrawerTagsContainer } from './styled';
import Colors from '../../Colors';

const PetDrawer = ({ data: petId = '' }: { data: string }) => {
  const { data: { getBranchPetProfile: profile } = {}, loading } = useQuery<{ getBranchPetProfile: Pet }>(GetBranchPetProfile, {
    variables: { id: petId },
    fetchPolicy: 'cache-and-network'
  });

  const { mobile } = useMediaQuery({ mobile: true });

  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petProfile = (profile || {}) as Pet;
  const petTags = petProfile?.PetRecord?.BranchPetRecordTags || [];
  const [selected, setSelected] = useState('details');

  return (
    <PetContainer>
      {loading && <LoadingOverlay />}
      <DrawerContainer flexZero>
        <ProfileCover profile={petProfile} showEdit={!!drawerBar?.isExtended} />
        {!!petTags?.length && (
          <>
            <PetDrawerTagsContainer padding="12px 20px">
              {petTags?.map((item, index) => (
                <PetDrawerTag color={Colors.black} key={index}>
                  <span>{item.name}</span>
                </PetDrawerTag>
              ))}
            </PetDrawerTagsContainer>
          </>
        )}
        <ActionButtonsContainer>
          <ActionButton onClick={() => setSelected('details')} isSelected={selected === 'details'}>
            Details
          </ActionButton>
          <ActionButton isSelected={selected === 'notes'} onClick={() => setSelected('notes')}>
            Notes
          </ActionButton>
        </ActionButtonsContainer>
        {!mobile && (
          <>
            <PetProfileInfo profile={petProfile} selected={selected} />
            <Divider />
            <DrawerSection paddingT={20} paddingB={20}>
              <ProfileActionBar smallDescriptionPosition={SMALL_DESCRIPTION_POSITIONS.UP} profile={petProfile?.AppUser} drawerId={DRAWER_IDS.PETS_DRAWER} />
            </DrawerSection>
          </>
        )}
      </DrawerContainer>
      {(drawerBar?.isExtended || mobile) && <PetExtension petProfile={petProfile} />}
    </PetContainer>
  );
};

export default PetDrawer;
