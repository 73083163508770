import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { RecordBody, RecordDataContainer } from '../../styled';
import { Branch } from '../../../components/Profile/types';
import ProviderBranchModal from './ProviderBranchModal';
import { statusOptions } from './types';

const ProviderBranchRecord = ({ branch }: { branch: Branch }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <ProviderBranchModal branch={branch} />,
      title: 'Branch Settings & Branding'
    });
  };

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>{branch?.name}</RecordBody>
      <RecordBody fontWeight={'800'}>{statusOptions.find(st => st.value === branch?.BranchTargeting?.status)?.label}</RecordBody>
    </RecordDataContainer>
  );
};

export default ProviderBranchRecord;
