import React from 'react';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import SideTab from '../../components/Shared/SideTabs/SideTabs';
import useMediaQuery from '../../hooks/useMediaQuery';
import PetsBody from './PetsBody/PetsBody';
import PetClientsBody from './PetsClients/PetsClientsBody';
import PetsForms from './PetsForms/PetsForms';
import AddClientPetAction from './AddClientAction';

export enum PETS_TABS_TYPES {
  FORMS = 'Forms',
  ACTIVE = 'Active',
  CLIENTS = 'Clients',
  ARCHIVED = 'Archived',
  PETS = 'Pets'
}

const sections = [
  {
    tabs: [
      {
        name: PETS_TABS_TYPES.ACTIVE,
        value: PETS_TABS_TYPES.ACTIVE.toLowerCase()
      },
      {
        name: PETS_TABS_TYPES.ARCHIVED,
        value: PETS_TABS_TYPES.ARCHIVED.toLowerCase()
      }
    ]
  },
  {
    tabs: [
      {
        name: PETS_TABS_TYPES.CLIENTS,
        value: PETS_TABS_TYPES.CLIENTS.toLowerCase()
      }
    ]
  },
  {
    tabs: [
      {
        name: PETS_TABS_TYPES.FORMS,
        value: PETS_TABS_TYPES.FORMS.toLowerCase()
      }
    ]
  }
];

const mobileSections = [
  {
    tabs: [
      {
        name: PETS_TABS_TYPES.PETS,
        value: PETS_TABS_TYPES.ACTIVE.toLowerCase()
      }
    ]
  },

  {
    tabs: [
      {
        name: PETS_TABS_TYPES.CLIENTS,
        value: PETS_TABS_TYPES.CLIENTS.toLowerCase()
      }
    ]
  }
];

const Pets = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const sectionsToDisplay = mobile ? mobileSections : sections;
  const tabs = sections.flatMap(section => section.tabs);

  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) as PETS_TABS_TYPES;
  const selectedTabState = tabValue || PETS_TABS_TYPES.ACTIVE.toLowerCase();

  return (
    <ViewWrapper>
      {!mobile && <SideTab sections={sectionsToDisplay} count={true} tabsWithCount={[PETS_TABS_TYPES.FORMS]} defaultTab={PETS_TABS_TYPES.ACTIVE} />}

      <MainContainer>
        {mobile && <SideTab sections={sectionsToDisplay} count={true} tabsWithCount={[PETS_TABS_TYPES.FORMS]} defaultTab={PETS_TABS_TYPES.ACTIVE} components={[AddClientPetAction]} />}
        {PETS_TABS_TYPES.CLIENTS.toLowerCase() === selectedTabState ? (
          <PetClientsBody />
        ) : PETS_TABS_TYPES.FORMS.toLowerCase() === selectedTabState ? (
          <PetsForms />
        ) : (
          <PetsBody selected={selectedTabState} />
        )}
      </MainContainer>
    </ViewWrapper>
  );
};

export default Pets;
