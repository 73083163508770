import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import Colors from '../../Colors';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { AddBusUserToChatRoom, ChatSubscription, GetBranchAppUserTags, GetBranchChatRoom, GetBranchChatRooms, GetBusUserProfile, GetMessages, UpdateMessageReadStatus } from '../../queries';
import { vars } from '../../reactive';
import client from '../../utils/client';
import { UsersContainer, UsersNamesContainer } from '../../views/Bookings/components/BookingsDrawer/styled';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import { RecordBody } from '../../views/styled';
import ChatRecordOptions, { ChatRecordOptionsRef } from '../Chat/ChatRecordOptions';
import { MobileCloseButton } from '../DrawerBar/DrawerButtons';
import { DefaultProfilePhoto, Divider, DrawerContainer, DrawerSection, ProfilePhoto } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { PetDrawerTag, PetDrawerTagsContainer } from '../PetDrawer/styled';
import { PetRecordOptionsContainer } from '../Pets/styled';
import ProfileActionBar from '../Profile/ProfileActionBar';
import InfiniteList, { InfiniteListActions } from '../Shared/InfiniteList/InfiniteList';
import { ActivityIndicator } from '../Shared/Spinner';
import InputBar from './InputBar';
import MessageItem from './MessageItem';
import { CloseButtonContainer, GroupHeaderImagesContainer, JoinRoomButton } from './styled';

const ChatDrawer = ({ data: chatRoomId = '' }: any) => {
  const infiniteListActions = useRef<InfiniteListActions>({ scrollToEnd: () => {} });
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const { data: { getBranchChatRooms: [room] = [] } = {}, refetch: refetchRoom } = useQuery(GetBranchChatRoom, {
    variables: { chatRoom_id: [chatRoomId], requisite_queries: ['chatRoom_id'] },
    skip: !chatRoomId
  });

  const isStaff = room?.is_bus_room;

  const chatRecordOptionsRef = useRef<ChatRecordOptionsRef>(null);
  const messageReadStatus = room?.MessageReadStatuses?.find(status => status.BusUser?.id === profile?.id);

  const [[hasMoreItems, setHasMoreItems], { subscribeToMore, data: { getMessages: messages = [] } = {}, loading, fetchMore, refetch: refetchMessages }] = usePaginatedQuery({
    query: GetMessages,
    otherVariables: { ChatRoomId: chatRoomId },
    otherParams: { fetchPolicy: 'cache-and-network' }
  });

  const [updateMessageReadStatus, { loading: loadingUpdateStatus }] = useMutation(UpdateMessageReadStatus);

  const [addUserToChat, { loading: loadingJoin }] = useMutation(AddBusUserToChatRoom);

  const isUserInvited = isStaff ? true : room?.BusUsers?.some(user => user.id === profile.id);
  const roomUsers = room ? [...(room?.AppUsers || []), ...room?.BusUsers] : [];

  const userTags = room?.AppUsers?.flatMap(user => user?.BranchAppUserTags)?.map(tag => tag);
  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 },
    skip: isStaff
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];

  const joinRoom = async () => {
    await addUserToChat({ variables: { BusUserId: profile.id, ChatRoomId: room.id } });
    refetchRoom();
  };

  useEffect(() => {
    const sub = subscribeToMore({
      document: ChatSubscription,
      variables: { ChatRooms: [chatRoomId] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        const newMessage = subscriptionData?.data?.messageAdded;
        const list = [{ ...newMessage, __typename: 'Message' }, ...prev?.getMessages];
        infiniteListActions?.current?.scrollToEnd();
        return Object.assign({}, prev, { getMessages: [...(list || [])] });
      }
    });
    return () => sub();
  }, []);

  useEffect(() => {
    if (room && messages?.length > 0 && !loadingUpdateStatus) {
      recordMessageReadStatusForUser(messages[0]);
    }
  }, [messages?.length]);

  const recordMessageReadStatusForUser = (message: any) => {
    if (messageReadStatus?.last_read_message === message?.id) {
      return;
    }
    updateMessageReadStatus({
      variables: {
        ChatRoomId: chatRoomId,
        BranchId: profile?.Branch?.id,
        last_read_message: message ? message.id : null,
        last_read_timestamp: new Date(),
        status: 'READ'
      }
    }).then(() => {
      if (!messageReadStatus) {
        refetchRoom();
      }
    });
  };

  const drawer = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.CHAT_DRAWER)!;
  const { mobile, tablet } = useMediaQuery({ mobile: true, tablet: true });

  const onSendMessage = async () => {
    const { data: { getMessages: list = [] } = {} } = await refetchMessages();
    const currentQuery = client.readQuery({
      query: GetBranchChatRooms
    });
    const currentRoom = currentQuery?.getBranchChatRooms?.find(room => room.id === chatRoomId);
    const updatedRoom = {
      ...currentRoom,
      Messages: list
    };

    client.writeQuery({
      query: GetBranchChatRooms,
      data: {
        getBranchChatRooms: [...(currentQuery?.getBranchChatRooms || [])].map(room => {
          if (room.id === chatRoomId) {
            return updatedRoom;
          }
          return room;
        })
      }
    });
  };
  const users = isStaff ? room?.BusUsers?.filter(user => user.id !== profile.id) : room?.AppUsers || [];

  const icons = useIcons();
  const usersProfileImages = users?.map(user => user?.profile_pic);
  const usersNames = users?.map(user => user?.name);
  return (
    <>
      <DrawerContainer>
        <DrawerSection paddingT={mobile ? 12 : tablet ? 16 : 20} paddingB={mobile ? 12 : tablet ? 16 : 20} zIndex={10} flexRow center>
          {mobile && (
            <CloseButtonContainer>
              <MobileCloseButton drawer={drawer} />
            </CloseButtonContainer>
          )}
          {!!users?.length && users?.map(user => <ProfileActionBar drawerId={DRAWER_IDS.CHAT_DRAWER} key={user.id} profile={user} showChat={false} />)}

          {users?.length > 1 && !!userTags?.length && (
            <UsersContainer>
              <GroupHeaderImagesContainer>
                {usersProfileImages?.map((profileImage, index) => {
                  if (profileImage) return profileImage && <ProfilePhoto bgImage={profileImage} key={index} isAbsolute={true} />;

                  if (!profileImage) return <DefaultProfilePhoto image={icons.userInDrawers.childImageSharp.gatsbyImageData} alt={'userImage'} key={index} isAbsolute index={index} />;
                })}
              </GroupHeaderImagesContainer>
              <UsersNamesContainer>
                <RecordBody width="200" display="block" noMargin fontWeight="800">
                  {usersNames?.join(' & ') || 'No Users'}
                </RecordBody>
              </UsersNamesContainer>
            </UsersContainer>
          )}
          <PetRecordOptionsContainer mobileMargin="0 0 0 auto">
            <ChatRecordOptions
              ref={chatRecordOptionsRef}
              showAlways={true}
              loadTags={false}
              appUsers={room?.AppUsers as BranchAppUser[]}
              appUserQuickTags={quickTags}
              removeAppUserOptions={isStaff}
              profile={profile}
              chatRoom={room}
              left={mobile}
              showOnMobileDrawer={true}
            />
          </PetRecordOptionsContainer>
        </DrawerSection>

        <Divider />

        {!!users.length && userTags.length >= 1 && (
          <PetDrawerTagsContainer padding="12px 20px">
            {userTags?.map(tag => (
              <PetDrawerTag key={tag.id}>{tag.name}</PetDrawerTag>
            ))}
          </PetDrawerTagsContainer>
        )}

        <InfiniteList
          list={messages}
          fetchMore={fetchMore}
          hasMoreItems={hasMoreItems}
          itemRenderer={(msg: any) => <MessageItem key={msg?.id} msg={msg} users={roomUsers} messageReadStatuses={room?.MessageReadStatuses} profile={profile} isStaff={isStaff} />}
          items={messages}
          loading={loading}
          refetch={refetchMessages}
          setHasMoreItems={setHasMoreItems}
          offset={messages.length}
          inversed
          listActionsRef={infiniteListActions}
          mobilePadding="0 16px 0 16px"
          gap={10}
        />

        {isUserInvited ? (
          <InputBar chatRoomId={chatRoomId} scrollToBottom={infiniteListActions?.current?.scrollToEnd} branchId={profile?.Branch?.id} onSend={onSendMessage} />
        ) : (
          <JoinRoomButton onClick={joinRoom}>{loadingJoin ? <ActivityIndicator size={12.5} color={Colors.white} /> : 'Join the Conversation'}</JoinRoomButton>
        )}
      </DrawerContainer>
    </>
  );
};

export default ChatDrawer;
