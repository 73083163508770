import { useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useRef } from 'react';
import { Controller, UseFormMethods, useForm, useWatch } from 'react-hook-form';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { ColorPicker, FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, selectTheme, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import { Container } from '../../../components/Shared/Shared';
import { ReplaceBranchTagCategoryItems } from '../../../queries';
import { vars } from '../../../reactive';
import { setDrawerBar, unsetDrawerBars } from '../../../reactive/actions';
import Common from '../Common';
import BranchTagCategories from './BranchTagCategories';
import BranchTagsAutomations from './BranchTagsAutomations';
import { AUTOMATIONS_OPTION_DROPDOWN_TYPES, BRANCH_TAGS_QUERIES, BRANCH_TAGS_TYPES, BRANCH_TAGS_TYPES_LABELS, BranchTag, TAGS_AUTOMATIONS_TYPES } from './types';
import ReactSelect from 'react-select';
import Select from '../../../components/Shared/Forms/Select';
import { getSelectedBranchTagsOptions } from './TagsModalOptions';

export type FormData = {
  name: string;
  quick_action: boolean;
  automations: { type: TAGS_AUTOMATIONS_TYPES; value: string | { name: string; value: string }[] }[];
  categoryId?: { label: string; value: string };
  color: string;
  capacity: number;
  reset_at_midnight: boolean;
  visibility?: {
    showOnBar: boolean;
    showOnTaggedItem: boolean;
    showOnAppointments: boolean;
  };
};

type BranchTagsModalFormProps = {
  branchTag?: BranchTag;
  formOptions: UseFormMethods<FormData>;
  saveError: boolean;
  saveLoading: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  defaultName?: string;
  deleteTag: () => void;
  defaultQuickTag?: boolean;
  defaultCategoryId?: string | null;
  defaultType: BRANCH_TAGS_TYPES;
  type: BRANCH_TAGS_TYPES;
};

const BranchTagsModalForm = ({
  branchTag,
  formOptions,
  saveError,
  saveLoading,
  onSubmit,
  defaultName,
  deleteTag,
  defaultQuickTag = false,
  defaultType,
  type,
  defaultCategoryId
}: BranchTagsModalFormProps) => {
  const { control, errors } = formOptions;

  return (
    <ModalLayout
      compact
      buttons={[
        branchTag ? (
          <FormSubmitButton type="button" onClick={deleteTag} loading={saveLoading} error={!!saveError} danger>
            Delete
          </FormSubmitButton>
        ) : (
          <></>
        ),
        <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
          Save
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Tag Name</FormLabel>
        <Controller as={<FormInput error={!!errors.name} />} control={control} name={'name'} defaultValue={defaultName || 'New Tag'} rules={{ required: true }} />
        {errors.name && <FormError>{errors.name.message || 'Title is required'}</FormError>}
      </WideInputGroup>
      {/* <WideInputGroup>
              <FormLabel>Capacity (0 for no limit)</FormLabel>
              <Controller
                as={<FormInput error={!!errors.capacity} type={'number'} />}
                control={control}
                name={'capacity'}
                defaultValue={branchTag?.capacity || 0}
                rules={{ required: true, validate: value => value >= 0 }}
              />
              {errors.capacity && <FormError>{errors.capacity.message || 'Capacity is required'}</FormError>}
            </WideInputGroup> */}

      {/* <WideInputGroup>
              <FormLabel>Type</FormLabel>
              <Controller
                control={control}
                name={`type`}
                render={({ onChange, value }) => {
                  return (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      fullWidth
                      disabled={saveLoading}
                      name={`type`}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={String(value)}
                    >
                      {Object.values(BRANCH_TAGS_TYPES).map(type => (
                        <option key={type} value={type}>
                          Tag for {BRANCH_TAGS_TYPES_LABELS[type]}
                        </option>
                      ))}
                    </FormSelect>
                  );
                }}
                rules={{ required: true }}
                defaultValue={defaultType}
              />
            </WideInputGroup> */}
      <WideInputGroup>
        <FormLabel>Type</FormLabel>
        <Controller
          control={control}
          name="type"
          defaultValue={defaultType}
          rules={{ required: true }}
          render={({ onChange, value }) => {
            const options = Object.values(BRANCH_TAGS_TYPES).map(type => ({
              value: type,
              label: `Tag for ${BRANCH_TAGS_TYPES_LABELS[type]}`
            }));

            return (
              <Select
                options={options}
                value={options.find(option => option.value === value) || null}
                onChange={selectedOption => onChange(selectedOption?.value || options[0].value)}
                theme={selectTheme}
              />
            );
          }}
        />
      </WideInputGroup>

      <BranchTagCategories branchTag={branchTag} formOptions={formOptions} defaultValue={defaultCategoryId} />
      <WideInputGroup>
        <FormLabel>Label Colour</FormLabel>
        <Controller
          as={<ColorPicker type={'color'} error={errors.color && errors.color.message} />}
          control={control}
          name={`color`}
          defaultValue={branchTag?.color || '#a5cdce'}
          rules={{ required: true }}
        />
      </WideInputGroup>
      <WideInputGroup>
        <BranchTagsAutomations control={control} existingTag={branchTag} errors={errors} defaultQuickTag={defaultQuickTag} type={type} defaultType={defaultType} formOptions={formOptions} />
      </WideInputGroup>
    </ModalLayout>
  );
};

const BranchTagsModal = ({
  branchTag,
  name,
  quickTag,
  presetType,
  defaultCategoryId,
  onAddTag
}: {
  branchTag?: BranchTag;
  presetType?: BRANCH_TAGS_TYPES;
  name?: string;
  quickTag?: boolean;
  defaultCategoryId?: string | null;
  onAddTag?: (newTag: BranchTag, type: BRANCH_TAGS_TYPES) => void;
}) => {
  const formOptions = useForm<FormData>();
  const { handleSubmit, control, getValues } = formOptions;
  const categoryIdRef = useRef<string | null>(null);

  const defaultType = useMemo(() => {
    if (presetType) {
      return presetType;
    }
    if (!branchTag) {
      return BRANCH_TAGS_TYPES.PET_RECORD;
    }

    if (branchTag.hasOwnProperty('appointmentsCount')) {
      return BRANCH_TAGS_TYPES.APPOINTMENT;
    }
    if (branchTag.hasOwnProperty('petRecordsCount')) {
      return BRANCH_TAGS_TYPES.PET_RECORD;
    }
    if (branchTag.hasOwnProperty('appUsersCount')) {
      return BRANCH_TAGS_TYPES.APP_USER;
    }

    return BRANCH_TAGS_TYPES.PET_RECORD;
  }, [branchTag, presetType]);

  const type = useWatch({
    control,
    name: 'type',
    defaultValue: defaultType
  }) as BRANCH_TAGS_TYPES;

  const { AddBranchTag, EditBranchTag } = BRANCH_TAGS_QUERIES[type || BRANCH_TAGS_TYPES.PET_RECORD];

  const { DeleteBranchTag } = BRANCH_TAGS_QUERIES[defaultType];

  const [addBranchTag, { data: addedTag, loading: addLoading, error: addError }] = useMutation(AddBranchTag);

  const [editBranchTag, { data: editedTag, loading: editLoading, error: editError }] = useMutation(EditBranchTag);

  const [deleteDefaultTag, { data: deletedTag, loading: deleteLoading, error: deleteError }] = useMutation(DeleteBranchTag);

  const [replaceCategoryItems, { data: replacedCategoryItems, loading: replaceLoading, error: replaceError }] = useMutation(ReplaceBranchTagCategoryItems);

  const deleteTag = () => {
    if (!branchTag) {
      return;
    }

    deleteDefaultTag({ variables: { id: branchTag.id } });
  };

  const onSubmit = handleSubmit(data => {
    const selectedOptions = getSelectedBranchTagsOptions(data.branchTagsOptions);
    const visibility = {
      showOnAppointments: selectedOptions.visibility_showOnAppointments,
      showOnBar: selectedOptions.visibility_showOnBar,
      showOnTaggedItem: selectedOptions.visibility_showOnTaggedItem
    };
    console.log(selectedOptions);
    const { name, automations = [], color, capacity, reset_at_midnight, categoryId } = data;

    const multiSelectAutomations = automations.filter(item => AUTOMATIONS_OPTION_DROPDOWN_TYPES[item.type] === OPTION_DROPDOWN_TYPES.MULTI_SELECT);

    const multiSelectAutomationsValues = multiSelectAutomations.map(item => ({ type: item.type, id: (item.value as { name: string; value: string }[]).map(({ value }) => value) }));

    const selectAutomations = automations.filter(item => AUTOMATIONS_OPTION_DROPDOWN_TYPES[item.type] === OPTION_DROPDOWN_TYPES.SELECT) as {
      type: TAGS_AUTOMATIONS_TYPES;
      value: string;
    }[];

    const selectAutomationsValues = selectAutomations.map(item => ({ type: item.type, id: item.value }));

    const otherTypes = automations.filter(item => ![OPTION_DROPDOWN_TYPES.MULTI_SELECT, OPTION_DROPDOWN_TYPES.SELECT].includes(AUTOMATIONS_OPTION_DROPDOWN_TYPES[item.type]));

    const automationsValues = [...multiSelectAutomationsValues, ...selectAutomationsValues, ...otherTypes];

    const variables = {
      name,
      quick_action: selectedOptions.quick_action,
      automations: automationsValues,
      color,
      capacity: Number(capacity) || null,
      reset_at: selectedOptions.reset_at_midnight
        ? {
            hour: 0,
            minute: 0
          }
        : null,
      visibility
    };

    categoryIdRef.current = categoryId?.value || null;

    if (!branchTag) {
      addBranchTag({ variables });
      return;
    }

    if (type === defaultType) {
      editBranchTag({ variables: { id: branchTag.id, ...variables } });
      return;
    }

    deleteTag();
    addBranchTag({ variables });
  });

  const saveError = !!addError?.message || !!editError?.message || !!deleteError?.message || !!replaceError?.message;
  const saveLoading = addLoading || editLoading || deleteLoading || replaceLoading;
  const savedTag =
    addedTag?.branchPetRecordTagAdd ||
    editedTag?.branchPetRecordTagEdit ||
    addedTag?.branchAppointmentTagAdd ||
    editedTag?.branchAppointmentTagEdit ||
    addedTag?.branchAppUserTagAdd ||
    editedTag?.branchAppUserTagEdit;
  const savedCategoryItems = replacedCategoryItems?.replaceBranchTagCategoryItems;
  const deletedTagId = deletedTag?.branchPetRecordTagDelete || deletedTag?.branchAppointmentTagDelete || deletedTag?.branchAppUserTagDelete;

  const refetchTags = () => {
    (async () => {
      await Common.get<() => Promise<void>>(`BranchTags.GetBranchTags.refetch`)?.();
    })();
  };

  const closeModal = () => {
    if (deletedTagId) {
      unsetDrawerBars();
    }

    const drawerBar = vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.BRANCH_TAG_DRAWER);

    if (drawerBar) {
      setDrawerBar({ drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER, recordData: savedTag });
    }

    if (onAddTag) {
      onAddTag(savedTag, type);
      return;
    }

    ModalDialog.closeModal();
  };

  useEffect(() => {
    if (savedTag || (deletedTagId && type === defaultType)) {
      refetchTags();

      const categoryId = categoryIdRef.current || getValues('categoryId')?.value;

      if (!categoryId || (categoryId === branchTag?.BranchTagCategories?.[0]?.id && type === defaultType)) {
        closeModal();
        return;
      }

      const tagId = (() => {
        switch (type) {
          case BRANCH_TAGS_TYPES.PET_RECORD:
            return { BranchPetRecordTagId: [savedTag?.id] };
          case BRANCH_TAGS_TYPES.APPOINTMENT:
            return { BranchAppointmentTagId: [savedTag?.id] };
          case BRANCH_TAGS_TYPES.APP_USER:
            return { BranchAppUserTagId: [savedTag?.id] };
        }
      })();

      replaceCategoryItems({ variables: { BranchTagCategoryId: [categoryId], ...(tagId || {}) } });
    }
  }, [savedTag, deletedTagId]);

  useEffect(() => {
    if (savedCategoryItems) {
      closeModal();
    }
  }, [savedCategoryItems]);

  return (
    <BranchTagsModalForm
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      branchTag={branchTag}
      onSubmit={onSubmit}
      defaultName={branchTag?.name || name}
      deleteTag={deleteTag}
      defaultQuickTag={quickTag}
      defaultType={defaultType}
      defaultCategoryId={defaultCategoryId}
      type={type}
    />
  );
};

export default BranchTagsModal;
