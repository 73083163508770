import React, { FC, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import Modal, { ModalRef } from '../../../components/Modal/Modal';
import { convertDurationToMinutes } from '../../../components/Shared/DurationSelector';
import { ColorPicker, FormError, FormInput, FormLabel, FormTextArea, InputGroup, RemoveRowContainer } from '../../../components/Shared/Forms/Forms';
import useIcons from '../../../hooks/useIcons';
import { getKeys } from '../../../utils/helpers';
import { isValidURL } from '../../../utils/validators';
import BranchTagItemModal from '../BranchTags/BranchTagItemModal';
import { BRANCH_TAGS_TYPES } from '../BranchTags/types';
import VoucherModal from '../Discounts/VoucherModal';
import { FormVoucher } from '../Discounts/types';
import { RemoveAddedImage } from '../styled';
import { CustomizationFormOptionsBodyContainer, CustomizationFormOptionsBodyWrapper, CustomizationFormOptionsContainer, CustomizationFormOptionsWrapper, CustomizationInputContainer } from './styled';
import { CustomizationFormOptionsProps, customizationMetaOptions, CustomizationFormOptionsRef, CustomizationRules, CustomizationRulesTitles, TransformedCustomizationOptionRule } from './types';
import Select from '../../../components/Shared/Forms/Select';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import { FlexRow } from '../../../components/Shared/Shared';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import Colors from '../../../Colors';
import { Divider } from '../../Pets/Health/styled';

const CustomizationFormOptions = forwardRef<CustomizationFormOptionsRef, CustomizationFormOptionsProps>(
  ({ formOptions, handleRemoveRow, index, option, watchedCustomization, customizationIndex, busUsers, hideRules, defaultValue, onExpandOption }, ref) => {
    const icons = useIcons();
    const { control, errors, setValue } = formOptions;
    const { meta: watchedMeta, rules: watchedRules } = watchedCustomization?.options?.[index] || {};

    const optionErrors = errors?.customizations?.[customizationIndex]?.options?.find((_item, optionIndex) => optionIndex === index);

    const optionName = `customizations[${customizationIndex}].options[${index}]`;

    const vouchersModalRef = useRef<ModalRef>(null);
    const tagsModalRef = useRef<ModalRef>(null);

    const onClickVoucher = (itemIndex: number) => {
      const voucher = watchedRules?.[itemIndex]?.value as FormVoucher;
      vouchersModalRef.current?.openModal({ voucher, title: 'Voucher to create' });
    };

    const onClickTags = (itemIndex: number) => {
      const selectedTags = watchedRules?.[itemIndex]?.value as { tags: string[] };

      tagsModalRef.current?.openModal({ selectedTags, title: 'Tags to add' });
    };

    const rulesToShow = getKeys(CustomizationRules).filter(key => !hideRules?.[key]);

    const rulesToShowOptions = rulesToShow.map(rule => ({
      value: CustomizationRules[rule],
      label: CustomizationRulesTitles[rule]
    }));

    const rulesFieldArray = useFieldArray<TransformedCustomizationOptionRule>({
      control,
      name: `${optionName}.rules`
    });

    const handleAddRule = () => {
      rulesFieldArray.append({ type: CustomizationRules.NONE, value: '' });
    };

    const handleRemoveRole = (index: number) => {
      rulesFieldArray.remove(index);
    };

    const [isExpanded, setIsExpanded] = useState(false);

    useImperativeHandle(ref, () => ({
      collapse: () => setIsExpanded(false)
    }));

    const getRulesDescription = (rules: TransformedCustomizationOptionRule[]) => {
      return rules
        ?.map(rule => {
          switch (rule.type) {
            case CustomizationRules.DURATION:
              return `and extends duration by ${rule.value} minutes`;
            case CustomizationRules.CREATE_VOUCHER:
              return 'and creates a voucher';
            case CustomizationRules.TAG_APPOINTMENTS:
              return 'and tags booking';
            case CustomizationRules.ASSIGN:
              return 'and assigns booking to staff';
            default:
              return '';
          }
        })
        .join(', ');
    };

    const optionDescription =
      watchedCustomization?.options?.[index]?.price !== undefined ? (
        <FormLabel wrap marginBottom={0} style={{ padding: '10px 10px 0 10px' }}>
          Adds {getBranchCurrencySymbol()} {watchedCustomization?.options?.[index]?.price} {getRulesDescription(watchedCustomization?.options?.[index]?.rules || [])}
        </FormLabel>
      ) : null;

    return (
      <CustomizationFormOptionsWrapper>
        <CustomizationFormOptionsContainer column>
          <CustomizationFormOptionsContainer
            isTitle
            isExpanded={isExpanded}
            onClick={() => {
              setIsExpanded(isExpanded => !isExpanded);
              if (!isExpanded) {
                onExpandOption?.();
              }
            }}
          >
            <InputGroup>
              <FlexRow gap={10}>
                <FlexRow gap={2} flex1>
                  <Controller
                    as={<FormInput error={!!optionErrors?.sort_index} width={40} flexOne />}
                    control={control}
                    name={`${optionName}.sort_index`}
                    defaultValue={option?.sort_index || ''}
                    rules={{ required: true }}
                  />
                  {!!optionErrors?.sort_index && <FormError>{optionErrors?.sort_index?.message || 'sort_index is required'}</FormError>}
                  <Controller as={<FormInput error={!!optionErrors?.title} />} control={control} name={`${optionName}.title`} defaultValue={option?.title || ''} rules={{ required: true }} />
                  {!!optionErrors?.title && <FormError>{optionErrors?.title?.message || 'Title is required'}</FormError>}
                </FlexRow>
                <RemoveRowContainer noMargin>
                  <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveRow(index)} noMargin />
                </RemoveRowContainer>
              </FlexRow>
              {optionDescription}
            </InputGroup>
          </CustomizationFormOptionsContainer>

          <CustomizationFormOptionsBodyContainer isExpanded={isExpanded}>
            <CustomizationFormOptionsBodyWrapper>
              <CustomizationFormOptionsContainer>
                <InputGroup>
                  <FormLabel>Description</FormLabel>
                  <Controller as={<FormTextArea error={!!optionErrors?.description} />} control={control} name={`${optionName}.description`} defaultValue={option?.description || ''} />
                </InputGroup>
              </CustomizationFormOptionsContainer>
              <CustomizationFormOptionsContainer>
                <InputGroup>
                  <FormLabel>Cost</FormLabel>
                  <Controller as={<FormInput error={!!optionErrors?.price} />} control={control} name={`${optionName}.price`} defaultValue={option?.price || 0} rules={{ required: true }} />
                  {!!optionErrors?.price && <FormError>{optionErrors?.price?.message || 'Price is required'}</FormError>}
                </InputGroup>
              </CustomizationFormOptionsContainer>
              <Divider />
              <FormLabel>Automations</FormLabel>

              {!!rulesToShow.length &&
                rulesFieldArray.fields.map((item, itemIndex) => {
                  const optionItemRuleName = `${optionName}.rules[${itemIndex}]`;
                  return (
                    <CustomizationFormOptionsContainer key={item.id} column gap={10} alternate>
                      <CustomizationInputContainer>
                        <InputGroup direction="row" style={{ gap: 10, alignItems: 'center' }}>
                          <Controller
                            render={({ onChange, value }) => (
                              <Select
                                options={rulesToShowOptions.filter(option => !watchedRules?.find((rule, ruleIndex) => rule.type === option.value && ruleIndex !== itemIndex))}
                                value={rulesToShowOptions.find(option => option.value === value) || rulesToShowOptions[0]}
                                onChange={e => {
                                  setValue(`${optionItemRuleName}.value`, '');
                                  onChange(e?.value);
                                }}
                              />
                            )}
                            control={control}
                            name={`${optionItemRuleName}.type`}
                            defaultValue={option?.rules?.[itemIndex]?.type || 'none'}
                            rules={{ required: true }}
                          />
                          {!!optionErrors?.rules?.[itemIndex]?.type && <FormError>{optionErrors?.rules?.[itemIndex]?.type?.message || 'Rule is required'}</FormError>}
                          {watchedRules?.[itemIndex]?.type && (
                            <>
                              {watchedRules?.[itemIndex]?.type === CustomizationRules.DURATION && (
                                <>
                                  <Controller
                                    as={<FormInput type={'number'} error={!!optionErrors?.rules?.[itemIndex]?.value} />}
                                    control={control}
                                    name={`${optionItemRuleName}.value`}
                                    defaultValue={option?.rules?.[itemIndex]?.value || 0}
                                    rules={{ required: true }}
                                  />
                                  {!!optionErrors?.rules?.[itemIndex]?.value && <FormError>{optionErrors?.rules?.[itemIndex]?.value?.message || 'Value is required'}</FormError>}
                                </>
                              )}

                              {watchedRules?.[itemIndex]?.type === CustomizationRules.CREATE_VOUCHER && (
                                <>
                                  <Modal
                                    title="Add Voucher"
                                    modalContent={props => (
                                      <VoucherModal
                                        onSubmit={voucher => {
                                          setValue(`${optionItemRuleName}.value`, {
                                            auto_apply: !!(String(voucher.auto_apply) === 'true'),
                                            discountId: voucher.discountId,
                                            limit: Number(voucher.limit),
                                            period: {
                                              start: voucher.period?.start,
                                              end: voucher.period?.end,
                                              createdAt_duration: voucher.period?.createdAt_duration ? convertDurationToMinutes(voucher.period?.createdAt_duration) : null
                                            },
                                            user_limit: Number(voucher.user_limit),
                                            productId: voucher.products
                                          });
                                          requestAnimationFrame(() => vouchersModalRef.current?.closeModal());
                                        }}
                                        voucher={{
                                          ...(props?.voucher || defaultValue?.rules?.[itemIndex]?.value || null),
                                          products: props?.voucher?.productId || (defaultValue?.rules?.[itemIndex]?.value as Record<string, any>)?.productId || []
                                        }}
                                        otherVouchers={props?.otherVouchers}
                                        disableAppUserSelect
                                        disableCodeGeneration
                                        disablePeriodSelect
                                      />
                                    )}
                                    ref={vouchersModalRef}
                                    noButton
                                  />
                                  <FormLabel clickable color={Colors.primary} onClick={() => onClickVoucher(itemIndex)} marginBottom={0}>
                                    voucher
                                  </FormLabel>
                                  <Controller
                                    render={({ onChange, value }) => <FormInput type={'hidden'} error={!!optionErrors?.rules?.[itemIndex]?.value} flexOne onChange={onChange} value={value || ''} />}
                                    control={control}
                                    name={`${optionItemRuleName}.value`}
                                    defaultValue={option?.rules?.[itemIndex]?.value || ''}
                                    rules={{ validate: value => value || 'Voucher is required' }}
                                  />

                                  {!!optionErrors?.rules?.[itemIndex]?.value && <FormError>{optionErrors?.rules?.[itemIndex]?.value?.message || 'Value is required'}</FormError>}
                                </>
                              )}
                              {watchedRules?.[itemIndex]?.type === CustomizationRules.TAG_APPOINTMENTS && (
                                <>
                                  <Modal
                                    title="Tag Appointments"
                                    modalContent={props => (
                                      <BranchTagItemModal
                                        existingTagsItems={(props?.selectedTags?.tags || (defaultValue?.rules?.[itemIndex]?.value as any)?.tags || []).map((tag: string) => ({ id: tag })) || []}
                                        onSubmit={({ selectedTags }) => {
                                          setValue(`${optionItemRuleName}.value`, {
                                            tags: selectedTags?.map(({ value }) => value) || []
                                          });
                                          requestAnimationFrame(() => tagsModalRef.current?.closeModal());
                                        }}
                                        type={BRANCH_TAGS_TYPES.APPOINTMENT}
                                        loading={false}
                                        isCreatable={false}
                                      />
                                    )}
                                    ref={tagsModalRef}
                                    noButton
                                  />
                                  <FormLabel clickable color={Colors.primary} onClick={() => onClickTags(itemIndex)} marginBottom={0}>
                                    Edit tags
                                  </FormLabel>
                                  <Controller
                                    render={({ onChange, value }) => <FormInput type={'hidden'} error={!!optionErrors?.rules?.[itemIndex]?.value} flexOne onChange={onChange} value={value || ''} />}
                                    control={control}
                                    name={`${optionItemRuleName}.value`}
                                    defaultValue={option?.rules?.[itemIndex]?.value || ''}
                                    rules={{ required: true }}
                                  />
                                  {!!optionErrors?.rules?.[itemIndex]?.value && <FormError>{optionErrors?.rules?.[itemIndex]?.value?.message || 'Value is required'}</FormError>}
                                </>
                              )}
                            </>
                          )}
                        </InputGroup>{' '}
                        <RemoveRowContainer noMargin style={{ marginLeft: 'auto' }}>
                          <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveRole(itemIndex)} noMargin />
                        </RemoveRowContainer>
                      </CustomizationInputContainer>
                      {watchedRules?.[itemIndex]?.type === CustomizationRules.ASSIGN && (
                        <InputGroup width={372}>
                          <Controller
                            render={({ onChange, value }) => (
                              <Select
                                onChange={newValue => {
                                  onChange([...(newValue || [])]?.map(busUser => busUser?.id));
                                }}
                                isMulti
                                value={[...(value || [])]?.map(id => busUsers.filter(busUser => busUser.id === id)?.[0])}
                                options={busUsers}
                                getOptionValue={busUser => busUser?.id || ''}
                                getOptionLabel={busUser => busUser?.name || ''}
                                name={`${optionItemRuleName}.value`}
                              />
                            )}
                            control={control}
                            name={`${optionItemRuleName}.value`}
                            defaultValue={option?.rules?.[itemIndex]?.value || []}
                            rules={{ required: true, validate: value => value?.length || 'Value is required' }}
                          />

                          {!!optionErrors?.rules?.[itemIndex]?.value && <FormError>{optionErrors?.rules?.[itemIndex]?.value?.message || 'Value is required'}</FormError>}
                        </InputGroup>
                      )}
                    </CustomizationFormOptionsContainer>
                  );
                })}
              <AddNewBtn onClick={handleAddRule} noSpaceArround>
                <AddNewBtnIcon src={icons.plusBlack.childImageSharp.gatsbyImageData.images.fallback.src} />
                Add an automation
              </AddNewBtn>
              <Divider />
              <CustomizationFormOptionsContainer>
                <InputGroup>
                  <FormLabel>Visual Preview</FormLabel>
                  <CustomizationInputContainer>
                    <Controller
                      render={({ onChange, value }) => (
                        <Select
                          options={customizationMetaOptions}
                          value={customizationMetaOptions.find(option => option.value === value) || customizationMetaOptions[0]}
                          onChange={e => {
                            setValue(`${optionName}.meta.value`, '');
                            onChange(e?.value);
                          }}
                        />
                      )}
                      control={control}
                      name={`${optionName}.meta.type`}
                      defaultValue={option?.meta?.type || 'none'}
                      rules={{ required: true }}
                    />
                    {!!optionErrors?.meta?.type && <FormError>{optionErrors?.meta?.type?.message || 'Preview is required'}</FormError>}
                    {watchedMeta?.type && watchedMeta?.type !== 'none' && (
                      <>
                        <Controller
                          render={({ onChange, value }) => (
                            <>
                              {watchedMeta.type === 'url' && <FormInput type={'text'} error={!!optionErrors?.meta?.value} flexOne onChange={onChange} value={value || ''} />}
                              {watchedMeta.type === 'hex' && <ColorPicker type={'color'} error={optionErrors?.meta?.value?.message} width={120} autoHeight onChange={onChange} value={value || ''} />}
                            </>
                          )}
                          control={control}
                          name={`${optionName}.meta.value`}
                          defaultValue={option?.meta?.value || ''}
                          rules={
                            watchedMeta.type === 'url'
                              ? {
                                  required: true,
                                  pattern: {
                                    value: isValidURL,
                                    message: 'Invalid URL'
                                  }
                                }
                              : { required: watchedMeta.type !== 'none' }
                          }
                        />
                        {optionErrors?.meta?.value && <FormError>{optionErrors?.meta?.value?.message || 'Preview is required'}</FormError>}
                      </>
                    )}
                  </CustomizationInputContainer>
                </InputGroup>
              </CustomizationFormOptionsContainer>
            </CustomizationFormOptionsBodyWrapper>
          </CustomizationFormOptionsBodyContainer>
        </CustomizationFormOptionsContainer>
      </CustomizationFormOptionsWrapper>
    );
  }
);

export default CustomizationFormOptions;
