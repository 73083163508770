import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import OrderInvoicesList from '../../../components/OrderInvoices/OrderInvoicesList';
import { OrderInvoice } from '../../../components/OrderInvoices/types';
import useIcons from '../../../hooks/useIcons';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchOrderInvoices } from '../../../queries';
import { ButtonsContainer } from '../../Bookings/components/BookingsDrawer/styled';
import { BranchSummaryCountMode } from '../BranchSummary/styled';
import OrderInvoicesDownloadModal from './OrderInvoicesDownloadModal';
import { CurrentPeriod } from './types';

const BranchBillingInvoices = ({ period, variables }: { period: CurrentPeriod; variables: Record<string, any> }) => {
  const paginatedQueryResult = usePaginatedQuery<OrderInvoice[]>({
    query: GetBranchOrderInvoices,
    limit: 50,
    otherVariables: {
      invoice_date_from: period.start,
      invoice_date_to: period.end,
      ...variables
    }
  });

  const icons = useIcons();
  const exportIcon = icons?.export?.childImageSharp.gatsbyImageData;

  return (
    <OrderInvoicesList
      paginatedQueryResult={paginatedQueryResult}
      ListHeader={
        <ButtonsContainer style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <BranchSummaryCountMode
            style={{ display: 'flex', gap: 10, paddingBottom: 10 }}
            onClick={() =>
              ModalDialog.openModal({
                content: () => (
                  <OrderInvoicesDownloadModal
                    initialValues={{
                      invoice_date_from: period.start,
                      invoice_date_to: period.end,
                      ...variables
                    }}
                  />
                ),
                title: 'Export Invoices'
              })
            }
          >
            <span>Export</span>
            <GatsbyImage image={exportIcon} alt="export" />
          </BranchSummaryCountMode>
        </ButtonsContainer>
      }
    />
  );
};

export default BranchBillingInvoices;
