import React, { useState } from 'react';
import { RecordsContainer } from '../../styled';
import BranchAppUsers from './BranchBillingAppUsers';
import BranchBillingBookings from './BranchBillingBookings';
import { BillingStatsContainer, FilterItem, FilterItemCount, FilterItemText, SelectedBillingFiltersContainer, SelectedBillingFiltersWrapper, VerticalDivider } from './styled';
import BranchBillingSubscriptions from './BranchBillingSubscriptions';
import BranchBillingOrders from './BranchBillingOrders';
import { useQuery } from '@apollo/client';
import { GetBranchCounts } from '../../../queries';
import { Branch } from '../../../components/Profile/types';
import { toDateInput } from '../../../utils/dates';
import BranchBillingInvoices from './BranchBillingInvoices';
import { BRANCH_BILLING_FILTER_COMPONENT, BRANCH_BILLING_FILTERS, BRANCH_BILLING_FILTERS_ITEMS, defaultCounts } from './types';
import BranchBillingStatsFilterItems from './BranchBillingStatsFilterItems';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { getCurrentBillingFilters } from './utils';
import BranchBillingSubscriptionRepeats from './BranchBillingSubscriptionRepeats';
import useURLSearchParams from '../../../hooks/useURLSearchParams';

const BranchBillingBranchStats = ({ currentPeriod }: { currentPeriod: { start: string; end: string } }) => {
  const { currentBookingFilter, currentInvoiceFilter, currentSubscriptionFilter, currentUnpaidFilter } = getCurrentBillingFilters(currentPeriod);

  const { data: { getBranchCounts: branchCounts = defaultCounts } = {}, loading } = useQuery<{
    getBranchCounts: Branch['branchCounts'];
  }>(GetBranchCounts, {
    fetchPolicy: 'cache-and-network',
    variables: {
      timestamp_from: currentPeriod.start,
      timestamp_to: currentPeriod.end
    }
  });

  const bookingSectionsTitles = {
    filters: currentBookingFilter,
    period: 'Period',
    date: `${toDateInput(currentPeriod.start)} - ${toDateInput(currentPeriod.end)}`
  };

  const invoiceSectionsTitles = {
    filters: currentInvoiceFilter,
    period: 'Issue Date',
    date: `${toDateInput(currentPeriod.end)}`
  };

  const subscriptionSectionsTitles = {
    filters: currentSubscriptionFilter,
    period: 'Period',
    date: `${toDateInput(currentPeriod.start)} - ${toDateInput(currentPeriod.end)}`
  };

  const unpaidSectionsTitles = {
    filters: currentUnpaidFilter,
    period: 'All Unpaid',
    date: ''
  };

  // const orderSectionsTitles = {
  //   filters: currentOrderFilter,
  //   period: 'Period',
  //   date: `${toDateInput(currentPeriod.start)} - ${toDateInput(currentPeriod.end)}`
  // };

  const [selectedMainFilter] = useURLSearchParams<BRANCH_BILLING_FILTERS>('mainFilter');
  const selectedFilterState = useURLSearchParams<BRANCH_BILLING_FILTERS>('filter');
  const [selectedFilter] = selectedFilterState;
  const invoiceFilter = selectedMainFilter === BRANCH_BILLING_FILTERS.MAIN_INVOICES;
  const subscriptionsFilter = selectedMainFilter === BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS;
  const bookingsFilter = selectedMainFilter === BRANCH_BILLING_FILTERS.MAIN_BOOKINGS;

  const billingSections = invoiceFilter ? invoiceSectionsTitles : subscriptionsFilter ? subscriptionSectionsTitles : bookingsFilter ? bookingSectionsTitles : unpaidSectionsTitles;

  const filters = billingSections.filters;

  const variables = BRANCH_BILLING_FILTERS_ITEMS(currentPeriod)[selectedFilter];

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <BillingStatsContainer noMargin>
        <SelectedBillingFiltersContainer>
          <SelectedBillingFiltersWrapper secondary>
            {false && (
              <>
                <FilterItem>
                  <FilterItemText>{billingSections.period}</FilterItemText>
                  <FilterItemCount>{billingSections.date}</FilterItemCount>
                </FilterItem>
                <VerticalDivider />
              </>
            )}
            {branchCounts && <BranchBillingStatsFilterItems counts={branchCounts} filters={filters} selectedFilterState={selectedFilterState} loading={loading} secondary />}
          </SelectedBillingFiltersWrapper>
        </SelectedBillingFiltersContainer>
      </BillingStatsContainer>
      <>
        {loading && <CenteredLoader />}
        {!loading && (
          <>
            {BRANCH_BILLING_FILTER_COMPONENT[selectedFilter] === 'BranchBillingAppUsers' && <BranchAppUsers period={currentPeriod} variables={variables} />}

            {BRANCH_BILLING_FILTER_COMPONENT[selectedFilter] === 'BranchBillingBookings' && <BranchBillingBookings period={currentPeriod} variables={variables} />}

            {BRANCH_BILLING_FILTER_COMPONENT[selectedFilter] === 'BranchBillingSubscriptions' && <BranchBillingSubscriptions period={currentPeriod} variables={variables} />}

            {BRANCH_BILLING_FILTER_COMPONENT[selectedFilter] === 'BranchBillingOrders' && <BranchBillingOrders period={currentPeriod} variables={variables} />}

            {BRANCH_BILLING_FILTER_COMPONENT[selectedFilter] === 'BranchBillingInvoices' && <BranchBillingInvoices period={currentPeriod} variables={variables} />}

            {BRANCH_BILLING_FILTER_COMPONENT[selectedFilter] === 'BranchBillingSubscriptionRepeats' && <BranchBillingSubscriptionRepeats period={currentPeriod} variables={variables} />}
          </>
        )}
      </>
    </div>
  );
};

export default BranchBillingBranchStats;
