import React, { useEffect } from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchForms } from '../../../queries';
import Common from '../Common';
import BranchFormRecord from './BranchFormRecord';
import { BranchForm } from './types';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import ModalDialog from '../../../components/Modal/ModalDialog';
import BranchFormModal from './BranchFormModal';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';

const BranchForms = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchForm[]>({ query: GetBranchForms });

  const { data: { formGet: branchForms = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchForms.GetBranchForms.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <>
      <ToolBar
        displayString={'Forms'}
        customizedToolComponent={
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
            noApplyButton
            options={[
              {
                id: 'add-form',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'Add Form',
                    value: 'add-form',
                    onClick: () =>
                      ModalDialog.openModal({
                        title: 'Form',
                        content: () => <BranchFormModal />
                      })
                  }
                ]
              }
            ]}
          />
        }
      />
      <InfiniteList
        paddingL={20}
        paddingR={20}
        list={branchForms}
        itemRenderer={(form: BranchForm) => <BranchFormRecord key={form.id} branchForm={form} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        offset={branchForms?.length}
        setHasMoreItems={setHasMoreItems}
      />
    </>
  );
};

export default BranchForms;
