import { useLocation } from '@reach/router';
import React from 'react';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import SideTab from '../../components/Shared/SideTabs/SideTabs';
import ToolsBar from '../../components/Shared/ToolBar/ToolBar';
import SubscriptionsBody from './SubscriptionsBody';
import { SUBSCRIPTION_COLLECTION_STATUS_TYPES, SUBSCRIPTION_STATUS_TYPES, SUBSCRIPTION_TABS_NAMES, SUBSCRIPTION_TABS_TYPES } from './types';
import SubscriptionsHeaderDashboard from './SubscriptionsHeaderDashboard';

const Subscriptions = () => {
  const location = useLocation();
  const tabs: { name: string; status: string[]; otherVariables: Record<string, any>; value: string }[] = [
    {
      name: SUBSCRIPTION_TABS_NAMES.ACTIVE,
      status: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED],
      otherVariables: {
        collectionStatus: [SUBSCRIPTION_COLLECTION_STATUS_TYPES.ACTIVE],
        order_by_end_date: false,
        order_by_name: false
      },
      value: SUBSCRIPTION_TABS_TYPES.ACTIVE
    },
    {
      name: SUBSCRIPTION_TABS_NAMES.PAUSED,
      status: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED],
      otherVariables: {
        collectionStatus: [SUBSCRIPTION_COLLECTION_STATUS_TYPES.PAUSED],
        order_by_end_date: false,
        order_by_name: false
      },
      value: SUBSCRIPTION_TABS_TYPES.PAUSED
    },
    {
      name: SUBSCRIPTION_TABS_NAMES.CANCELED,
      status: [SUBSCRIPTION_STATUS_TYPES.CANCELED],
      otherVariables: {
        collectionStatus: null,
        order_by_end_date: false,
        order_by_name: false
      },
      value: SUBSCRIPTION_TABS_TYPES.CANCELED
    }
  ];

  const sections = [{ tabs }];
  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) || 'active';

  return (
    <ViewWrapper>
      <SideTab sections={sections} />
      <MainContainer>
        <SubscriptionsBody tabs={tabs} selectedTabsState={tabValue} />
      </MainContainer>
    </ViewWrapper>
  );
};

export default Subscriptions;
