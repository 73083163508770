import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import { CalendarProps } from 'react-big-calendar';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { AssignBranchRoomToAppointment, AssignBusUserToAppointment, EditBranchSlot, GetBranchCalendarAppointmentsViews, GetBranchSchedulesSlots, GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { hideCalendarActionMessage, setDrawerBar } from '../../reactive/actions';
import BookingDurationModal from '../../views/Bookings/components/BookingsDrawer/BookingDurationModal';
import BookingRescheduleModal from '../../views/Bookings/components/BookingsDrawer/BookingRescheduleModal';
import { BlockedCalendarEvent, BOOKING_STATUS_TYPES, BookingOrder, CalendarEvent, CalendarEvents } from '../../views/Bookings/types';
import { DRAWER_IDS } from '../DrawerBar/types';
import ModalDialog from '../Modal/ModalDialog';
import { BusUserProfile } from '../Profile/types';
import SlotModal, { SLOTS_TABS_TYPES } from './Modals/SlotModal';
import { CALENDAR_VIEWS, CALENDAR_VIEW_BOOKING_TYPE } from './types';
import { convertDurationToMinutes } from '../Shared/DurationSelector';
import { SlotsCalendar } from './Calendars/SlotsCalendar';
import { MultiDayCalendar } from './Calendars/MultiDayCalendar';
import { BranchRoom } from '../../views/Store/BranchRooms/types';
import { BranchSchedule } from '../../views/Store/BranchSchedules/types';
import { BranchAvailability } from '../../views/Store/types';
import { getStartOfDate, getStartOfToday } from '../../utils/dates';

export type TheCalendarEvent = {
  isBlocked?: boolean;
  view: Partial<
    CalendarEvent &
      BlockedCalendarEvent & {
        color: string;
        BranchRooms: BranchRoom[];
        time: string;
        branch_schedules: BranchSchedule[];
        timestamp_until: string;
        BusUsers: Partial<BusUserProfile>[];
      }
  >;
  start: Date;
  end: Date;
  variables?: Record<string, unknown>;
  resourceId: string;
};

export type TheCalendarResource = { resourceId: string; resourceTitle: string };

function extractRange(range: Date[] | { start: Date; end: Date }) {
  let start = range.start;
  let end = range.end;

  if (range.length === 1) {
    // day
    start = range[0];
    end = endOfDay(range[0]);
  } else if (range.length > 1) {
    // week

    start = range[0];
    end = range.slice(-1)[0];
  }

  return { start, end };
}

const TheCalendar: FC<{
  selectedUser: string;
  setSelectedUser: (value: string) => void;
}> = ({ selectedUser, setSelectedUser }) => {
  const modalTitle = useReactiveVar(vars.modalTitle);
  const [dateRange, setDateRange] = useState({ from: new Date(), to: new Date() });

  const [addStaff] = useMutation<{ assignBusUserToAppointment: BookingOrder }>(AssignBusUserToAppointment);
  const [addBranchRoom] = useMutation<{ assignBranchRoomToAppointment: BookingOrder }>(AssignBranchRoomToAppointment);

  const calendarTagCategoryId = useReactiveVar(vars.calendarTagCategoryId);

  const { data: { getBusUserProfile: busUserProfile } = {}, refetch: refetchProfile } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile);

  const [selectedCategoryTab, setSelectedCategoryTab] = useState<string>('');
  const selectedBookingType = useReactiveVar(vars.calendarSelectedBookingType) || CALENDAR_VIEWS.MY_SCHEDULE;
  const multiStaffBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;
  const myScheduleBookingType = selectedBookingType === CALENDAR_VIEWS.MY_SCHEDULE;
  const isMultiDay = selectedBookingType === CALENDAR_VIEWS.MULTI_DAY;
  const schedulesToReturn = !selectedCategoryTab || isMultiDay ? null : [selectedCategoryTab];
  const fromDateForWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const toDateForWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
  const fromDateForMonth = startOfMonth(new Date());
  const toDateForMonth = endOfMonth(new Date());

  const fromDate = isMultiDay ? fromDateForMonth : fromDateForWeek;
  const toDate = isMultiDay ? toDateForMonth : toDateForWeek;

  const profile = (busUserProfile || {}) as BusUserProfile;

  const [handleEditBranchSlot] = useMutation(EditBranchSlot);

  const [getCalendarAppointments, { data: calendarAppointmentsData, refetch: refetchCalendarAppointments, loading: loadingCalendarAppointments }] = useLazyQuery<{
    getBranchCalendarAppointmentsViews: CalendarEvents;
  }>(GetBranchCalendarAppointmentsViews, {
    variables: {
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      timestamp_from: new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes(), fromDate.getSeconds())).toISOString(),
      timestamp_to: new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes(), toDate.getSeconds())).toISOString(),
      filter_by_role: profile.role,
      booking_type: CALENDAR_VIEW_BOOKING_TYPE[selectedBookingType],
      BranchTagCategoryId: calendarTagCategoryId ? [calendarTagCategoryId] : null,
      ...(multiStaffBookingType && {
        appointment_busUserAssigned: true
      }),
      ...(myScheduleBookingType && {
        appointment_busUserAssigned_id: profile?.id
      }),
      product_branchSchedule_id: schedulesToReturn
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const dates = data?.getBranchCalendarAppointmentsViews?.singleDayAppointmentsViews?.map(item => item.date);

      const count = dates.map(date => {
        const checkInCheckOut = data?.getBranchCalendarAppointmentsViews?.multiDayAppointmentsViews?.reduce(
          (accumulator, appointment) => {
            if (appointment.timestamp && new Date(appointment.timestamp).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) {
              accumulator.checkIn++;
            }
            if (appointment.timestamp_until && new Date(appointment.timestamp_until).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) {
              accumulator.checkOut++;
            }
            return accumulator;
          },
          { checkIn: 0, checkOut: 0 }
        );

        return checkInCheckOut;
      });
      vars.calendarDatesCheckInCheckOutCount(count);
    },
    pollInterval: 60000
  });

  useEffect(() => {
    if (multiStaffBookingType) {
      // getBusUserAssignedCalendarAppointments();
    } else {
      getCalendarAppointments();
    }
  }, [multiStaffBookingType]);

  const adjustCalendarDomView = () => {
    setTimeout(() => {
      const allDayHeaders = document.querySelectorAll('.rbc-allday-cell');

      if (allDayHeaders) {
        allDayHeaders.forEach(h => (h.style.display = 'none'));
      }
    }, 100);
  };

  const events = useMemo(() => {
    const list = isMultiDay
      ? calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.multiDayAppointmentsViews
      : calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.singleDayAppointmentsViews?.map(e => e.views)?.flat();
    return (list || [])?.map(view => {
      const dt = new Date(view.timestamp);
      const dtUtc = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds());
      const dtUntil = new Date(view.timestamp_until);
      const dtUntilUtc = new Date(dtUntil.getUTCFullYear(), dtUntil.getUTCMonth(), dtUntil.getUTCDate(), dtUntil.getUTCHours(), dtUntil.getUTCMinutes(), dtUntil.getUTCSeconds());
      const nightsNumber = Math.floor(Number(view.duration));
      const nightsToReturn = nightsNumber > 1 ? ` - ${nightsNumber} nights` : '- night';
      const color = view.color;
      return {
        view,
        title: view.petsNames + ' - ' + view.itemName + nightsToReturn,
        start: dtUtc,
        end: new Date(view.timestamp_until ? dtUntilUtc : addMinutes(dtUtc, Number(view.duration))),
        allDay: false,
        resourceId: isMultiDay ? view.BranchRooms?.[0]?.id : view.BusUsers?.[0]?.id,
        color
      };
    });
  }, [calendarAppointmentsData]);

  const blockedEvents = useMemo(() => {
    return (calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.blockedSlotsViews || [])
      ?.map(e => e.views)
      .flat()
      ?.map(view => {
        const count = view.count;
        const time = view.time;
        const duration = view.duration;
        const branch_schedules = view.branch_schedules;
        const dt = new Date(view.timestamp);
        const dtUtc = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds());
        const dtUntil = new Date(view.timestamp_until);
        const dtUntilUtc = new Date(dtUntil.getUTCFullYear(), dtUntil.getUTCMonth(), dtUntil.getUTCDate(), dtUntil.getUTCHours(), dtUntil.getUTCMinutes(), dtUntil.getUTCSeconds());
        const variables = {
          timeStamp: dtUtc,
          count: Number(count),
          duration: Number(duration !== undefined ? convertDurationToMinutes(duration) || 0 : 0),
          description: view.description,
          branch_schedules,
          status: 'BLOCKED'
        };

        return {
          view,
          title: view.description,
          start: dtUtc,
          end: new Date(view.timestamp_until ? dtUntilUtc : addMinutes(dtUtc, Number(view.duration))),
          allDay: false,
          resourceId: view.BusUsers?.[0]?.id,
          isBlocked: true,
          time,
          variables
        };
      });
  }, [calendarAppointmentsData]);

  const onEventDrop: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventDrop'] = e => {
    const isBlocked = e.event.isBlocked;
    const { start, end } = extractRange({ start: e.start, end: e.end });
    const appointmentsIds = e.event.view.appointmentsIds;
    const branchSlotsIds = e.event.view.branchSlotsIds;
    const blockedNewDate = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes()));
    const ecStart = new Date(e.event.view.timestamp);

    if (isBlocked) {
      handleEditBranchSlot({
        variables: {
          ...e.event.variables,
          id: branchSlotsIds[0],
          timestamp: blockedNewDate
        }
      });
      e.event.start = start;
      e.event.end = end;
    }

    const reschedule = () => {
      if (start.getTime() === e?.event?.start?.getTime() && !isMultiDay) {
        return;
      }

      const startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours() - 12, start.getMinutes(), start.getSeconds(), start.getMilliseconds()));
      if (isMultiDay && startUTC.getDay() === ecStart.getDay()) {
        return;
      }

      const group = appointmentsIds.length > 1 ? true : false;
      // if (!isMultiDay) {
      ModalDialog.openModal({
        content: () => <BookingRescheduleModal appointmentsIds={appointmentsIds} newDate={start} newDateUntil={isMultiDay && end} group={group} />,
        title: isMultiDay ? 'Amend Booking' : 'Reschedule',
        onClose: refetchCalendarAppointments
      });
      // }
    };

    if (e.resourceId !== e.event.resourceId && multiStaffBookingType) {
      addStaff({
        variables: {
          id: appointmentsIds,
          BusUserId: [e.resourceId]
        },
        refetchQueries: ['getBranchCalendarAppointmentsViews']
      });
    }
    if (e.resourceId !== e.event.resourceId && isMultiDay) {
      addBranchRoom({
        variables: {
          id: appointmentsIds,
          BranchRoomId: [e.resourceId]
        },
        refetchQueries: ['getBranchCalendarAppointmentsViews']
      });
    }
    reschedule();
  };

  const onEventResize: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventResize'] = data => {
    const isBlocked = data.event.isBlocked;
    const { start, end } = extractRange({ start: data.start, end: data.end });
    const newDuration = differenceInMinutes(end, start);
    const newDate = data.event.start !== start ? start : null;
    const appointmentsIds = data.event.view.appointmentsIds;
    const branchSlotsIds = data.event.view.branchSlotsIds;

    if (isBlocked) {
      handleEditBranchSlot({
        variables: {
          ...data.event.variables,
          id: branchSlotsIds[0],
          duration: newDuration
        }
      });
      data.event.start = start;
      data.event.end = end;
    }

    if (isMultiDay) {
      return ModalDialog.openModal({
        content: () => <BookingRescheduleModal appointmentsIds={appointmentsIds} newDate={start} newDateUntil={end} />,
        title: 'Amend Booking',
        onClose: refetchCalendarAppointments
      });
    }
    if (!isBlocked) {
      ModalDialog.openModal({
        content: () => <BookingDurationModal appointmentsIds={appointmentsIds} newDuration={newDuration} newDate={newDate} />,
        title: 'Update Duration',
        onClose: refetchCalendarAppointments
      });
    }
  };

  const onRangeChange: CalendarProps<TheCalendarEvent, TheCalendarResource>['onRangeChange'] = range => {
    adjustCalendarDomView();
    const { start, end } = extractRange(range);
    setDateRange({ from: start, to: end });
    refetchCalendarAppointments({
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      timestamp_from: new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds(), start.getMilliseconds())),
      timestamp_to: new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), end.getHours(), end.getMinutes(), end.getSeconds(), end.getMilliseconds())),
      filter_by_role: profile.role,
      booking_type: CALENDAR_VIEW_BOOKING_TYPE[selectedBookingType],
      BranchTagCategoryId: calendarTagCategoryId ? [calendarTagCategoryId] : null,
      ...(multiStaffBookingType && {
        appointment_busUserAssigned: true
      }),
      ...(myScheduleBookingType && {
        appointment_busUserAssigned_id: profile?.id
      })
    });
  };

  const onSelectEvent: CalendarProps<TheCalendarEvent, TheCalendarResource>['onSelectEvent'] = event => {
    const drawerId = event.view.isGroup ? DRAWER_IDS.GROUP_BOOKINGS_DRAWER : DRAWER_IDS.BOOKING_DRAWER;
    if (event.isBlocked) {
      return ModalDialog.openModal({
        title: modalTitle,
        content: () => <SlotModal tab={SLOTS_TABS_TYPES.BLOCK} branchSlotsIds={event.view.branchSlotsIds} />,
        onClose: refetchCalendarAppointments
      });
    }
    const recordData = event.view.appointmentsIds.map(id => ({ id }));
    setDrawerBar({ drawerId, recordData, otherData: { refetch: refetchCalendarAppointments } });
  };

  const onSelectSlot: CalendarProps['onSelectSlot'] = slotInfo => {
    const slotStart = slotInfo.slots[0];
    const selectedId = slotInfo.resourceId;
    vars.selectedDate({ date: slotStart, hour: slotStart.getHours() });
    ModalDialog.openModal({
      title: modalTitle,
      content: () => <SlotModal tab={SLOTS_TABS_TYPES.BOOK} selectedId={selectedId} />,
      onCloseBySave: () => {
        hideCalendarActionMessage();
        setTimeout(() => {
          refetchProfile();
          refetchCalendarAppointments();
          vars.selectedDate(null);
          // refetchAvailability();
        }, 3000);
      }
    });
  };

  if (isMultiDay) {
    return (
      <MultiDayCalendar
        events={events}
        blockedEvents={blockedEvents}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onRangeChange={onRangeChange}
        fromDateForMonth={fromDateForMonth}
        loadingCalendarAppointments={loadingCalendarAppointments}
      />
    );
  }

  return (
    <SlotsCalendar
      dateRange={dateRange}
      setDateRange={setDateRange}
      events={events}
      blockedEvents={blockedEvents}
      onEventDrop={onEventDrop}
      onEventResize={onEventResize}
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      onRangeChange={onRangeChange}
      multiStaffBookingType={multiStaffBookingType}
      adjustCalendarDomView={adjustCalendarDomView}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      setSelectedCategoryTab={setSelectedCategoryTab}
      selectedCategoryTab={selectedCategoryTab}
      fromDateForWeek={fromDateForWeek}
      toDateForWeek={toDateForWeek}
    />
  );
};

export default TheCalendar;
