import { useQuery } from '@apollo/client';
import React from 'react';
import { sentenceCase } from 'sentence-case';
import { CounterBtn } from '../../../components/Calendar/styled';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Pet } from '../../../components/Pets/types';
import useIcons from '../../../hooks/useIcons';
import { GetBranchPetProfile } from '../../../queries';
import { vars } from '../../../reactive';
import { FULL_DAYS, FULL_WEEK_DAYS_MAP } from '../../../utils/dates';
import { getKeys } from '../../../utils/helpers';
import HealthBranchPetRecordItemModal from './HealthBranchPetRecodItem';
import HealthEditPetRecordFeedingRoutineModal from './HealthEditPetRecordFeedingRoutineModal';
import HealthEditPetRecordMealsModal from './HealthEditPetRecordMealsModal';
import HealthPetRecordModal from './HealthPetRecordModal';
import HealthPetRecords from './HealthPetRecords';
import HealthPetRecordStateModal from './HealthPetRecordStateModal';
import HealthWeightModal from './HealthWeightModal';
import {
  Container,
  HealthMeasurementTitle,
  PetRecordEntitiesContainer,
  PetRecordEntity,
  PetRecordEntityLabel,
  PetRecordEntitySubLabel,
  PetRecordRecordsOptions,
  SectionContainer,
  SectionWrapper,
  SectionsContainer
} from './styled';
import HealthEditPetRecordTreatmentRoutineModal from './HealthEditPetRecordTreatmentRoutineModal';
import HealthAllergiesConditionsModal from './HealthAllergiesConditionsModal';

export const SHAPES = ['SEVERELY_UNDERWEIGHT', 'TOO_THIN', 'SKINNY', 'BELOW_IDEAL', 'JUST_RIGHT', 'ABOVE_IDEAL', 'OVERWEIGHT', 'OBESE', 'SEVERELY_OBESE'] as const;
export type Shape = (typeof SHAPES)[number];

const Health = () => {
  const petId = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER)?.recordData;
  const { data: { getBranchPetProfile: pet = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: { id: petId },
    fetchPolicy: 'cache-and-network'
  });
  const shape: Shape = pet?.PetRecord?.shape;
  const currentWeight: string = pet?.PetRecord?.measurements?.weights?.slice(-1)?.[0]?.weight?.toFixed(1);
  const currentHeight: string = pet?.PetRecord?.measurements?.heights?.slice(-1)?.[0]?.height?.toFixed(1);
  const currentNeck: string = pet?.PetRecord?.measurements?.necks?.slice(-1)?.[0]?.neck?.toFixed(1);
  const currentBack: string = pet?.PetRecord?.measurements?.backs?.slice(-1)?.[0]?.back?.toFixed(1);
  const currentChest: string = pet?.PetRecord?.measurements?.chests?.slice(-1)?.[0]?.chest?.toFixed(1);

  const conditions = getKeys(pet?.PetRecord?.chronic_conditions || {})
    .filter(cond => pet?.PetRecord?.chronic_conditions[cond])
    .map(cond => sentenceCase(cond || ''))
    .join(', ');
  const allergies = getKeys(pet?.PetRecord?.allergies || {})
    ?.filter(allergy => pet?.PetRecord?.allergies[allergy])
    .map(allergy => sentenceCase(allergy || ''))
    .join(', ');
  const disabilities = getKeys(pet?.PetRecord?.disabilities || {})
    ?.filter(disability => pet?.PetRecord?.disabilities[disability])
    .map(disability => sentenceCase(disability || ''))
    .join(', ');
  const daycare = pet?.PetRecord?.BranchPetRecordItems?.find(branchPetRecordItem => branchPetRecordItem.type === 'DAYCARE');
  const petBranch = pet?.PetRecord?.Branch;
  const branchAddress = petBranch?.address;

  const allergiesAndConditionsDataSections = [
    [
      { label: pet?.gender === 'FEMALE' ? 'Spayed' : 'Neutered', subLabel: pet?.PetRecord?.neutred ? 'YES' : 'NO', unit: '' },
      { label: 'Chronic Conditions', subLabel: conditions, unit: '' },
      { label: 'Allergies', subLabel: allergies, unit: '' }
    ],
    [{ label: 'Disabilities', subLabel: disabilities, unit: '' }]
  ];

  const statsDataSections = [
    [
      { label: 'Height', subLabel: currentHeight, unit: 'cm' },
      { label: 'Neck', subLabel: currentNeck, unit: 'cm' },
      { label: 'Back', subLabel: currentBack, unit: 'cm' }
    ],
    [{ label: 'Chest', subLabel: currentChest, unit: 'cm' }]
  ];

  const vetDaycareDetailsSections = [
    [
      { label: 'Vet', subLabel: petBranch?.name, unit: '' },
      { label: 'Address', subLabelArray: [branchAddress?.street, branchAddress?.city, branchAddress?.country, branchAddress?.postcode], unit: '' }
    ],
    [
      { label: 'Daycare', subLabel: daycare?.Branch?.name, unit: '' },
      {
        label: 'Days',
        subLabelArray: daycare?.days?.map(day => FULL_DAYS[FULL_WEEK_DAYS_MAP.indexOf(day)]),
        unit: ''
      }
    ]
  ];

  return (
    <Container>
      <SectionsContainer>
        <HealthPetRecords pet={pet} />
        {/* <SectionContainer>
          <HealthMeasurementWrapper>
            <HealthMeasurementContainer>
              <HealthMeasurementHeader>
                <HealthMeasurementTitle>Body Condition Score</HealthMeasurementTitle>
                <HealthMeasurementValue color={Colors.primary}>
                  {SHAPES.indexOf(shape) + 1} - {sentenceCase(shape === 'BELOW_IDEAL' ? 'LEAN' : shape || '')}
                </HealthMeasurementValue>
              </HealthMeasurementHeader>
              <HealthMeasurementHeader>
                <HealthMeasurementTitle>Current Weight</HealthMeasurementTitle>
                <HealthMeasurementValue>{currentWeight ? `${currentWeight} kg` : '-'}</HealthMeasurementValue>
              </HealthMeasurementHeader>
              <EditHealthWeight pet={pet} />
            </HealthMeasurementContainer>
            <HealthChart pet={pet} />
          </HealthMeasurementWrapper>
        </SectionContainer> */}

        <SectionContainer>
          <PetRecordEntitiesContainer column>
            <PetRecordRecordsOptions>
              <HealthMeasurementTitle>Allergies & Conditions</HealthMeasurementTitle>
              <EditHealthPetRecord pet={pet} />
            </PetRecordRecordsOptions>
            <PetRecordEntitiesContainer noPadding noBorderRadius mobilePadding="0">
              {allergiesAndConditionsDataSections.map((section, index) => (
                <SectionWrapper key={index}>
                  {section.map((item, i) => (
                    <PetRecordEntity key={i}>
                      <PetRecordEntitySubLabel>{item.label}</PetRecordEntitySubLabel>
                      <PetRecordEntityLabel>{item.subLabel ? item.subLabel + (item.unit || '') : '-'}</PetRecordEntityLabel>
                    </PetRecordEntity>
                  ))}
                </SectionWrapper>
              ))}
            </PetRecordEntitiesContainer>
          </PetRecordEntitiesContainer>
        </SectionContainer>

        <SectionContainer>
          <PetRecordEntitiesContainer column>
            <PetRecordRecordsOptions>
              <HealthMeasurementTitle>Stats</HealthMeasurementTitle>
              <EditHealthPetRecordStats pet={pet} />
            </PetRecordRecordsOptions>
            <PetRecordEntitiesContainer noPadding noBorderRadius mobilePadding="0">
              {statsDataSections.map((section, index) => (
                <SectionWrapper key={index}>
                  {section.map((item, i) => (
                    <PetRecordEntity key={i}>
                      <PetRecordEntitySubLabel>{item.label}</PetRecordEntitySubLabel>
                      <PetRecordEntityLabel>{item.subLabel ? item.subLabel + (item.unit || '') : '-'}</PetRecordEntityLabel>
                    </PetRecordEntity>
                  ))}
                </SectionWrapper>
              ))}
            </PetRecordEntitiesContainer>
          </PetRecordEntitiesContainer>
        </SectionContainer>

        {/* <SectionContainer>
          <PetRecordEntitiesContainer column>
            <VetDaycareTitle>
              <span>Vet & Daycare Details</span>
              <EditHealthBranchPetRecodItem pet={pet} />
            </VetDaycareTitle>
            <Divider />
            <PetRecordEntitiesContainer noPadding>
              {vetDaycareDetailsSections.map((section, index) => (
                <SectionWrapper key={index}>
                  {section.map((item, i) => (
                    <PetRecordEntity key={i}>
                      <PetRecordEntitySubLabel>{item.label}</PetRecordEntitySubLabel>
                      <PetRecordEntityLabel>{item.subLabel && item.subLabel + (item.unit || '')}</PetRecordEntityLabel>

                      {item.subLabelArray && item.subLabelArray.map((subLabel, i) => <PetRecordEntityLabel key={i}>{subLabel}</PetRecordEntityLabel>)}

                      {!item.subLabel && !item.subLabelArray?.length && <PetRecordEntityLabel>-</PetRecordEntityLabel>}
                    </PetRecordEntity>
                  ))}
                </SectionWrapper>
              ))}
            </PetRecordEntitiesContainer>
          </PetRecordEntitiesContainer>
        </SectionContainer> */}
      </SectionsContainer>
    </Container>
  );
};

export default Health;

export const EditBtn: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const icons = useIcons();
  const moreIcon = icons?.moreIcon?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  return <CounterBtn bgImg={moreIcon} onClick={onClick} />;
};

export const EditHealthWeight = ({ pet }: { pet: Pet }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => <HealthWeightModal pet={pet} />,
          title: 'BCS & Weight'
        })
      }
    />
  );
};

export const EditHealthPetRecord = ({ pet }: { pet: Pet }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => <HealthAllergiesConditionsModal pet={pet} />,
          title: 'Allergies & Conditions'
        })
      }
    />
  );
};

export const EditHealthPetRecordStats = ({ pet }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => <HealthPetRecordStateModal pet={pet} />,
          title: 'Pet Stats'
        })
      }
    />
  );
};

export const EditHealthBranchPetRecodItem = ({ pet }: { pet: Pet }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => <HealthBranchPetRecordItemModal pet={pet} />,
          title: 'Vet & Daycare Details'
        })
      }
    />
  );
};

export const EditPetRecordMeals = ({ pet }: { pet: Pet }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => (
            <HealthEditPetRecordMealsModal
              pet={pet}
              options={{
                includeWeight: false
              }}
            />
          ),
          title: 'Diet & Feeding Schedule'
        })
      }
    />
  );
};

export const EditPetRecordFeedingRoutine = ({ petProfileBehavior }: { petProfileBehavior: Pet['PetRecord']['PetBehavior'] }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => (
            <HealthEditPetRecordFeedingRoutineModal
              petProfileBehavior={petProfileBehavior}
              options={{
                includeWeight: false
              }}
            />
          ),
          title: 'Edit Feeding Routine'
        })
      }
    />
  );
};

export const EditPetRecordTreatmentRoutine = ({ petProfileBehavior }: { petProfileBehavior: Pet['PetRecord']['PetBehavior'] }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => (
            <HealthEditPetRecordTreatmentRoutineModal
              petProfileBehavior={petProfileBehavior}
              options={{
                includeWeight: false
              }}
            />
          ),
          title: 'Edit Treatment Routine'
        })
      }
    />
  );
};
