import React from 'react';
import ModalDialog from '../../components/Modal/ModalDialog';
import OptionDropdown from '../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_TYPES, OPTION_DROPDOWN_MENU_BUTTON_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import AppUserDetailsModal from '../../components/UserDrawer/AppUserDetailsModal';
import PetDetailsModal from '../../components/UserDrawer/PetDetailsModal';
import { useQuery } from '@apollo/client';
import { GetBusUserProfile } from '../../queries';
import { isUserSuperVisorOrAdminOrSuper } from '../../utils/helpers';

const AddClientPetAction = () => {
  const { data: { getBusUserProfile: currentBusUserProfile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const isAdminOrSuperAdmin = isUserSuperVisorOrAdminOrSuper(currentBusUserProfile);
  return (
    <OptionDropdown
      options={[
        {
          id: 'addItems',
          optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
          items: [
            ...(isAdminOrSuperAdmin
              ? [
                  {
                    name: 'Add Client',
                    value: 'addAppUser',
                    onClick: () => {
                      ModalDialog.openModal({
                        content: () => <AppUserDetailsModal />,
                        title: 'Add Client'
                      });
                    }
                  },
                  {
                    name: 'Add Pet',
                    value: 'addPet',
                    onClick: () => {
                      ModalDialog.openModal({
                        content: () => <PetDetailsModal />,
                        title: 'Add Pet'
                      });
                    }
                  }
                ]
              : [])
          ]
        }
      ]}
      menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS_BLACK}
      noApplyButton
    />
  );
};

export default AddClientPetAction;
