import styled, { css } from 'styled-components';
import Colors from '../../Colors';

export const GroupHeader = styled.dt<{ isSticky?: boolean; dispaly?: string }>`
  /* padding: 16px; */
  font-size: 16px;
  display: ${props => props.dispaly || 'auto'};
  justify-content: center;
  align-items: center;
  color: ${Colors.secondaryText};
  @media (max-width: 768px) {
    padding: 4px 0 6px 0;
  }
`;
export const BookingList = styled.ul`
  font-size: 14px;
  height: 100%;
  padding-bottom: 0;
  margin: 0;
  flex-direction: column;
  display: flex;
  overflow-x: hidden;
`;

export const ActionContent = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 15px;
  margin-left: 4px;
  width: 56px;
  color: #eee;
  user-select: none;
  background-color: ${props => props.backgroundColor};
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const BookingButtonImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const RadioSwitchContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  z-index: 10;
  background-color: #fff;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;

  span {
    font-size: 12px;
    color: ${Colors.greyOutTitle};
    font-weight: bold;
  }

  label {
    margin: 0;
  }
`;

export const SlotAvailabilityContainer = styled.div<{ hover?: boolean }>`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  background-color: #f0f0f0;

  user-select: none;

  ${props =>
    props.hover &&
    css`
      &:hover {
        background-color: #e0e0e0 !important;
      }
    `}
`;

export const BookingActionsContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-end;
  padding: 16px 32px;
  gap: 8px;
`;
