import { GatsbyImage } from 'gatsby-plugin-image';
import React, { forwardRef } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { FadeInBottom, Shake, SlideInFromBottom } from '../Shared/Animations';

export const ChatContainer = styled.div`
  width: 370px;
  @media (max-width: 1200px) {
    width: 330px;
  }
`;
export const LogoContainer = styled.div``;
export const JoinRoomButton = styled.button`
  height: 40px;
  background-color: ${Colors.primary};
  color: ${Colors.white};
  border: 0;
  outline: none;
  cursor: pointer;
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const EmbededItemsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 20px;
  height: 60px;
  font-size: 12px;
  font-weight: bold;
  box-sizing: border-box;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AttachIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  box-shadow: none;
`;

export const EmbedReportContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

export const InputBarAreaContainer = styled.div<{ noPadding?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 27px;
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`;

export const InputBarAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type InputBarProps = { textError?: boolean; borderRadius?: number; fontSize?: number } & TextareaAutosizeProps & React.RefAttributes<HTMLTextAreaElement>;

export const InputBarArea = styled(forwardRef<HTMLTextAreaElement, InputBarProps>(({ textError, fontSize, ...props }, ref) => <TextareaAutosize ref={ref} {...props} />))<InputBarProps>`
  border: solid 1px #979797;
  /* border-radius: 19px; */
  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius}px;
    `}
  min-height: 25px;
  resize: none;
  padding: 8px 12px;
  padding-bottom: 0;
  flex: 1;
  &:focus {
    outline: none;
    border: solid 1px transparent;
    box-shadow: 0 0 1px 1px ${Colors.black};
  }
  ${({ textError }) =>
    textError &&
    css`
      border: solid 1px ${Colors.error};
      box-shadow: 0 0 1px 1px ${Colors.error};
    `}

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `}

  @media (max-width: 768px) {
    &:focus {
      border: solid 1px ${Colors.primary};
    }
  }

  @media (max-width: 1200px) {
    padding-right: 36px;
    font-size: 14px;
  }
`;

export const MessagesContainer = styled.ul`
  list-style-type: none;
  font-size: 14px;
  overflow: auto;
  height: calc(100vh - 132px);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
  margin: 0;
  flex-direction: column-reverse;
  display: flex;
  overflow-x: hidden;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
export const DownloadIcon = styled.img`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const ChatImageContainer = styled.a`
  position: relative;
  height: 200px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    filter: grayscale(0.5);
  }
  &:hover > ${DownloadIcon} {
    opacity: 1;
  }
`;
export const ChatImage = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 6px;
  object-fit: cover;
`;
export const MessagePop = styled.div<{ isFromUser: boolean; firstCollection?: boolean; lastCollection?: boolean }>`
  /* position: relative; */
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 12px;
  white-space: pre-wrap;
  /* ${props =>
    props.isFromUser
      ? css`
          border-bottom-right-radius: 2px;
        `
      : css`
          border-bottom-left-radius: 2px;
        `} */
  /* min-width: 232px; */
  max-width: 232px;
  word-wrap: break-word;
  padding: 12px;
  background: ${props => (props.isFromUser ? 'linear-gradient(0deg, #7552A1 -155.69%, #009BA6 113.62%)' : '#F4F4F4')};

  color: ${props => (props.isFromUser ? Colors.white : Colors.black)};
  /* ${props =>
    props.isFromUser &&
    css`
      margin-left: 30px;
    `} */
  /* &:before {
    content: '';
    position: absolute;
    background-color: transparent;
    bottom: -13px;
    ${props =>
    props.isFromUser
      ? css`
          right: -38px;
        `
      : css`
          left: -38px;
        `}
    height: 51px;
    width: 25px;
    ${props =>
    props.isFromUser
      ? css`
          border-bottom-right-radius: 15px;
        `
      : css`
          border-bottom-left-radius: 11px;
        `}
    box-shadow: 0 ${props => (props.isFromUser ? '15px' : '11px')} 0 0 ${props => (props.isFromUser ? Colors.black : '#ededed')};
    transform: rotate(${props => (props.isFromUser ? '90deg' : '270deg')});
  } */
  @media (max-width: 1200px) {
    /* min-width: 225px; */
    max-width: 225px;
  }

  @media (max-width: 768px) {
    // min-width: 70vw;
    max-width: calc(70vw);
  }

  ${props =>
    props.firstCollection &&
    css`
      &:before {
        content: '';
        display: none;
      }

      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 0;
    `};

  ${props =>
    props.lastCollection &&
    css`
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    `};
`;
export const SenderLabel = styled.div`
  font-weight: 800;
`;
export const SenderIcon = styled.img<{ isToUser: boolean; hasMessageReadStatuses?: boolean }>`
  height: 24px;
  width: 24px;
  bottom: ${props => (props.hasMessageReadStatuses ? 0 : 0)}px;
  position: absolute;

  ${props =>
    props.isToUser
      ? css`
          left: -30px;
        `
      : css`
          left: 0;
        `}

  border-radius: 15px;
`;
export const DateLabel = styled.div<{ isFromUser: boolean }>`
  color: ${props => (props.isFromUser ? '#e4e4e4' : '#898989')};
  font-weight: 100;
  font-size: 8px;
  margin-right: 0;
  align-self: ${props => (props.isFromUser ? 'flex-start' : 'flex-start')};
  text-align: ${props => (props.isFromUser ? 'left' : 'left')};
`;
export const MessageContainer = styled.li<{ isFromUser: boolean }>`
  display: flex;
  flex-direction: column;
  animation: ${FadeInBottom} 0.3s cubic-bezier(0.04, 0.98, 0.71, 0.99);
  position: relative;
  align-self: ${props => (props.isFromUser ? 'flex-end' : 'flex-start')};
  margin-right: ${props => (props.isFromUser ? '20px' : '')};
  margin-left: ${props => (!props.isFromUser ? '40px' : '')};
`;

export const AppUserImage = styled(GatsbyImage)`
  height: 40px;
  width: 40px;
  border-radius: 20px;
`;
export const AppUserImageContainer = styled.div`
  padding: 10px 27px;
  display: flex;
  align-items: center;
`;
export const AppUserName = styled.p`
  font-size: 16px;
  color: ${Colors.subtitle2};
  font-weight: bold;
  padding-left: 15px;
`;

export const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  a {
    color: ${Colors.primary};
    text-decoration: none;
    text-align: center;
  }
`;

export const DynamicLinkButton = styled.button<{ isFromUser?: boolean; error?: boolean }>`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 8px;
  margin-bottom: 6px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid ${props => (props.isFromUser ? `${Colors.white}` : `${Colors.primary}`)};
  border-radius: 8px;
  background: ${props => (props.isFromUser ? `${Colors.primary}` : `${Colors.white}`)};
  color: ${props => (props.isFromUser ? `${Colors.white}` : `${Colors.primary}`)};
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(95%);
  }
  ${props =>
    props.error &&
    css`
      animation: ${Shake} 0.4s ease;
    `}
`;

export const RemoveEmbededItemButtonContainer = styled.div<{ bgImg?: string }>`
  position: absolute;
  top: -5px;
  right: -5px;
  background-image: url(${props => props.bgImg});
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const DynamicLinkModalContainer = styled.div<{ show?: boolean }>`
  position: fixed;
  width: 300px;
  right: 360px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 60vh;
  overflow-y: scroll;
  background-color: ${Colors.white};
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  ${props =>
    props.show &&
    css`
      opacity: 1;
      visibility: visible;
      animation: ${SlideInFromBottom} 0.5s ease-in-out;
      bottom: 20px;
    `}

  ${props =>
    !props.show &&
    css`
      visibility: hidden;
      pointer-events: none;
      bottom: -100%;
      transition: all 0.3s ease-in-out;
    `}

  @media (max-width: 1200px) {
    right: 300px;
  }
`;

export const ReportContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ChatImagesTimeContainer = styled.div<{ isFromUser?: boolean }>`
  width: 220px;
  background: ${props => (props.isFromUser ? 'linear-gradient(0deg, #7552A1 -155.69%, #009BA6 113.62%)' : '#F4F4F4')};
  max-width: 290px;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${props => (props.isFromUser ? Colors.white : Colors.black)};
`;

export const ChatImagesContainer = styled.div`
  width: 100%;
  max-width: 290px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 8px;
  align-items: center;
`;

export const LoaderContainer = styled.div`
  margin: 0 10px;
`;

export const CloseButtonContainer = styled.div``;

export const ProfileActionBarContainer = styled.div`
  width: 100%;
`;

export const ArrowIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${Colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 4px;
  img {
    filter: invert(1);
  }
`;

export const InputBarAreaSend = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const LinkAnchor = styled.a<{ isFromUser: boolean }>`
  color: ${props => (props.isFromUser ? `${Colors.white}` : `${Colors.primary}`)};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const GroupHeaderImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px 20px;
  width: 30px;
`;
