import React, { useEffect, useState } from 'react';
import useIcons from '../../../hooks/useIcons';
import { CounterBtn } from '../../Calendar/styled';
import { Divider } from '../SideTabs/styles';
import { BtnContainer, ServiceButton, ServicesAndStaffContainer, ToolBarString, ToolsBarContainer, ToolsContainer, VerticalDivider } from './styles';

import { GatsbyImage } from 'gatsby-plugin-image';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useURLSearchParams from '../../../hooks/useURLSearchParams';
import { vars } from '../../../reactive';
import SearchBar from '../Filters/SearchBar';

const ToolBar = ({
  toolBarButtons,
  urlSearchParam,
  onSearchChange,
  displayTools = true,
  displayAll = true,
  selectOnlyOne = false,
  displayDivider = true,
  defaultValue,
  middleAction,
  padding,
  customizedToolComponent = () => {},
  displayString
}: // filterHeaderActions
{
  toolBarButtons?: { name: string; id: string; onClick?: (tab: string) => void; divider?: boolean }[];
  urlSearchParam: string;
  onSearchChange?: (value: string) => void;
  displayTools?: boolean;
  displayAll?: boolean;
  selectOnlyOne?: boolean;
  displayDivider?: boolean;
  defaultValue?: string;
  middleAction?: JSX.Element | null;
  padding?: string;
  customizedToolComponent?: JSX.Element;
  displayString?: string | JSX.Element;
  // filterHeaderActions?: { action: JSX.Element; id: string }[];
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const icons = useIcons();
  const searchIcon = icons?.searchBlack?.childImageSharp.gatsbyImageData;
  const multiSelectIcon = icons.multiSelectBlack?.childImageSharp.gatsbyImageData;

  const [selected, setSelected] = useURLSearchParams<string[]>(urlSearchParam);

  const [showServices, setshowServices] = useState<boolean>(true);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [filterItemClicked, setFilterItemClicked] = useState<{ [key: string]: boolean }>({});

  const isAllSelected = !selected || !selected?.length || toolBarButtons?.length === 0;
  const ids = toolBarButtons?.map(service => service.id);
  const filteredServices = ids?.filter(id => selected.includes(id));

  const onClickSearchBtn = () => {
    setshowServices(!showServices);
    setShowSearchInput(!showSearchInput);
  };

  // const onClickMultiSelect = () => {
  //   return (
  //     <ListHeaderContentContainer>
  //       <FlexRow>
  //         {filterHeaderActions?.map(({ action, id }) => (
  //           <Fragment key={id}>{action}</Fragment>
  //         ))}
  //       </FlexRow>
  //     </ListHeaderContentContainer>
  //   );
  // };

  const handleClickOnService = (id: string) => {
    if (selected.includes(id)) {
      const ids = selected.filter(el => el !== id);
      setSelected(ids);
      return;
    }
    const ids = [...selected, id];
    setSelected(ids);
  };

  const handleClickAllServices = () => {
    setSelected([]);
  };

  const handleClickFilterItem = (value: string) => {
    setFilterItemClicked(prevState => ({
      ...prevState,
      [value]: !filterItemClicked[value]
    }));
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected([defaultValue]);
    }
  }, [defaultValue]);

  const handleClickOnOneService = (id: string) => {
    setSelected([id]);
  };

  useEffect(() => {
    return () => {
      vars.bookingVariables({
        appointment_search: undefined
      });
      vars.chatVariables({
        chat_search: undefined
      });
    };
  }, []);

  return (
    <>
      <ToolsBarContainer padding={padding}>
        {showSearchInput && onSearchChange && <SearchBar onSearch={onSearchChange} showAlways />}
        {mobile && displayTools && onSearchChange && (
          <CounterBtn onClick={onClickSearchBtn}>
            <GatsbyImage image={searchIcon} alt="searchIcon" />
          </CounterBtn>
        )}
        {!showSearchInput && !displayString && (
          <ServicesAndStaffContainer isVisible={showServices}>
            {displayAll && !mobile && (
              <BtnContainer>
                <ServiceButton isSelected={isAllSelected} onClick={handleClickAllServices}>
                  All
                </ServiceButton>
                <VerticalDivider />
              </BtnContainer>
            )}
            {displayAll && mobile && (
              <BtnContainer>
                <ServiceButton isSelected={isAllSelected} onClick={handleClickAllServices}>
                  All
                </ServiceButton>
                <VerticalDivider />
              </BtnContainer>
            )}

            {!displayString &&
              toolBarButtons?.map(({ name, id, divider }, index) => {
                const isSelected = filteredServices.includes(id) && !isAllSelected;
                return (
                  <BtnContainer key={index}>
                    <ServiceButton
                      key={id}
                      isSelected={isSelected}
                      onClick={() => {
                        !selectOnlyOne ? handleClickOnService(id) : handleClickOnOneService(id);
                      }}
                    >
                      {name}
                    </ServiceButton>
                    {divider && <VerticalDivider />}
                  </BtnContainer>
                );
              })}
          </ServicesAndStaffContainer>
        )}

        {displayString && !showSearchInput && <ToolBarString>{displayString}</ToolBarString>}

        {displayTools && !mobile && (
          <ToolsContainer>
            <VerticalDivider />

            {onSearchChange && (
              <CounterBtn onClick={onClickSearchBtn}>
                <GatsbyImage image={searchIcon} alt="searchIcon" />
              </CounterBtn>
            )}
            {middleAction}

            {customizedToolComponent}
          </ToolsContainer>
        )}
      </ToolsBarContainer>
      {displayDivider && <Divider />}
    </>
  );
};

export default ToolBar;
