import { useMutation, useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ModalBody } from '../../../../components/Modal/styled';
import { convertDurationToMinutes } from '../../../../components/Shared/DurationSelector';
import { ModalContent, ModalWrapper } from '../../../../components/Shared/Shared';
import SideTab, { Section, SIDE_TABS_TYPES } from '../../../../components/Shared/SideTabs/SideTabs';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import { AddBranchProduct, EditBranchProduct, GetBranchProducts, GetBusUserProfile, GetFromFlag } from '../../../../queries';
import { offsetUTC } from '../../../../utils/dates';
import { BOOKING_TYPE, SLOT_OFFSET_TYPE } from '../../../Bookings/types';
import { getCustomizationsDefaultValues, getSelectedCustomizations } from '../../Customizations/utils';
import { getProductOptionsDefaultValue, getSelectedProductOptions } from '../../ProductOptions';
import { getChargeType, isProductNoCharge, isProductPostpaid } from '../../ProductPrice';
import { CHARGE_STATUS, Product, productStatuses } from '../../types';
import StoreProductModalAvailability from './StoreProductModalAvailability';
import StoreProductModalCustomizations from './StoreProductModalCustomizations';
import StoreProductModalDetails from './StoreProductModalDetails';
import StoreProductModalPricing from './StoreProductModalPricing';
import { petTypesOptions, StoreProductModalForm as StoreProductModalFormType } from './types';
import usePhotoUpload, { useDeletePhotos } from '../../../../hooks/usePhotoUpload';
import { BinaryUploadProps, BinaryUploadRef } from '../../../Pets/Reports/BinaryUpload';
import { BusUserProfile } from '../../../../components/Profile/types';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { MarkupEditorRef } from '../../../../components/Shared/MarkupEditor';
import { kebabCase } from '../../../../utils/helpers';
import { FormButtonsContainer, FormSubmitButton } from '../../../../components/Shared/Forms/Forms';
import { Divider } from '../../../../components/SideBar/styled';
import StoreProductModalBookingRules from './StoreProductModalBookingRules';

const StoreProductModalForm = ({ product, type }: { product?: Product; type: Product['type'] }) => {
  const tabsState = useState('details');
  const isSubscription = type === 'subscription';
  const isService = type === 'service';
  const isProduct = type === 'product';
  const { data: { getBusUserProfile: profile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const [activeTab] = tabsState;
  const defaultChargeStatus = product
    ? isProductNoCharge(product)
      ? CHARGE_STATUS.NO_CHARGE
      : isProductPostpaid(product)
      ? CHARGE_STATUS.CHARGE_LATER
      : CHARGE_STATUS.CHARGE_NOW
    : CHARGE_STATUS.CHARGE_NOW;

  const { data: { flagGet: fromFlagData = [] } = {} } = useQuery(GetFromFlag);

  const [addBranchProduct, { loading: loadingAddProduct, error: errorAddProduct }] = useMutation(AddBranchProduct, {
    refetchQueries: ['getBranchProducts']
  });

  const [editBranchProduct, { loading: loadingEditProduct, error: errorEditProduct }] = useMutation(EditBranchProduct, {
    refetchQueries: ['getBranchProducts']
  });

  const hasFromFlag = (product?.Flags.filter(flag => flag.id === fromFlagData?.[0]?.id).length || 0) > 0;

  const defaultProductOptionsValue = getProductOptionsDefaultValue({ product: product, hasFromFlag });
  const customizationsDefaultValue = getCustomizationsDefaultValues(product?.customizations);

  const defaultBookingType = product?.booking_type || BOOKING_TYPE.SLOT;

  const defaultValues: StoreProductModalFormType = {
    name: product?.name || '',
    status: product?.status
      ? { value: product?.status, label: productStatuses.find(status => product?.status === status.value)?.availablality || '' }
      : { value: productStatuses[0]?.value, label: productStatuses[0]?.availablality },
    category: product?.ProductCategories.map(category => ({ label: category.name, value: category.id }))?.[0] || null,
    cta_text: product?.cta_text || isProduct ? 'Add to Cart' : isService ? 'Book Now' : 'Subscribe Now',
    charge_status: defaultChargeStatus,
    original_price: product?.original_price || 0,
    price_discount: Number(Math.trunc((1 - (Number(product?.price || 0) / Number(product?.original_price || 0) || 1)) * 100).toFixed(2)) || 0,
    productOptions: defaultProductOptionsValue,
    customizations: customizationsDefaultValue,
    booking_type: defaultBookingType,
    branch_schedules: product?.BranchSchedules?.map(schedule => schedule.id) || [],
    duration: product?.duration || 0,
    sessions: product?.sessions || 0,
    slotOffsetType: product?.slots_start_date ? SLOT_OFFSET_TYPE.START_DATE : product?.max_slot_offset ? SLOT_OFFSET_TYPE.SLOT_TIME_OFFSET : SLOT_OFFSET_TYPE.NO_OFFSET,
    slots_start_date: product?.slots_start_date || new Date().toISOString().split('T')[0],
    max_slot_offset: (product?.max_slot_offset || 0) / 1000,
    additional_info: {
      payment_description: product?.additional_info?.payment_description || '',
      booking_order_close_message: product?.additional_info?.booking_order_close_message || ''
    },
    description: product?.description || '',
    short_description: product?.short_description || '',
    sort_index: product?.sort_index || 1,
    max_bulk_orders: product?.max_bulk_orders || 1,
    stock: product?.stock !== null && product?.stock !== undefined && product?.stock >= 0 ? String(product?.stock) : '',
    unit: product?.unit || '',
    sku: product?.sku || '',
    weight: product?.weight || 0,
    colors: {
      calendar: product?.colors?.calendar || '#B5D1F1'
    },
    slugs: {
      calendar: product?.slugs?.calendar || '',
      path: product?.slugs?.path || ''
    },
    pet_rules: {
      max_pet_count_type: product?.pet_rules?.max_pet_count && product?.pet_rules?.max_pet_count > 0 ? 'limited' : 'no_limit',
      max_pet_count: product?.pet_rules?.max_pet_count || 0,
      allowed_pet_types: product?.pet_rules?.allowed_pet_types || petTypesOptions.map(option => option.value),
      excluded_pet_breeds: product?.pet_rules?.excluded_pet_breeds || [],
      pet_age_type: product?.pet_rules?.pet_min_age && product?.pet_rules?.pet_max_age ? 'between' : product?.pet_rules?.pet_min_age ? 'above' : product?.pet_rules?.pet_max_age ? 'below' : 'none',
      pet_min_age: product?.pet_rules?.pet_min_age || 0,
      pet_max_age: product?.pet_rules?.pet_max_age || 0
    },
    allow_booking_from: product?.SubscriptionProducts?.length ? 'subscribed_to' : 'no_rule',
    product_subscription_dependencies: product?.SubscriptionProducts?.map(dependency => dependency.id) || [],
    subscription_period: product?.payment?.interval || 'month'
  };

  const formOptions = useForm<StoreProductModalFormType>({
    defaultValues,
    shouldUnregister: false,
    mode: 'onChange'
  });

  const { handleSubmit, formState, trigger } = formOptions;

  const commonProps = {
    formOptions,
    defaultValues,
    product,
    type
  };

  const markupEditorRef = useRef<MarkupEditorRef>(null);
  const photosRef = useRef<BinaryUploadRef>(null);
  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const onSubmit = handleSubmit(async data => {
    const productOptions = getSelectedProductOptions(data.productOptions);
    const customizations = getSelectedCustomizations(data.customizations);
    const sharedVariables = {
      name: data?.name,
      original_price: String(data?.original_price),
      price: String((Number(data?.original_price) * (1 - Number(data?.price_discount || 0) / 100)).toFixed(2) || 0),
      cta_text: data?.cta_text,
      status: data?.status?.value,
      type,
      colors: data?.colors,
      slugs: {
        calendar: data?.slugs?.calendar || data?.name,
        path: data?.slugs?.path || kebabCase(data?.name.trimEnd?.().trimStart?.())
      },
      sort_index: Number(data?.sort_index || 1),
      customizations,
      product_categories: data?.category?.value ? [data?.category?.value] : [],
      description: markupEditorRef?.current?.getHTML() || '',
      short_description: data?.short_description,
      flags: productOptions?.from ? [fromFlagData?.[0]?.id] : [],
      ...productOptions,
      additional_info: {
        ...(data?.additional_info?.payment_description ? { payment_description: data?.additional_info?.payment_description } : {}),
        ...(data?.additional_info?.booking_order_close_message ? { booking_order_close_message: data?.additional_info?.booking_order_close_message } : {})
      },
      charge_type: getChargeType(data?.charge_status, productOptions),
      stock: data?.stock ? String(data?.stock) : null,
      payment: {}
    };

    const serviceVariables = isService
      ? {
          duration: data?.duration !== undefined ? convertDurationToMinutes(data?.duration) : null,
          booking_type: data?.booking_type,
          branch_schedules: data?.branch_schedules,
          slots_start_date: data?.slots_start_date ? new Date(new Date(data?.slots_start_date).getTime() - offsetUTC(data?.slots_start_date)) : null,
          max_slot_offset: data?.max_slot_offset !== undefined ? Number(convertDurationToMinutes(data?.max_slot_offset)) * 1000 : 0,
          sessions: Number(data?.sessions) || 1,
          max_bulk_orders: Number(data?.max_bulk_orders) || 1,
          pet_rules: {
            max_pet_count: data?.pet_rules?.max_pet_count || 0,
            allowed_pet_types: data?.pet_rules?.allowed_pet_types || [],
            excluded_pet_breeds: data?.pet_rules?.excluded_pet_breeds || [],
            pet_min_age: data?.pet_rules?.pet_min_age || 0,
            pet_max_age: data?.pet_rules?.pet_max_age || Infinity
          },
          product_subscription_dependencies: data?.product_subscription_dependencies
        }
      : {};

    if (isService) {
      sharedVariables.payment = {
        ...(sharedVariables.payment || {}),
        method_optional: productOptions.optional_card_details
      };
    }

    const productVariables = isProduct
      ? {
          sku: data?.sku,
          weight: data?.weight,
          unit: data?.unit
        }
      : {};

    if (isProduct) {
    }

    const subscriptionVariables = isSubscription ? {} : {};

    if (isSubscription) {
      sharedVariables.payment = {
        ...(sharedVariables.payment || {}),
        interval: data?.subscription_period
      };
    }

    const variables = {
      ...sharedVariables,
      ...serviceVariables,
      ...productVariables,
      ...subscriptionVariables
    };

    let newProductId = product?.id;

    if (!product) {
      const { data } = await addBranchProduct({ variables });
      newProductId = data?.addProduct?.id;
    }

    const photos = await photosRef.current?.getPhotos(`providers/${profile?.Provider.id}/branches/${profile?.Branch.id}/products/${newProductId}`);

    const shouldEdit = (product && product.id) || photos?.length;

    if (shouldEdit) {
      await editBranchProduct({
        variables: {
          ...variables,
          id: newProductId,
          photos
        }
      });
    }

    ModalDialog.closeModal();
  });

  const binaryUploadProps: BinaryUploadProps = {
    deletePhotos,
    uploadPhotos,
    ref: photosRef,
    defaultBinaries: {
      photos: product?.photos || [],
      attachments: []
    },
    options: {
      enableAttachments: false
    }
  };

  const markupEditorProps = {
    ref: markupEditorRef
  };

  const loading = loadingAddProduct || loadingEditProduct || loadingUploadPhotos;

  const error = !!errorAddProduct?.message || !!errorEditProduct?.message || !!errorUploadPhotos?.message;

  const sideTabSections: Section[] = [
    {
      tabs: [{ name: 'Details', value: 'details' }]
    },
    ...(!isSubscription
      ? [
          {
            tabs: [{ name: 'Availability', value: 'availability' }, ...(isService ? [{ name: 'Booking Rules', value: 'booking-rules' }] : [])]
          }
        ]
      : []),
    {
      tabs: [
        { name: 'Pricing', value: 'pricing' },
        { name: 'Variations', value: 'variations' }
      ]
    }
  ];

  return (
    <ModalLayout
      sideTabs={
        <SideTab
          sections={sideTabSections}
          type={SIDE_TABS_TYPES.STATE_BUTTONS}
          defaultTab="details"
          tabsState={tabsState}
          isModal
          onClickTab={async () => {
            return await trigger();
          }}
          backgroundColor="f4f4f4"
        />
      }
      buttons={
        <FormSubmitButton onClick={onSubmit} disabled={formState.isSubmitting} error={error}>
          {formState.isSubmitting || loading ? 'Saving...' : 'Save Changes'}
        </FormSubmitButton>
      }
      compact={false}
    >
      <>
        {activeTab === 'details' && <StoreProductModalDetails {...commonProps} binaryUploadProps={binaryUploadProps} markupEditorProps={markupEditorProps} />}
        {activeTab === 'pricing' && <StoreProductModalPricing {...commonProps} />}
        {activeTab === 'variations' && <StoreProductModalCustomizations {...commonProps} />}
        {activeTab === 'availability' && <StoreProductModalAvailability {...commonProps} />}
        {activeTab === 'booking-rules' && <StoreProductModalBookingRules {...commonProps} />}
      </>
    </ModalLayout>
  );
};

const StoreProductModal = ({ productId, type }: { productId?: string; type: Product['type'] }) => {
  const { data: { getBranchProducts: [product] = [] } = {}, loading: productLoading } = useQuery<{ getBranchProducts: Product[] }>(GetBranchProducts, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: [productId],
      type,
      requisite_queries: ['id', 'type'],
      alternative_queries: []
    },
    skip: !productId
  });

  if (productLoading) {
    return <CenteredLoader />;
  }

  return <StoreProductModalForm product={product} type={type} />;
};

export default StoreProductModal;
