import { useEffect, useState } from 'react';

const useBulkSelect = ({ getItems }: { getItems: () => { id: string }[] } = { getItems: () => [] }) => {
  const [show, setShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const toggleShow = () => setShow(prev => !prev);

  const hide = () => setShow(false);

  const toggleSelect = (id: string) => {
    setSelected(prev => (prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]));
  };

  const toggleSelectAllItems = () => {
    setSelectedAll(prev => !prev);
  };

  const clearSelected = () => {
    setSelectedAll(false);
    requestAnimationFrame(() => setSelected([]));
  };

  const toggleSelectMultiple = (ids: string[]) => {
    setSelected(prev => {
      const newSelected = ids.filter(id => !prev.includes(id));
      return [...prev, ...newSelected];
    });
  };

  const isSelected = (id: string) => selected.includes(id);

  const allItems = getItems?.()?.map(item => item.id) || [];

  useEffect(() => {
    setSelected(selectedAll ? allItems : []);
  }, [selectedAll, JSON.stringify(allItems)]);

  return {
    show,
    selected,
    selectedAll,
    toggleShow,
    hide,
    toggleSelect,
    toggleSelectAllItems,
    clearSelected,
    toggleSelectMultiple,
    isSelected
  };
};

export type UseBulkSelectOutput = ReturnType<typeof useBulkSelect>;

export default useBulkSelect;
