import styled, { css } from 'styled-components';
import Colors from '../../../Colors';

export const BillingStatsContainer = styled.div<{ noMargin?: boolean; clickable?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export const BillingStatsTitle = styled.span`
  font-size: 20px;
  font-weight: 800;
  color: #000;
  margin-bottom: 16px;
  margin-left: 16px;
  height: 24px;
`;

export const SelectedBillingFiltersContainer = styled.div`
  display: flex;
`;

export const SelectedBillingFiltersWrapper = styled.div<{ small?: boolean; noBottomRadius?: boolean; noTopRadius?: boolean; hoverable?: boolean; secondary?: boolean }>`
  display: flex;
  background-color: #fff;
  width: 100%;
  align-items: center;
  padding: 28px 10px;
  border: 1px solid #e8e8e8;
  ${({ small }) =>
    small &&
    css`
      padding: 10px 10px;
    `}
  ${({ noBottomRadius, noTopRadius }) => {
    if (noBottomRadius && noTopRadius) {
      return css`
        border-radius: 0;
      `;
    }
    if (noBottomRadius) {
      return css`
        border-radius: 15px 15px 0 0;
      `;
    }
    if (noTopRadius) {
      return css`
        border-radius: 0 0 15px 15px;
      `;
    }
    return css`
      border-radius: 15px;
    `;
  }}

  ${({ hoverable }) =>
    hoverable &&
    css`
      &:hover {
        background-color: #f2f2f2;
      }
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      padding: 0;
      border-radius: 0;
      border: none;
      border-top: 2px solid #f4f4f4;
    `}

  position: relative;
`;

export const FilterItem = styled.div<{ active?: boolean; clickable?: boolean; marginToRight?: boolean; minWidth?: number; secondary?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4px 8px;
  margin-left: 16px;
  ${({ marginToRight }) =>
    marginToRight &&
    css`
      margin-left: 0;
      margin-right: 16px;
    `}
  border-radius: 20px;
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  user-select: none;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;

  ${({ secondary, active }) =>
    secondary &&
    css`
      margin: 0;
      border-radius: 0;
      border-bottom: 2px solid ${active ? Colors.black : '#e8e8e8'};
      padding: 16px 20px;
    `}
`;

export const FilterItemContainer = styled.div<{ secondary?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  ${({ secondary }) =>
    secondary &&
    css`
      &:nth-child(odd) {
        background-color: ${Colors.lightGrey3};
      }
      &:nth-child(even) {
        background-color: ${Colors.white};
      }
    `}
`;

export const FilterItemText = styled.span<{ active?: boolean; inactiveColor?: string; secondary?: boolean }>`
  font-size: 14px;
  font-weight: bold;
  color: ${props => (props.secondary ? Colors.black : '#878787')};
  ${({ inactiveColor }) =>
    inactiveColor &&
    css`
      color: ${inactiveColor};
    `}
  ${({ active, secondary }) =>
    active &&
    !secondary &&
    css`
      color: ${Colors.primary};
    `}
`;

export const FilterItemCount = styled.span<{ active?: boolean; small?: boolean; secondary?: boolean }>`
  font-size: 16px;
  font-weight: 800;
  color: ${props => (props.secondary ? Colors.black : '#404040')};

  ${({ active, secondary }) =>
    active &&
    !secondary &&
    css`
      color: ${Colors.primary};
    `}

  ${({ small }) =>
    small &&
    css`
      font-size: 14px;
    `}
`;

export const VerticalDivider = styled.div<{ maxHeight?: string }>`
  width: 1px;
  height: 100%;
  max-height: ${({ maxHeight }) => maxHeight || '100%'};
  background-color: #e8e8e8;
`;

export const ActiveFilterBarContainer = styled.div<{ index: number }>`
  position: absolute;
  left: ${({ index }) => index * 100}%;
  right: 0;
  height: 2px;
  bottom: -10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
`;

export const ActiveFilterBar = styled.div`
  width: 80%;
  height: 100%;
  background-color: ${Colors.primary};
  background-color: ${Colors.primary};
  border-radius: 2px;
`;

export const ActionButton = styled.button`
  background: black;
  color: white;
  border-radius: 30px;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: bold;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 30px;
`;

export const BranchBillingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
