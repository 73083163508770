import React from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchSubmittedForms, GetBranchSubmittedFormsViews } from '../../../queries';
import { SubmittedForm } from '../../Store/BranchForms/types';
import PetsFormRecord from './PetsFormRecord';
import PetsFormsHeader from './PetsFormsHeader';
import { getBranchFormsFiltersVariables, useBranchFormsFilters } from './BranchFormsFilters';
import { ListCount } from '../../../components/Shared/Forms/Forms';

const PetsForms = () => {
  const filtersControl = useBranchFormsFilters();

  const [[hasMoreItems, setHasMoreItems], { data, loading: loadingPetFormsReports, fetchMore }] = usePaginatedQuery<{
    count: number;
    views: SubmittedForm[];
  }>({
    query: GetBranchSubmittedFormsViews,
    limit: 20,
    otherVariables: getBranchFormsFiltersVariables({
      selectedFilters: filtersControl.selectedFilters
    })
  });

  const allForms = data?.getBranchSubmittedFormsViews.views || [];

  return (
    <>
      <PetsFormsHeader filtersControl={filtersControl} />
      <ListCount>{data?.getBranchSubmittedFormsViews.count || 0} Forms</ListCount>
      <InfiniteList
        paddingL={20}
        paddingR={20}
        fetchMore={fetchMore as any}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        itemRenderer={(form: SubmittedForm) => <PetsFormRecord key={form.id} submittedForm={form} />}
        list={allForms}
        loading={loadingPetFormsReports}
        offset={allForms?.length}
      />
    </>
  );
};

export default PetsForms;
