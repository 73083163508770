import { useMutation } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePhotoUpload, { useDeletePhotos } from '../../hooks/usePhotoUpload';
import { EditBranchPet } from '../../queries';
import { vars } from '../../reactive';
import { calculateAgeFromBirthdate } from '../../utils/dates';
import { getRandomPetPlaceholder } from '../../utils/helpers';
import { DRAWER_IDS } from '../DrawerBar/types';
import PetRecordOptions from '../Pets/PetRecordOptions';
import { Pet } from '../Pets/types';
import { LoadingOverlay } from '../Shared/Spinner';
import { ConfettiContainer, CoverContainer, CoverLabel, CoverLabelContainer, CoverNameContainer, CoverSubLabel, PetDrawerEdit, ReportButtonContainer } from './styled';
import ReactConfetti from 'react-confetti';

export enum PROFILE_COVER_TYPES {
  PET = 'PET',
  USER = 'USER'
}

export enum PROFILE_COVER_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

type ProfileCoverProps = {
  profile: Pet;
  defaultSize?: PROFILE_COVER_SIZES;
  type?: PROFILE_COVER_TYPES;
  showEdit?: boolean;
};

const ProfileCover: FC<ProfileCoverProps> = ({ profile, defaultSize = PROFILE_COVER_SIZES.LARGE, type = PROFILE_COVER_TYPES.PET, showEdit }) => {
  const icons = useIcons();
  const petType = type === PROFILE_COVER_TYPES.PET;
  const userType = type === PROFILE_COVER_TYPES.USER;
  const editIcon = icons?.edit?.childImageSharp?.gatsbyImageData;
  const petProfilePic = useMemo(() => profile?.profile_pic || getRandomPetPlaceholder(icons), [icons, profile]);
  const userProfilePic = profile?.profile_pic || icons?.user?.childImageSharp?.gatsbyImageData?.images.fallback.src;
  const { mobile } = useMediaQuery({ mobile: true });

  const petBirthdate = profile?.birthdate;
  const isBirthday = petBirthdate && new Date(petBirthdate).getUTCDate() === new Date().getUTCDate() && new Date(petBirthdate).getMonth() === new Date().getMonth();

  const reportButtonRef = useRef<HTMLDivElement>(null);
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const startingSize = mobile ? PROFILE_COVER_SIZES.SMALL : defaultSize;
  const [size, setSize] = useState(startingSize);

  useEffect(() => {
    setSize(startingSize);
  }, [mobile, startingSize]);

  return (
    <CoverContainer
      size={size}
      bgImage={petType ? petProfilePic : userType && userProfilePic}
      onClick={e => {
        if (reportButtonRef.current?.contains(e.target as Node) || !mobile) {
          return;
        }
        if (size === PROFILE_COVER_SIZES.SMALL) {
          setSize(PROFILE_COVER_SIZES.LARGE);
          return;
        }
        setSize(PROFILE_COVER_SIZES.SMALL);
      }}
    >
      {isBirthday && (
        <ConfettiContainer>
          <ReactConfetti height={330} />
        </ConfettiContainer>
      )}

      <CoverLabelContainer size={size}>
        <CoverNameContainer>
          <CoverLabel size={size}>{profile?.name}</CoverLabel>
          {size !== PROFILE_COVER_SIZES.SMALL && petType && <CoverSubLabel>{calculateAgeFromBirthdate(profile?.birthdate)}</CoverSubLabel>}
        </CoverNameContainer>

        {mobile && (
          <ReportButtonContainer zIndex={!drawerBar?.otherData?.selectedTab ? 12 : 1} ref={reportButtonRef}>
            <PetRecordOptions pets={[profile]} showAlways loadTags />
          </ReportButtonContainer>
        )}
      </CoverLabelContainer>
    </CoverContainer>
  );
};

export default ProfileCover;
