import styled from 'styled-components';

export const PetsListContainer = styled.div`
  width: 600px;
  max-width: 600px;
  overflow-y: auto;
`;

export const RouteHeader = styled.div`
  display: flex;
  padding: 24px 12px;
  gap: 12px;
  max-width: 100%;
  min-width: 100%;
`;

export const RouteColorTag = styled.div<{ color: string }>`
  width: 3px;
  min-width: 4px;
  border-radius: 5px;
  background-color: ${props => props.color};
`;
