import React, { useEffect } from 'react';
import { BusUserProfile } from '../../../components/Profile/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchBusUsers, GetBranchRooms } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import { BranchRoom } from './types';
import BusUserRecord from '../BusUsers/BusUserRecord';
import BranchRoomRecord from './BranchRoomRecord';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import ModalDialog from '../../../components/Modal/ModalDialog';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import StoreProductModal from '../Operations/StoreProductModal/StoreProductModal';
import useIcons from '../../../hooks/useIcons';
import DiscountsModal from '../Discounts/DiscountsModal';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BackButton } from '../../../components/DrawerBar/styled';
import { useDiscountsFilters } from '../Discounts/DiscountsFilters';
import BranchRoomModal from './BranchRoomModal';

const BranchRooms = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchRoom[]>({ query: GetBranchRooms, otherParams: { fetchPolicy: 'cache-and-network' } });

  const { data: { getBranchRooms: branchRooms = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchRooms.GetBranchRooms.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  const icons = useIcons();

  const plusBlackIcon = icons?.plusBlack?.childImageSharp?.gatsbyImageData;

  const onAdd = () => {
    ModalDialog.openModal({
      content: () => <BranchRoomModal />,
      title: 'Add New Pen'
    });
  };

  return (
    <>
      <ToolBar
        displayString="Pens"
        middleAction={
          <>
            <BackButton onClick={onAdd}>
              <GatsbyImage image={plusBlackIcon} alt="add" />
            </BackButton>
          </>
        }
      />
      <RecordsContainer>
        <InfiniteList
          list={branchRooms}
          itemRenderer={(branchRoom: BranchRoom) => <BranchRoomRecord branchRoom={branchRoom} />}
          hasMoreItems={hasMoreItems}
          loading={loading}
          fetchMore={fetchMore}
          offset={branchRooms?.length}
          setHasMoreItems={setHasMoreItems}
        />
      </RecordsContainer>
    </>
  );
};

export default BranchRooms;
