import React, { Fragment } from 'react';
import { ColorTag, VaccRecordContainer } from '../Pets/Health/styled';
import { RecordBody } from '../styled';
import InfiniteList from '../../components/Shared/InfiniteList/InfiniteList';
import FleetSchedulePetRecord from './FleetSchedulePetRecord';
import { VanContainer, VansContainer, UserImage, UserImageContainer, StaffContainer } from './styled';
import useIcons from '../../hooks/useIcons';
import { BranchVehicleRoutesView } from './types';
import { Divider } from '../../components/SideBar/styled';

const FleetScheduleList = ({ singleDayVehiclesRoutesViews }: { singleDayVehiclesRoutesViews: BranchVehicleRoutesView[] }) => {
  const icons = useIcons();
  const convertToAmPm = (timeString: string) => {
    const hours = timeString?.split(':')[0];
    const minutes = timeString?.split(':')[1];
    let hour = parseInt(hours);
    const period = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;
    return `${hour}:${minutes} ${period}`;
  };

  return (
    <VansContainer>
      {singleDayVehiclesRoutesViews?.map((vehicleData, vehicleIdx) => {
        const branchVehicleView = vehicleData?.branchVehicleView;
        const branchVehicleRoutesViews = vehicleData?.branchVehicleRoutesViews;
        return (
          <Fragment key={vehicleIdx}>
            {branchVehicleRoutesViews?.map((branchVehicleRoutesView, index) => {
              const branchRouteView = branchVehicleRoutesView?.branchRouteView;
              const busUsersViews = branchVehicleRoutesView?.busUsersViews;
              const busUsers = busUsersViews?.map(busUserView => busUserView?.name).join(' & ');

              return (
                <VanContainer key={index}>
                  <VaccRecordContainer width="100%">
                    <ColorTag color={branchVehicleView?.color} />
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <RecordBody fontWeight="800" fontSize={18} noMargin width="100%">
                        {branchVehicleView?.name}
                      </RecordBody>
                      <RecordBody display="flex" width="100%" noMargin flex="unset">
                        <RecordBody fontWeight="800" fontSize={16} noMargin display="flex" flex="unset" style={{ justifyContent: 'space-between' }} gap="10px" width="100%">
                          <RecordBody flex="unset">{branchRouteView?.name}</RecordBody>

                          <RecordBody fontWeight="800" fontSize={16} noMargin flex="unset" width="auto">
                            {convertToAmPm(branchRouteView?.start_time)}
                          </RecordBody>
                        </RecordBody>
                      </RecordBody>
                    </div>
                  </VaccRecordContainer>
                  <Divider />
                  <StaffContainer>
                    <UserImageContainer>
                      {busUsersViews?.map((busUserView, index) => (
                        <UserImage key={index} src={busUserView?.profile_pic || icons?.user?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={busUserView?.name} />
                      ))}
                    </UserImageContainer>
                    <RecordBody fontWeight="800" fontSize={16} width="300" display="block" padding="0 20px">
                      {busUsers}
                    </RecordBody>
                  </StaffContainer>
                  <InfiniteList
                    paddingL={20}
                    paddingR={20}
                    list={branchVehicleRoutesView?.petsViews}
                    itemRenderer={(pet, index) => <FleetSchedulePetRecord petRecord={pet} key={index} index={index + 1} />}
                    hasMoreItems={false}
                    offset={branchVehicleRoutesView?.petsViews?.length}
                    setHasMoreItems={() => {}}
                    mobilePadding="8px 16px 0 16px"
                  />
                </VanContainer>
              );
            })}
          </Fragment>
        );
      })}
    </VansContainer>
  );
};

export default FleetScheduleList;
