export const billingCycleOptions = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY', label: 'Weekly' },
  // { value: 'BIWEEKLY', label: 'Biweekly' },
  { value: 'MONTHLY', label: 'Monthly' }
  // { value: 'YEARLY', label: 'Yearly' }
];

// export const visibilityOptions = [
//   { value: 'VISIBLE', label: 'Visible' },
//   { value: 'INVISIBLE', label: 'Invisible' },
//   { value: 'PARTIAL', label: 'Partial' }
// ];

export const statusOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'SOON', label: 'Coming soon' },
  { value: 'INACTIVE', label: 'Be right back' }
];
