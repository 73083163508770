import { useLazyQuery } from '@apollo/client';
import { SelectedFilter } from '../../components/Shared/Filters/config';
import { getFilter, getMoreOptionsValues } from '../../components/Shared/Filters/helpers';
import useFilters from '../../components/Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import { GetAllServices, GetBranchAppointmentTags, GetBranchPetRecordTags, GetBusUserProfile } from '../../queries';
import { FULL_DAYS, SUN_THREE_LETTERS } from '../../utils/dates';
import { BranchAppointmentTag } from '../Store/BranchTags/types';

enum BOOKINGS_FILTER_TYPES {
  BOOKING_TAGS = 'BOOKING_TAGS',
  BOOKING_QUICK_TAGS = 'BOOKING_QUICK_TAGS',
  BOOKING_PETS_TAGS = 'BOOKING_PETS_TAGS',
  BOOKING_BUS_USERS = 'BOOKING_BUS_USERS',
  BOOKING_PRODUCT_NAME = 'BOOKING_PRODUCT_NAME',
  BOOKING_SEARCH = 'BOOKING_SEARCH',
  BOOKING_WEEKDAY = 'BOOKING_WEEKDAY'
}
const HIDDEN_BOOKINGS_FILTERS = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: false,
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: true,
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: false,
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: false,
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: false,
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: true,
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: false
};
const BOOKINGS_FILTERS_QUERIES = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: ['branchAppointmentTag_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: ['branchAppointmentTag_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: ['appointment_branchPetRecordTag_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: ['appointment_busUserAssigned_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: ['appointment_product_name'],
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: ['appointment_search'],
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: ['appointment_timestamp_day_of_week']
} as const;

enum BOOKINGS_MORE_OPTIONS_TYPES {
  BOOKING_TAGS = 'BOOKING_TAGS',
  BOOKING_PETS_TAGS = 'BOOKING_PETS_TAGS',
  BOOKING_BUS_USERS = 'BOOKING_BUS_USERS',
  BOOKING_PRODUCT_NAME = 'BOOKING_PRODUCT_NAME',
  BOOKING_SEARCH = 'BOOKING_SEARCH',
  BOOKING_WEEKDAY = 'BOOKING_WEEKDAY'
}

const BOOKINGS_FILTER_TYPES_NAMES: Record<BOOKINGS_FILTER_TYPES, string> = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: 'Tags',
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: 'Pets Tags',
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: 'Staff Member',
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: 'Service Name',
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: 'Search',
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: 'Weekday'
};

const BOOKINGS_MORE_OPTIONS_TITLES: Record<BOOKINGS_MORE_OPTIONS_TYPES, string> = {
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS]: 'Tags',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS]: 'Pets Tags',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS]: 'Staff Member',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME]: 'Service Name',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH]: 'Search',
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY]: 'Weekday'
};

const BOOKINGS_MORE_OPTIONS_DROPDOWN_TYPES: Record<BOOKINGS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT,
  [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY]: OPTION_DROPDOWN_TYPES.MULTI_SELECT
};

const BOOKINGS_FILTER_TYPES_UNITS: Record<BOOKINGS_FILTER_TYPES, string> = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: '',
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: ''
};

const BOOKINGS_FILTER_TYPES_MORE_OPTIONS: Record<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES[]> = {
  [BOOKINGS_FILTER_TYPES.BOOKING_TAGS]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS],
  [BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS],
  [BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS],
  [BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS],
  [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME],
  [BOOKINGS_FILTER_TYPES.BOOKING_SEARCH]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH],
  [BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY]: [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY]
};

const useBookingsFilters = ({ filtersToHide = [] }: { filtersToHide?: BOOKINGS_FILTER_TYPES[] } = {}) => {
  const [getAllTags, { data: { getBranchAppointmentTags: allTags = [] } = {}, called: calledTags }] = useLazyQuery<{ getBranchAppointmentTags: BranchAppointmentTag[] }>(GetBranchAppointmentTags, {
    fetchPolicy: 'cache-and-network'
  });

  const [getAllPetsTags, { data: { branchPetRecordTagGet: allPetsTags = [] } = {}, called: calledPetsTags }] = useLazyQuery<{ branchPetRecordTagGet: BranchAppointmentTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network'
  });

  const [getBusUsers, { data: { getBusUserProfile: { Branch: { BusUsers = [] } = {} } = {} } = {}, called: calledBusUsers }] = useLazyQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  const [getAllServices, { data: { getProducts: services = [] } = {}, called: calledServices }] = useLazyQuery(GetAllServices, {
    fetchPolicy: 'cache-and-network'
  });

  const toCall = [
    { called: calledTags, get: () => getAllTags({ variables: { offset: 0, limit: 1000 } }), id: BOOKINGS_FILTER_TYPES.BOOKING_TAGS },
    { called: calledPetsTags, get: () => getAllPetsTags({ variables: { offset: 0, limit: 1000 } }), id: BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS },
    { called: calledBusUsers, get: () => getBusUsers({ variables: { offset: 0, limit: 1000 } }), id: BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS },
    { called: calledServices, get: () => getAllServices({ variables: { offset: 0, limit: 1000 } }), id: BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME }
  ];

  const BOOKINGS_MORE_OPTIONS_ITEMS: Record<BOOKINGS_MORE_OPTIONS_TYPES, { value: string | boolean | number; name: string }[] | []> = {
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS]: allTags?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS]: allPetsTags?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS]: BusUsers?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME]: services?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH]: [],
    [BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY]: SUN_THREE_LETTERS.map((_day, index) => ({ value: index, name: FULL_DAYS[index] }))
  };

  const filtersControl = useFilters<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES>({
    toCall,
    getOptions: () => {
      return {
        FILTER_TYPES: Object.keys(BOOKINGS_FILTER_TYPES)
          .filter(filter => !filtersToHide.includes(filter as BOOKINGS_FILTER_TYPES))
          .reduce((acc, filter) => {
            acc[filter] = filter;
            return acc;
          }, {} as Record<BOOKINGS_FILTER_TYPES, BOOKINGS_FILTER_TYPES>),
        MORE_OPTIONS_TYPES: BOOKINGS_MORE_OPTIONS_TYPES,
        MORE_OPTIONS_ITEMS: BOOKINGS_MORE_OPTIONS_ITEMS,
        FILTERS_QUERIES: BOOKINGS_FILTERS_QUERIES,
        HIDDEN_FILTERS: HIDDEN_BOOKINGS_FILTERS,
        FILTER_TYPES_MORE_OPTIONS: BOOKINGS_FILTER_TYPES_MORE_OPTIONS,
        FILTER_TYPES_NAMES: BOOKINGS_FILTER_TYPES_NAMES,
        FILTER_TYPES_UNITS: BOOKINGS_FILTER_TYPES_UNITS,
        MORE_OPTIONS_DROPDOWN_TYPES: BOOKINGS_MORE_OPTIONS_DROPDOWN_TYPES,
        MORE_OPTIONS_TITLES: BOOKINGS_MORE_OPTIONS_TITLES
      };
    }
  });

  return filtersControl;
};

const getBookingsVariables = ({
  defaultVariables,
  selectedFilters,
  overriddenFilters
}: {
  defaultVariables?: Record<string, any>;
  selectedFilters: SelectedFilter[];
  overriddenFilters?: Partial<Record<(typeof BOOKINGS_FILTERS_QUERIES)[keyof typeof BOOKINGS_FILTERS_QUERIES][number], any>>;
}) => {
  const getFilterValue = (filter: BOOKINGS_FILTER_TYPES) => getFilter(selectedFilters, filter);
  // booking tags
  const tagsFilter = getFilterValue(BOOKINGS_FILTER_TYPES.BOOKING_TAGS);
  const quickTagsFilter = getFilterValue(BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS);
  const tagsFilterValue = getMoreOptionsValues(tagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS, { name: true });
  const quickTagsFilterValue = getMoreOptionsValues(quickTagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS, { name: true });
  const allTags = [...new Set([...(tagsFilterValue || []), ...(quickTagsFilterValue || [])])];

  // pet tags
  const petTagsFilter = getFilterValue(BOOKINGS_FILTER_TYPES.BOOKING_PETS_TAGS);
  const petTagsFilterValue = getMoreOptionsValues(petTagsFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PETS_TAGS, { name: true });
  const allPetTags = [...new Set([...(petTagsFilterValue || [])])];

  // Bus user assigned
  const busUserAssignedFilter = getFilterValue(BOOKINGS_FILTER_TYPES.BOOKING_BUS_USERS);
  const busUserAssignedFilterValue = getMoreOptionsValues(busUserAssignedFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_BUS_USERS, { name: true });

  // Product name

  const productFilter = getFilterValue(BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME);
  const productFilterValue = getMoreOptionsValues(productFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_PRODUCT_NAME, { name: true });

  // Search
  const searchFilter = getFilterValue(BOOKINGS_FILTER_TYPES.BOOKING_SEARCH);
  const searchFilterValue = getMoreOptionsValues(searchFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH);

  // Weekday
  const weekdayFilter = getFilterValue(BOOKINGS_FILTER_TYPES.BOOKING_WEEKDAY);
  const weekdayFilterValue = getMoreOptionsValues(weekdayFilter, BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_WEEKDAY);

  const variables = {
    ...defaultVariables,
    branchAppointmentTag_name: allTags?.length ? allTags : null,
    appointment_branchPetRecordTag_name: allPetTags?.length ? allPetTags : null,
    appointment_busUserAssigned_name: busUserAssignedFilterValue?.length ? busUserAssignedFilterValue : null,
    appointment_product_name: productFilterValue?.length ? productFilterValue : null,
    appointment_search: searchFilterValue?.length ? searchFilterValue : null,
    appointment_timestamp_day_of_week: weekdayFilterValue?.length ? weekdayFilterValue : null,
    ...overriddenFilters,
    requisite_queries: Object.values(BOOKINGS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = BOOKINGS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return overriddenFiltersValues;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'true' ? BOOKINGS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(BOOKINGS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = BOOKINGS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return null;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'false' ? BOOKINGS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  return variables;
};

export { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES, HIDDEN_BOOKINGS_FILTERS, getBookingsVariables, useBookingsFilters };
