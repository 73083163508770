import { ORDER_INVOICE_STATUS } from '../../../components/OrderInvoices/types';
import { Pet } from '../../../components/Pets/types';
import { Branch, BranchCounts } from '../../../components/Profile/types';
import { getStartOfToday } from '../../../utils/dates';
import { ORDER_STATUS } from '../../Orders/types';
import { ORDER_REPEAT_STATUS, SUBSCRIPTION_COLLECTION_STATUS_TYPES } from '../../Subscriptions/types';
import { BranchAppUserTag } from '../BranchTags/types';

export type CurrentPeriod = {
  start: string;
  end: string;
};

export type BRANCH_BILLING_COMPONENTS =
  | 'BranchBillingAppUsers'
  | 'BranchBillingBookings'
  | 'BranchBillingOrders'
  | 'BranchBillingInvoices'
  | 'BranchBillingSubscriptions'
  | 'BranchBillingSubscriptionRepeats';

export type BranchAppUser = {
  id: string;
  name: string;
  is_verified: boolean;
  Orders: { id: string; total: string }[];
  appUserCounts: {
    totalOrdersCount: number;
    totalProductOrdersCount: number;
    totalProductOrdersAmount: number;
    totalAppointmentsCount: number;
    totalAppointmentsAmount: number;
    totalSubscriptionOrdersCount: number;
    totalSubscriptionOrdersAmount: number;
    totalOrdersAmount: number;
    totalAppointmentsOrdersCount: number;
  };
  BranchAppUserConnections: {
    id: string;
    BranchId: string;
    status: 'ACTIVE' | 'ARCHIVED';
  }[];
  BranchAppUserTags: BranchAppUserTag[];
  profile_pic: string;
  payment: {
    method: string;
  };
  Pets: Pet[];
  Branch: Branch;
  addresses: { line1: string; line2: string; city: string; country: string; geoLocation: { lat: number; lng: number } }[];
  postcode: string;
  email: string;
  phone_numbers: { number: string; label: 'primary' | 'emergency'; country_code: string }[];
  ChappedPets: Pet[];
};

export const defaultCounts: Branch['branchCounts'] = {
  totalProductOrdersCount: '',
  totalProductOrdersAmount: '',
  totalAppointmentsCount: '',
  totalEstimatedOrdersAmount: '',
  totalEstimatedSubscriptionOrderRepeatsAmount: '',
  totalRefundedSubscriptionOrderRepeatsAmount: '',
  totalAppointmentsAmount: '',
  totalAppointmentsOrdersCount: '',
  totalSubscriptionOrdersCount: '',
  totalSubscriptionOrdersAmount: '',
  totalOrdersCount: '',
  totalOrdersAmount: '',
  totalProductOrdersAppUsersCount: '',
  totalAppointmentsAppUsersCount: '',
  totalSubscriptionOrdersAppUsersCount: '',
  totalActiveSubscriptionOrderRepeatsAmount: '',
  totalBilledSubscriptionOrderRepeatsAmount: '',
  totalPendingSubscriptionOrderRepeatsAmount: '',
  totalRejectedSubscriptionOrderRepeatsAmount: '',
  totalUnbilledOrderSubscriptionRepeatsAmount: '',
  allTotalUnbilledOrderSubscriptionRepeatsAmount: '',
  allTotalUnbilledOrderSubscriptionRepeatsCount: '',
  totalUnbilledOrderSubscriptionRepeatsCount: '',
  totalOrderSubscriptionRepeatsAmount: '',
  totalBilledOrderSubscriptionRepeatsAmount: '',
  totalPendingOrderSubscriptionRepeatsAmount: '',
  totalRejectedOrderSubscriptionRepeatsAmount: '',
  totalActiveSubscriptionOrdersAmount: '',
  totalActiveSubscriptionOrdersCount: '',
  totalSubscriptionOrderRepeatsAmount: '',
  totalAppUsersCount: '',
  totalOrderInvoicesCount: '',
  totalOrderInvoicesAmount: '',
  totalBilledAppointmentsAmount: '',
  totalPendingAppointmentsAmount: '',
  totalRejectedAppointmentsAmount: '',
  totalUpcomingAppointmentsAmount: '',
  totalBilledAppointmentsCount: '',
  totalPendingAppointmentsCount: '',
  totalRejectedAppointmentsCount: '',
  totalUpcomingAppointmentsCount: '',
  totalBilledOrderInvoicesAmount: '',
  totalPendingOrderInvoicesAmount: '',
  totalRejectedOrderInvoicesAmount: '',
  totalUnbilledOrderInvoicesAmount: '',
  totalPaymentAppointmentsCount: '',
  totalPaymentAppointmentsAmount: ''
};

export type ProviderCountsOutput = {
  getProviderBranchesCounts: {
    providerCounts: Branch['branchCounts'];
    branches: {
      Branch: Branch;
      counts: Branch['branchCounts'];
    }[];
  };
};

export enum BRANCH_BILLING_FILTERS {
  MAIN_BOOKINGS = 'MAIN_BOOKINGS',
  MAIN_INVOICES = 'MAIN_INVOICES',
  MAIN_SUBSCRIPTIONS = 'MAIN_SUBSCRIPTIONS',
  MAIN_ORDERS = 'MAIN_ORDERS',
  MAIN_ORDERS_TOTAL = 'MAIN_ORDERS_TOTAL',
  MAIN_UNBILLED_SUBSCRIPTION_INVOICES = 'MAIN_UNBILLED_SUBSCRIPTION_INVOICES',
  MAIN_UNBILLED_BOOKINGS = 'MAIN_UNBILLED_BOOKINGS',

  APP_USERS = 'APP_USERS',
  BOOKINGS = 'BOOKINGS',
  ORDERS = 'ORDERS',
  ORDERS_APP_USERS = 'ORDERS_APP_USERS',
  ORDERS_TOTAL = 'ORDERS_TOTAL',
  INVOICES = 'INVOICES',
  INVOICES_BILLED = 'INVOICES_BILLED',
  INVOICES_PENDING_REJECTED = 'INVOICES_PENDING_REJECTED',
  INVOICES_UNBILLED = 'INVOICES_UNBILLED',
  INVOICES_ORDER_TOTAL = 'INVOICES_ORDER_TOTAL',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  SUBSCRIPTIONS_APP_USERS = 'SUBSCRIPTIONS_APP_USERS',
  SUBSCRIPTIONS_ISSUED_INVOICES = 'SUBSCRIPTIONS_ISSUED_INVOICES',
  SUBSCRIPTIONS_BILLED = 'SUBSCRIPTIONS_BILLED',
  SUBSCRIPTIONS_UNBILLED = 'SUBSCRIPTIONS_UNBILLED',
  SUBSCRIPTIONS_ACTIVE = 'SUBSCRIPTIONS_ACTIVE',
  SUBSCRIPTIONS_ORDER_TOTAL = 'SUBSCRIPTIONS_ORDER_TOTAL',
  // SUBSCRIPTION_INVOICES = 'SUBSCRIPTION_INVOICES',
  SUBSCRIPTION_INVOICES_BILLED = 'SUBSCRIPTION_INVOICES_BILLED',
  SUBSCRIPTION_INVOICES_PENDING_REJECTED = 'SUBSCRIPTION_INVOICES_PENDING_REJECTED',
  SUBSCRIPTION_INVOICES_REFUNDED = 'SUBSCRIPTION_INVOICES_REFUNDED',

  BOOKINGS_CONFIRMED = 'BOOKINGS_CONFIRMED',
  BOOKINGS_APP_USERS = 'BOOKINGS_APP_USERS',
  BOOKINGS_BILLED = 'BOOKINGS_BILLED',
  BOOKINGS_PENDING_REJECTED = 'BOOKINGS_PENDING_REJECTED',
  BOOKINGS_UPCOMING = 'BOOKINGS_UPCOMING',
  BOOKINGS_TOTAL = 'BOOKINGS_TOTAL',

  SUBSCRIPTION_INVOICES_UNBILLED_ALL = 'SUBSCRIPTION_INVOICES_UNBILLED_ALL',
  BOOKINGS_UNBILLED_ALL = 'BOOKINGS_UNBILLED_ALL'
}

export const BRANCH_BILLING_FILTERS_LABELS: Record<BRANCH_BILLING_FILTERS, string> = {
  [BRANCH_BILLING_FILTERS.MAIN_BOOKINGS]: 'Services',
  [BRANCH_BILLING_FILTERS.MAIN_INVOICES]: 'Invoices',
  [BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS]: 'Memberships',
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS]: 'Products',
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS_TOTAL]: 'Estimated billing',
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_SUBSCRIPTION_INVOICES]: 'Memberships',
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_BOOKINGS]: 'Services',

  [BRANCH_BILLING_FILTERS.APP_USERS]: 'Clients',
  [BRANCH_BILLING_FILTERS.BOOKINGS]: 'Confirmed bookings',
  [BRANCH_BILLING_FILTERS.ORDERS]: 'Products',
  [BRANCH_BILLING_FILTERS.ORDERS_APP_USERS]: 'Clients',
  [BRANCH_BILLING_FILTERS.INVOICES]: 'Invoices',
  [BRANCH_BILLING_FILTERS.INVOICES_BILLED]: 'Paid',
  [BRANCH_BILLING_FILTERS.INVOICES_PENDING_REJECTED]: 'Pending/Rejected',
  [BRANCH_BILLING_FILTERS.INVOICES_UNBILLED]: 'Unpaid',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS]: 'Memberships',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_APP_USERS]: 'Clients',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: 'Issued Invoices',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_BILLED]: 'Paid',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_UNBILLED]: 'Unpaid',
  // [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES]: 'Memberships Invoices',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: 'Paid',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: 'Unpaid',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_REFUNDED]: 'Refunded',
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: 'Estimated billing',
  [BRANCH_BILLING_FILTERS.INVOICES_ORDER_TOTAL]: 'Total Invoices',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: 'Total Value',

  [BRANCH_BILLING_FILTERS.BOOKINGS_CONFIRMED]: 'Confirmed bookings',
  [BRANCH_BILLING_FILTERS.BOOKINGS_APP_USERS]: 'Clients',
  [BRANCH_BILLING_FILTERS.BOOKINGS_BILLED]: 'Paid',
  [BRANCH_BILLING_FILTERS.BOOKINGS_PENDING_REJECTED]: 'Pending/Rejected',
  [BRANCH_BILLING_FILTERS.BOOKINGS_UPCOMING]: 'Upcoming',
  [BRANCH_BILLING_FILTERS.BOOKINGS_TOTAL]: 'Estimated total',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ACTIVE]: 'Period Memberships',

  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: 'All Unpaid Memberships',
  [BRANCH_BILLING_FILTERS.BOOKINGS_UNBILLED_ALL]: 'All Unpaid Bookings'
} as const;

export const BRANCH_BILLING_FILTERS_TOTALS: Record<BRANCH_BILLING_FILTERS, (keyof BranchCounts)[]> = {
  [BRANCH_BILLING_FILTERS.APP_USERS]: ['totalAppUsersCount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS]: ['totalAppointmentsAmount'],
  [BRANCH_BILLING_FILTERS.ORDERS]: ['totalProductOrdersAmount'],
  [BRANCH_BILLING_FILTERS.ORDERS_APP_USERS]: ['totalProductOrdersAppUsersCount'],
  [BRANCH_BILLING_FILTERS.INVOICES]: ['totalOrderInvoicesCount'],
  [BRANCH_BILLING_FILTERS.INVOICES_BILLED]: ['totalBilledOrderInvoicesAmount'],
  [BRANCH_BILLING_FILTERS.INVOICES_PENDING_REJECTED]: ['totalPendingOrderInvoicesAmount', 'totalRejectedOrderInvoicesAmount'],
  [BRANCH_BILLING_FILTERS.INVOICES_UNBILLED]: ['totalUnbilledOrderInvoicesAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS]: ['totalSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_APP_USERS]: ['totalSubscriptionOrdersAppUsersCount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: ['totalSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_BILLED]: ['totalBilledSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_UNBILLED]: ['totalPendingSubscriptionOrderRepeatsAmount', 'totalRejectedSubscriptionOrderRepeatsAmount'],
  // [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES]: ['totalOrderSubscriptionRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: ['totalBilledSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: ['totalPendingSubscriptionOrderRepeatsAmount', 'totalRejectedSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_REFUNDED]: ['totalRefundedSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: ['totalEstimatedOrdersAmount'],
  [BRANCH_BILLING_FILTERS.INVOICES_ORDER_TOTAL]: ['totalOrderInvoicesAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: ['totalEstimatedSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.MAIN_BOOKINGS]: ['totalAppointmentsAmount'],
  [BRANCH_BILLING_FILTERS.MAIN_INVOICES]: ['totalOrderInvoicesAmount'],
  [BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS]: ['totalEstimatedSubscriptionOrderRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS]: ['totalProductOrdersAmount'],
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS_TOTAL]: ['totalEstimatedOrdersAmount'],

  [BRANCH_BILLING_FILTERS.BOOKINGS_CONFIRMED]: ['totalAppointmentsAmount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_APP_USERS]: ['totalAppointmentsAppUsersCount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_BILLED]: ['totalBilledAppointmentsAmount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_PENDING_REJECTED]: ['totalPendingAppointmentsAmount', 'totalRejectedAppointmentsAmount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_UPCOMING]: ['totalUpcomingAppointmentsAmount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_TOTAL]: ['totalAppointmentsAmount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ACTIVE]: ['totalActiveSubscriptionOrdersCount'],
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_SUBSCRIPTION_INVOICES]: ['allTotalUnbilledOrderSubscriptionRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_BOOKINGS]: ['totalPaymentAppointmentsAmount'],

  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: ['allTotalUnbilledOrderSubscriptionRepeatsAmount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_UNBILLED_ALL]: ['totalPaymentAppointmentsAmount']
};

export const BRANCH_BILLING_FILTERS_SECONDARY_TOTALS: Record<BRANCH_BILLING_FILTERS, (keyof BranchCounts)[]> = {
  [BRANCH_BILLING_FILTERS.APP_USERS]: [],
  [BRANCH_BILLING_FILTERS.INVOICES]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: ['totalSubscriptionOrdersCount'],
  [BRANCH_BILLING_FILTERS.INVOICES_BILLED]: [],
  [BRANCH_BILLING_FILTERS.INVOICES_PENDING_REJECTED]: [],
  [BRANCH_BILLING_FILTERS.INVOICES_UNBILLED]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_BILLED]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_UNBILLED]: [],
  // [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_REFUNDED]: [],
  [BRANCH_BILLING_FILTERS.BOOKINGS]: ['totalAppointmentsOrdersCount'],
  [BRANCH_BILLING_FILTERS.ORDERS]: ['totalProductOrdersCount'],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS]: ['totalSubscriptionOrdersCount'],
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: [],
  [BRANCH_BILLING_FILTERS.INVOICES_ORDER_TOTAL]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: ['allTotalUnbilledOrderSubscriptionRepeatsCount'],
  [BRANCH_BILLING_FILTERS.MAIN_BOOKINGS]: ['totalAppointmentsOrdersCount'],
  [BRANCH_BILLING_FILTERS.MAIN_INVOICES]: ['totalOrderInvoicesCount'],
  [BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_APP_USERS]: [],
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS]: ['totalProductOrdersCount'],
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS_TOTAL]: [],

  [BRANCH_BILLING_FILTERS.BOOKINGS_CONFIRMED]: ['totalAppointmentsOrdersCount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_APP_USERS]: [],
  [BRANCH_BILLING_FILTERS.BOOKINGS_BILLED]: ['totalBilledAppointmentsCount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_PENDING_REJECTED]: ['totalPendingAppointmentsCount', 'totalRejectedAppointmentsCount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_UPCOMING]: ['totalUpcomingAppointmentsCount'],
  [BRANCH_BILLING_FILTERS.BOOKINGS_TOTAL]: ['totalAppointmentsOrdersCount'],
  [BRANCH_BILLING_FILTERS.ORDERS_APP_USERS]: [],
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ACTIVE]: [],
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_SUBSCRIPTION_INVOICES]: [],
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_BOOKINGS]: [],
  [BRANCH_BILLING_FILTERS.BOOKINGS_UNBILLED_ALL]: ['totalPaymentAppointmentsCount']
};

export const BRANCH_BILLING_FILTERS_FILTERABLE: Record<BRANCH_BILLING_FILTERS, boolean> = {
  [BRANCH_BILLING_FILTERS.APP_USERS]: true,
  [BRANCH_BILLING_FILTERS.BOOKINGS]: true,
  [BRANCH_BILLING_FILTERS.ORDERS]: true,
  [BRANCH_BILLING_FILTERS.ORDERS_APP_USERS]: true,
  [BRANCH_BILLING_FILTERS.INVOICES]: true,
  [BRANCH_BILLING_FILTERS.INVOICES_BILLED]: true,
  [BRANCH_BILLING_FILTERS.INVOICES_PENDING_REJECTED]: true,
  [BRANCH_BILLING_FILTERS.INVOICES_UNBILLED]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_APP_USERS]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_BILLED]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_UNBILLED]: true,
  // [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_REFUNDED]: true,
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: false,
  [BRANCH_BILLING_FILTERS.INVOICES_ORDER_TOTAL]: false,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: false,
  [BRANCH_BILLING_FILTERS.MAIN_BOOKINGS]: false,
  [BRANCH_BILLING_FILTERS.MAIN_INVOICES]: false,
  [BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS]: false,
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS]: false,
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS_TOTAL]: false,

  [BRANCH_BILLING_FILTERS.BOOKINGS_CONFIRMED]: true,
  [BRANCH_BILLING_FILTERS.BOOKINGS_APP_USERS]: true,
  [BRANCH_BILLING_FILTERS.BOOKINGS_BILLED]: true,
  [BRANCH_BILLING_FILTERS.BOOKINGS_PENDING_REJECTED]: true,
  [BRANCH_BILLING_FILTERS.BOOKINGS_UPCOMING]: true,
  [BRANCH_BILLING_FILTERS.BOOKINGS_TOTAL]: false,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ACTIVE]: true,
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_SUBSCRIPTION_INVOICES]: false,
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_BOOKINGS]: false,
  [BRANCH_BILLING_FILTERS.BOOKINGS_UNBILLED_ALL]: true,
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: true
};

export const BRANCH_BILLING_FILTERS_ITEMS: (currentPeriod: CurrentPeriod) => Record<BRANCH_BILLING_FILTERS, Record<string, any>> = currentPeriod => ({
  [BRANCH_BILLING_FILTERS.APP_USERS]: {},
  [BRANCH_BILLING_FILTERS.BOOKINGS]: {},
  [BRANCH_BILLING_FILTERS.ORDERS]: {},
  [BRANCH_BILLING_FILTERS.ORDERS_APP_USERS]: { order_product_type: ['product'] },
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: {},
  [BRANCH_BILLING_FILTERS.INVOICES]: {},
  [BRANCH_BILLING_FILTERS.INVOICES_BILLED]: { status: [ORDER_INVOICE_STATUS.PAYMENT_SUCCEEDED] },
  [BRANCH_BILLING_FILTERS.INVOICES_PENDING_REJECTED]: { status: [ORDER_INVOICE_STATUS.PAYMENT_PENDING, ORDER_INVOICE_STATUS.PAYMENT_REJECTED] },
  [BRANCH_BILLING_FILTERS.INVOICES_UNBILLED]: { unbilled: true },
  [BRANCH_BILLING_FILTERS.INVOICES_ORDER_TOTAL]: {},
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS]: {},
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_APP_USERS]: { order_product_type: ['subscription'] },
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: {
    status: [
      ORDER_REPEAT_STATUS.CONFIRMED,
      ORDER_REPEAT_STATUS.PAYMENT_PENDING,
      ORDER_REPEAT_STATUS.PAYMENT_REJECTED,
      ORDER_REPEAT_STATUS.PAYMENT_SUCCEEDED,
      ORDER_REPEAT_STATUS.REFUNDED,
      ORDER_REPEAT_STATUS.PARTIALLY_REFUNDED
    ]
  },
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_BILLED]: {
    OrderSubscriptionRepeat_status: [ORDER_REPEAT_STATUS.PAYMENT_SUCCEEDED]
  },
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_UNBILLED]: { OrderSubscriptionRepeat_status: [ORDER_REPEAT_STATUS.PAYMENT_PENDING, ORDER_REPEAT_STATUS.PAYMENT_PENDING, ORDER_REPEAT_STATUS.PAYMENT_REJECTED] },

  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: {},
  // [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES]: {},
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: { status: [ORDER_REPEAT_STATUS.PAYMENT_SUCCEEDED] },
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: { status: [ORDER_REPEAT_STATUS.PAYMENT_PENDING, ORDER_REPEAT_STATUS.PAYMENT_REJECTED] },
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_REFUNDED]: { status: [ORDER_REPEAT_STATUS.REFUNDED] },
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: {
    status: [ORDER_REPEAT_STATUS.PAYMENT_PENDING, ORDER_REPEAT_STATUS.PAYMENT_REJECTED],
    payment_invoice_date_from: undefined,
    payment_invoice_date_to: undefined
  },
  [BRANCH_BILLING_FILTERS.BOOKINGS_CONFIRMED]: {},
  [BRANCH_BILLING_FILTERS.BOOKINGS_APP_USERS]: { order_product_type: ['service'] },
  [BRANCH_BILLING_FILTERS.BOOKINGS_BILLED]: { order_status: [ORDER_STATUS.PAYMENT_SUCCEEDED] },
  [BRANCH_BILLING_FILTERS.BOOKINGS_PENDING_REJECTED]: { order_status: [ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED] },
  BOOKINGS_UPCOMING: { order_status: [ORDER_STATUS.REQUESTED, ORDER_STATUS.CONFIRMED] },
  [BRANCH_BILLING_FILTERS.BOOKINGS_TOTAL]: {},
  [BRANCH_BILLING_FILTERS.MAIN_BOOKINGS]: {},
  [BRANCH_BILLING_FILTERS.MAIN_INVOICES]: {},
  [BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS]: {},
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS]: {},
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS_TOTAL]: {},
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ACTIVE]: {
    status: [ORDER_STATUS.CONFIRMED],
    collectionStatus: [SUBSCRIPTION_COLLECTION_STATUS_TYPES.ACTIVE],
    subscription_optional_trial_end_to: currentPeriod.end,
    requisite_queries: ['subscription_optional_trial_end_to'],
    OrderSubscriptionRepeat_payment_invoice_date_from: undefined,
    OrderSubscriptionRepeat_payment_invoice_date_to: undefined
  },
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_SUBSCRIPTION_INVOICES]: {},
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_BOOKINGS]: {},
  [BRANCH_BILLING_FILTERS.BOOKINGS_UNBILLED_ALL]: {
    order_status: [ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED],
    timestamp_from: undefined,
    timestamp_to: undefined
  }
});

export const BRANCH_BILLING_FILTER_COMPONENT: Record<BRANCH_BILLING_FILTERS, BRANCH_BILLING_COMPONENTS> = {
  [BRANCH_BILLING_FILTERS.APP_USERS]: 'BranchBillingAppUsers',
  [BRANCH_BILLING_FILTERS.BOOKINGS]: 'BranchBillingBookings',
  [BRANCH_BILLING_FILTERS.ORDERS]: 'BranchBillingOrders',
  [BRANCH_BILLING_FILTERS.ORDERS_APP_USERS]: 'BranchBillingAppUsers',
  [BRANCH_BILLING_FILTERS.INVOICES]: 'BranchBillingInvoices',
  [BRANCH_BILLING_FILTERS.INVOICES_BILLED]: 'BranchBillingInvoices',
  [BRANCH_BILLING_FILTERS.INVOICES_PENDING_REJECTED]: 'BranchBillingInvoices',
  [BRANCH_BILLING_FILTERS.INVOICES_UNBILLED]: 'BranchBillingInvoices',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS]: 'BranchBillingSubscriptions',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_APP_USERS]: 'BranchBillingAppUsers',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES]: 'BranchBillingSubscriptionRepeats',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_BILLED]: 'BranchBillingSubscriptions',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_UNBILLED]: 'BranchBillingSubscriptions',
  // [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES]: 'BranchBillingSubscriptionRepeats',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_BILLED]: 'BranchBillingSubscriptionRepeats',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED]: 'BranchBillingSubscriptionRepeats',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_REFUNDED]: 'BranchBillingSubscriptionRepeats',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL]: 'BranchBillingSubscriptionRepeats',
  [BRANCH_BILLING_FILTERS.ORDERS_TOTAL]: 'BranchBillingOrders',
  [BRANCH_BILLING_FILTERS.INVOICES_ORDER_TOTAL]: 'BranchBillingInvoices',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]: 'BranchBillingSubscriptions',
  [BRANCH_BILLING_FILTERS.MAIN_BOOKINGS]: 'BranchBillingBookings',
  [BRANCH_BILLING_FILTERS.MAIN_INVOICES]: 'BranchBillingInvoices',
  [BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS]: 'BranchBillingSubscriptions',
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS]: 'BranchBillingOrders',

  [BRANCH_BILLING_FILTERS.BOOKINGS_CONFIRMED]: 'BranchBillingBookings',
  [BRANCH_BILLING_FILTERS.MAIN_ORDERS_TOTAL]: 'BranchBillingOrders',
  [BRANCH_BILLING_FILTERS.BOOKINGS_APP_USERS]: 'BranchBillingAppUsers',
  [BRANCH_BILLING_FILTERS.BOOKINGS_BILLED]: 'BranchBillingBookings',
  [BRANCH_BILLING_FILTERS.BOOKINGS_PENDING_REJECTED]: 'BranchBillingBookings',
  [BRANCH_BILLING_FILTERS.BOOKINGS_UPCOMING]: 'BranchBillingBookings',
  [BRANCH_BILLING_FILTERS.BOOKINGS_TOTAL]: 'BranchBillingBookings',
  [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ACTIVE]: 'BranchBillingSubscriptions',
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_SUBSCRIPTION_INVOICES]: 'BranchBillingSubscriptionRepeats',
  [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_BOOKINGS]: 'BranchBillingBookings',
  [BRANCH_BILLING_FILTERS.BOOKINGS_UNBILLED_ALL]: 'BranchBillingBookings'
};
