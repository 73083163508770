import { Branch } from '../../components/Profile/types';
import { Note } from '../../components/Shared/BranchNotes/types';
import { Booking } from '../Bookings/types';
import { BranchAppUser } from '../Store/BranchBilling/types';
import { SubmittedForm } from '../Store/BranchForms/types';

export type ProductOrder = {
  id: string;
  name: string;
  number: string;
  price: number;
  quantity: number;
  total: number;
  credit_used: number;
  AppUser: BranchAppUser;
  Products: { id: string; name: string; price: number; quantity: number; total: number }[];
  Notes: Note[];
  delivery: {
    method: {
      id: string;
      name: string;
      price: number;
      description: string;
    };
  };
  status: ORDER_STATUS;
  createdAt: string;
  billed_on: string;
  payment: {
    fail_reason: string;
  };
  OrderBills: {
    id: string;
    status: string;
    billed_on: string;
    OrderInvoice: {
      id: string;
      status: string;
      Order: ProductOrder;
      billed_on: string;
    };
  }[];
  OrderItems: {
    id: string;
    Appointments: Booking[];
    item: ProductOrder;
    quantity: number;
    customizations: { fields: Record<string, { title: string; price: number }>; quantity: number }[];
  }[];
  SubmittedForms: SubmittedForm[];
  Branch: Branch;
  Refunds: {
    id: string;
    amount: string;
    reason: string;
    note: string;
  }[];
  payment: {
    status: string;
    fail_reason?: string;
  };
};

export enum ORDER_STATUS {
  REQUESTED = 'REQUESTED',
  CONFIRMED = 'CONFIRMED',
  DISCOUNT_APPLIED = 'DISCOUNT_APPLIED',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED'
}

export const ORDER_STATUS_TYPES = {
  NEW: ['REQUESTED', 'DISCOUNT_APPLIED', 'PAYMENT_PENDING', 'PAYMENT_SUCCEEDED', 'PAYMENT_REJECTED'],
  ACTIVE: ['CONFIRMED', 'PARTIALLY_REFUNDED'],
  ARCHIVED: ['OUT_FOR_DELIVERY', 'CANCELED', 'REFUNDED']
};

export enum ORDERS_TABS_TYPES {
  NEW = 'New',
  UPCOMING = 'Upcoming',
  FULFILLED = 'Fulfilled'
}

export const ORDER_STATUS_TITLES = {
  REQUESTED: 'Requested',
  DISCOUNT_APPLIED: 'Discount Applied',
  PAYMENT_PENDING: 'Pending',
  PAYMENT_SUCCEEDED: 'Paid',
  PAYMENT_REJECTED: 'Rejected',
  CONFIRMED: 'Confirmed',
  OUT_FOR_DELIVERY: 'Delivery',
  CANCELED: 'Cancelled',
  REFUNDED: 'Refunded'
};

export const ORDER_SUBSCRIPTION_REPEAT_STATUS_TITLES = {
  REQUESTED: 'Draft',
  PAYMENT_PENDING: 'Pending',
  PAYMENT_SUCCEEDED: 'Paid',
  PAYMENT_REJECTED: 'Rejected',
  CONFIRMED: 'Confirmed',
  CANCELED: 'Cancelled',
  REFUNDED: 'Refunded',
  PARTIALLY_REFUNDED: 'Partially Refunded'
};

export const ORDER_STATUS_TITLES_COLORS = {
  REQUESTED: 'blue',
  DISCOUNT_APPLIED: 'blue',
  PAYMENT_PENDING: 'blue',
  PAYMENT_SUCCEEDED: 'green',
  PAYMENT_REJECTED: 'red',
  CONFIRMED: 'green',
  OUT_FOR_DELIVERY: 'green',
  CANCELED: 'red',
  REFUNDED: 'red',
  PARTIALLY_REFUNDED: 'red'
};
