import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import { BusUserProfile } from '../Profile/types';
import React, { FC, useEffect } from 'react';
import ModalDialog, { ModalLayout } from '../Modal/ModalDialog';
import { FormLabel, FormSubmitButton, InputGroup } from '../Shared/Forms/Forms';
import Select from '../Shared/Forms/Select';
import { AddBranchChatRoom, GetBusUserProfile } from '../../queries';
import { useMutation, useQuery } from '@apollo/client';
import { Room } from './ChatRecord';

type BranchChatRoomModalForm = {
  busUserId: BusUserProfile[];
};

interface BranchChatRoomModalFormProps {
  busUsers: BusUserProfile[];
  formOptions: UseFormMethods<BranchChatRoomModalForm>;
  loadingAdd: boolean;
  onSubmit: () => void;
  defaultValues?: Partial<BranchChatRoomModalForm>;
}

const BranchChatRoomModalForm: FC<BranchChatRoomModalFormProps> = ({ busUsers, formOptions, loadingAdd, onSubmit, defaultValues }) => {
  const { control } = formOptions;

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton loading={loadingAdd} onClick={onSubmit}>
          Create Room
        </FormSubmitButton>
      ]}
    >
      <InputGroup>
        <FormLabel>Staff Members</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select onChange={onChange} isMulti value={value} options={busUsers} getOptionValue={busUser => busUser?.id} getOptionLabel={busUser => busUser?.name} name={'busUserId'} />
          )}
          control={control}
          name={'busUserId'}
          defaultValue={defaultValues?.busUserId || []}
        />
      </InputGroup>
    </ModalLayout>
  );
};

export const BranchChatRoomModal = ({ chatRoom }: { chatRoom?: Room }) => {
  const defaultValues: BranchChatRoomModalForm = {
    busUserId: chatRoom?.BusUsers || []
  };

  const formOptions = useForm<BranchChatRoomModalForm>({ defaultValues });

  const { handleSubmit } = formOptions;

  const { data: { getBusUserProfile: { Branch: { BusUsers = [] } = {} } = {} } = {} } = useQuery<{
    getBusUserProfile: BusUserProfile;
  }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const [addBranchChatRoom, { loading: loadingAdd, data: addedRoom }] = useMutation(AddBranchChatRoom, {
    refetchQueries: ['GetBranchChatRooms']
  });

  const onSubmit = handleSubmit(({ busUserId }) => {
    addBranchChatRoom({
      variables: {
        BusUserId: busUserId?.map(item => item.id) || null,
        is_bus_room: true
      }
    });
  });

  useEffect(() => {
    if (addedRoom?.addBranchChatRoom?.id) {
      ModalDialog.closeModal();
    }
  }, [addedRoom?.addBranchChatRoom?.id]);

  return <BranchChatRoomModalForm busUsers={BusUsers} formOptions={formOptions} loadingAdd={loadingAdd} onSubmit={onSubmit} defaultValues={defaultValues} />;
};

export default BranchChatRoomModal;
