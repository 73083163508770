import styled from 'styled-components';
import Colors from '../../../../Colors';
export const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 0 20px;
  height: 64px;
  color: ${Colors.black};
  font-size: 18px;
  font-weight: 800;
  flex-shrink: 0;
`;

export const ViewSwitchContainer = styled.div`
  margin-left: auto;
`;
