import React, { FC, useLayoutEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormCheckbox, WideInputGroup } from '../../components/Shared/Forms/Forms';
import { BOOKING_TYPE } from '../Bookings/types';
import { StoreProductModalForm } from './Operations/StoreProductModal/types';
import { CHARGE_STATUS, Product } from './types';

type ProductOptionsProps = {
  formOptions: UseFormMethods<StoreProductModalForm>;
  type: Product['type'];
  defaultValues?: Partial<StoreProductModalForm['productOptions']>;
  optionsToShow?: ProductOptionsTypes[];
};

export enum ProductOptionsTypes {
  FROM = 'from',
  AUTOMATICALLY_PICK_BEST_DISCOUNT = 'automatically_pick_best_discount',
  USE_PETS_COUNT = 'use_pets_count',
  AUTO_CONFIRM_ORDER = 'auto_confirm_order',
  AUTO_CONFIRM_APPOINTMENTS = 'auto_confirm_appointments',
  ALLOWS_REPEATED_ORDERS = 'allows_repeated_orders',
  ALLOWS_BULK_ORDERS = 'allows_bulk_orders',
  ALLOWS_PAST_SLOTS = 'allows_past_slots',
  OPTIONAL_CARD_DETAILS = 'optional_card_details',
  USER_CAN_CANCEL = 'user_can_cancel',
  USER_CAN_RESCHEDULE = 'user_can_reschedule',
  ENABLE_CUSTOMIZATIONS_QUANTITY_FACTOR = 'enable_customizations_quantity_factor',
  SHOW_CUSTOMIZATIONS_PRICE = 'show_customizations_price',
  FUTURE_DELIVERY = 'future_delivery'
}

export const getSelectedProductOptions = (values: any[]) => {
  const vals = values || [];
  const selectedOptions = Object.values(ProductOptionsTypes).reduce((acc, option) => {
    acc[option] = vals.includes(option);
    return acc;
  }, {} as Record<ProductOptionsTypes, boolean>);

  return selectedOptions;
};

export const getProductOptionsDefaultValue = ({ product, hasFromFlag }: { product?: Product; hasFromFlag: boolean }) => {
  const defaultValue = [
    hasFromFlag && ProductOptionsTypes.FROM,
    !!product?.automatically_pick_best_discount && ProductOptionsTypes.AUTOMATICALLY_PICK_BEST_DISCOUNT,
    !!product?.use_pets_count && ProductOptionsTypes.USE_PETS_COUNT,
    !!product?.auto_confirm_order && ProductOptionsTypes.AUTO_CONFIRM_ORDER,
    !!product?.auto_confirm_appointments && ProductOptionsTypes.AUTO_CONFIRM_APPOINTMENTS,
    !!product?.allows_repeated_orders && ProductOptionsTypes.ALLOWS_REPEATED_ORDERS,
    !!product?.allows_bulk_orders && ProductOptionsTypes.ALLOWS_BULK_ORDERS,
    !!product?.payment?.method_optional && ProductOptionsTypes.OPTIONAL_CARD_DETAILS,
    !!product?.allows_past_slots && ProductOptionsTypes.ALLOWS_PAST_SLOTS,
    !!product?.user_can_cancel && ProductOptionsTypes.USER_CAN_CANCEL,
    !!product?.user_can_reschedule && ProductOptionsTypes.USER_CAN_RESCHEDULE,
    !!product?.enable_customizations_quantity_factor && ProductOptionsTypes.ENABLE_CUSTOMIZATIONS_QUANTITY_FACTOR,
    !!product?.show_customizations_price && ProductOptionsTypes.SHOW_CUSTOMIZATIONS_PRICE,
    !!product?.future_delivery && ProductOptionsTypes.FUTURE_DELIVERY
  ].filter(Boolean) as ProductOptionsTypes[];

  return defaultValue;
};

const ProductOptions: FC<ProductOptionsProps> = ({ formOptions, defaultValues, type, optionsToShow }) => {
  const { control, setValue, errors, watch } = formOptions;
  const serviceType = type === 'service';
  const subscriptionType = type === 'subscription';

  const watchedChargeStatus = watch('charge_status');
  const booking_type = watch('booking_type');
  const watchedProductOptions = watch('productOptions');

  const isChargeLater = watchedChargeStatus === CHARGE_STATUS.CHARGE_LATER;
  const isNoCharge = watchedChargeStatus === CHARGE_STATUS.NO_CHARGE;

  const isMultiday = booking_type === BOOKING_TYPE.MULTI_DAY;
  const isMultiSlot = booking_type === BOOKING_TYPE.MULTI_SLOT;

  const isRepeated = watchedProductOptions.includes(ProductOptionsTypes.ALLOWS_REPEATED_ORDERS);
  const isMultiOrder = watchedProductOptions.includes(ProductOptionsTypes.ALLOWS_BULK_ORDERS);

  const items = [
    {
      id: ProductOptionsTypes.FROM,
      name: 'Show “From” next  to the base price',
      description: 'Will show the label “from” next to the base price. Recommended when price variations exist'
    },
    (serviceType || subscriptionType) && {
      id: ProductOptionsTypes.AUTOMATICALLY_PICK_BEST_DISCOUNT,
      name: 'Auto-apply discounts and vouchers',
      description: 'Collar will automatically apply discounts and vouchers without the need for a voucher code'
    },
    (serviceType || subscriptionType) && {
      id: ProductOptionsTypes.USE_PETS_COUNT,
      name: 'Calculate pricing per pet',
      description: 'When checked, the total price will be multiplied by the number of pets'
    },
    serviceType &&
      !isRepeated &&
      !isMultiOrder && {
        id: ProductOptionsTypes.AUTO_CONFIRM_ORDER,
        name: 'Require pre-payment',
        description: 'Payment will be charged automatically prior to booking request'
      },
    serviceType && {
      id: ProductOptionsTypes.AUTO_CONFIRM_APPOINTMENTS,
      name: 'Auto-confirm booking requests',
      description: 'Collar will automatically confirm booking requests without the need for manual approval'
    },
    serviceType &&
      !isMultiday &&
      !isMultiSlot && {
        id: ProductOptionsTypes.ALLOWS_REPEATED_ORDERS,
        name: 'Allow Repeated Bookings for Users',
        description: 'Allow users to request recurring bookings of set days (Ex. Mondays & Wednesdays every week)'
      },
    serviceType &&
      !isMultiday &&
      !isMultiSlot && {
        id: ProductOptionsTypes.ALLOWS_BULK_ORDERS,
        name: 'Allow Multiple Bookings',
        description: 'Allow users to book multiple ad-hoc dates'
      },
    serviceType &&
      (isNoCharge || isChargeLater) && {
        id: ProductOptionsTypes.OPTIONAL_CARD_DETAILS,
        name: 'Do not request card details',
        description: 'Allow booking request without any card details'
      },
    serviceType && {
      id: ProductOptionsTypes.ALLOWS_PAST_SLOTS,
      name: 'Allow Bookings in the Past',
      description: 'Businesses will be able to book this service for users on dates in the past'
    },
    serviceType && {
      id: ProductOptionsTypes.USER_CAN_CANCEL,
      name: 'Allow Users to Cancel Booking',
      description: 'Will allow users to cancel the booking outside the no-cancellation window'
    },
    serviceType && {
      id: ProductOptionsTypes.USER_CAN_RESCHEDULE,
      name: 'Allow Users to Reschedule Booking',
      description: 'Will allow users to reschedule the booking outside the no-reschedule window'
    },
    serviceType &&
      isMultiday && {
        id: ProductOptionsTypes.ENABLE_CUSTOMIZATIONS_QUANTITY_FACTOR,
        name: 'Enable Variation Price Per Night',
        description: 'Will show the price of the variation per night in the listing and summary'
      },
    serviceType && {
      id: ProductOptionsTypes.SHOW_CUSTOMIZATIONS_PRICE,
      name: 'Show variation pricing from listings & summary',
      description: 'Will show the price of the variation in the listing and summary'
    },
    !serviceType &&
      !subscriptionType && {
        id: ProductOptionsTypes.FUTURE_DELIVERY,
        name: 'Enable Future Delivery',
        description: 'Allow users to select a future delivery date'
      }
  ].filter(Boolean) as { id: string; name: string; description: string }[];

  const filteredItems = optionsToShow ? (optionsToShow.map(option => items.find(item => item.id === option)).filter(Boolean) as { id: string; name: string; description: string }[]) : items;

  useLayoutEffect(() => {
    if (isMultiday || isMultiSlot) {
      setValue(
        'productOptions',
        watchedProductOptions.filter(option => option !== ProductOptionsTypes.ALLOWS_REPEATED_ORDERS && option !== ProductOptionsTypes.ALLOWS_BULK_ORDERS)
      );
    }
  }, [watchedChargeStatus, booking_type]);

  return (
    <WideInputGroup>
      <Controller
        control={control}
        name={`productOptions`}
        defaultValue={defaultValues}
        render={({ onChange, value }) => (
          <FormCheckbox alternate error={errors?.productOptions?.some(error => error)} column itemsArray={filteredItems} onChange={onChange} value={value || []} fontSize={14} />
        )}
      />
    </WideInputGroup>
  );
};

export default ProductOptions;
