import React, { FC } from 'react';
import Filters from '../../../components/Shared/Filters/Filters';
import SearchBar from '../../../components/Shared/Filters/SearchBar';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import { TabsHeaderContainer } from '../../../components/Shared/Shared';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GetAllBreeds, GetAllProducts, GetBranchCategoryByName, GetBranchPetRecordTags, GetBusUserProfile, GetProductKinds, GetVaccRecordTypes } from '../../../queries';
import { vars } from '../../../reactive';
import { managePetsFilters } from '../../../reactive/actions';
import { WEEK_DAYS } from '../../../utils/dates';
import { areObjectsEqual, isUserSuperVisorOrAdminOrSuper } from '../../../utils/helpers';
import { BranchPetRecordTag } from '../../Store/BranchTags/types';
import { PETS_TABS_TYPES } from '../Pets';
import { PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES } from '../PetsFilters';
import PetTagsQuickFilter from './PetTagsQuickFilter';
import { petTypesOptions } from '../../Store/Operations/StoreProductModal/types';

type PetsHeaderProps = {
  filtersControl: FiltersControl<PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES>;
  showFilters?: boolean;
  selectedTab: PETS_TABS_TYPES;
  middleHeaderAction?: React.ReactNode;
};

const petsToolBarButtonsAll = petTypesOptions.map(petType => ({ name: petType.label, id: petType.value }));

const PetsHeader: FC<PetsHeaderProps> = ({ filtersControl, showFilters = true, selectedTab, middleHeaderAction }) => {
  const { mobile } = useMediaQuery({ mobile: true });

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: PETS_FILTER_TYPES.SEARCH,
      moreOption: PETS_MORE_OPTIONS_TYPES.SEARCH
    }
  });

  const { headerAction } = filtersControl;
  const petToolBarButtons = !mobile ? petsToolBarButtonsAll : [];

  return (
    <>
      {!mobile && (
        <ToolBar
          toolBarButtons={petToolBarButtons}
          urlSearchParam={'pet_type'}
          onSearchChange={onChange}
          middleAction={
            <>
              {headerAction}
              {middleHeaderAction}
            </>
          }
          displayAll
          selectOnlyOne
        />
      )}
      {mobile && <SearchBar onSearch={onChange} showAlways />}
      {showFilters && (
        <>
          {!mobile && <Filters filtersControl={filtersControl} />}
          {!mobile && (
            <TabsHeaderContainer padding="12px 20px">
              <PetTagsQuickFilter filtersControl={filtersControl} SelectedTab={selectedTab} />
            </TabsHeaderContainer>
          )}
        </>
      )}
    </>
  );
};

export default PetsHeader;
