import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm, useWatch } from 'react-hook-form';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { AddPetTagButton, PetTagFilterPill, PetTagsQuickFilterContainer, TagsOptionsContainer, TagsQuickFiltersWrapper } from '../../../components/Shared/Filters/styled';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import { FormInput } from '../../../components/Shared/Forms/Forms';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import { VerticallyCenteredLoader } from '../../../components/Shared/Spinner';
import { GetBranchAppUserTags, GetBranchPetRecordTags, GetBranchTagCategories } from '../../../queries';
import { vars } from '../../../reactive';
import BranchTagsModal from '../../Store/BranchTags/BranchTagsModal';
import { BRANCH_TAGS_TYPES, BranchAppUserTag, BranchPetRecordTag } from '../../Store/BranchTags/types';
import { PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES } from '../PetsFilters';

const PetTagItem = ({ form, item }: { form: ControllerRenderProps<Record<string, any>>; item: BranchPetRecordTag }) => {
  const { onChange, value } = form;

  return (
    <PetTagFilterPill>
      <FormInput
        type={'checkbox'}
        onChange={e => {
          if (e.target.checked) {
            onChange([item.id]);
          } else {
            onChange([]);
          }
        }}
        checked={value.includes(item.id)}
      />

      <span style={{ display: 'flex', gap: '10px' }}>
        {item.name} <div>{item.petRecordsCount}</div>
      </span>
    </PetTagFilterPill>
  );
};

type PetTagsQuickFilterProps = {
  filtersControl: FiltersControl<PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES>;
  SelectedTab: string;
};

const PetTagsQuickFilter = ({ filtersControl, SelectedTab }: PetTagsQuickFilterProps) => {
  const { control } = useForm();
  const selectedCategoryId = useReactiveVar(vars.petSelectedCategoryId);

  const selectedQuickFilters = useWatch<string[]>({
    control,
    name: 'selectedQuickFilters',
    defaultValue: []
  });

  const { data: { getBranchTagCategories: tagCategories = [] } = {}, loading: loadingTagCategories } = useQuery<{ getBranchTagCategories: { id: string; name: string }[] }>(GetBranchTagCategories);

  const { data: { getBranchPetRecordTags: allQuickTags = [] } = {}, loading: loadingAllQuickTags } = useQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000, visibility: ['showOnBar'] }
  });

  const {
    data: { getBranchPetRecordTags: categoryTags = [] } = {},
    loading: loadingTagsByCategory,
    refetch: refetchTagsByCategory
  } = useQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    variables: {
      BranchTagCategoryId: [selectedCategoryId],
      offset: 0,
      limit: 1000,
      visibility: ['showOnBar']
    },
    skip: !selectedCategoryId,
    fetchPolicy: 'cache-and-network'
  });

  const [getAllTags, { data: { getBranchAppUserTags: allUserTags = [] } = {}, called: calledTags }] = useLazyQuery<{ getBranchAppUserTags: BranchAppUserTag[] }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const loading = loadingTagCategories || loadingTagsByCategory || loadingAllQuickTags;

  const quickTags = selectedCategoryId ? categoryTags : allQuickTags;

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: PETS_FILTER_TYPES.QUICK_TAGS,
      moreOption: PETS_MORE_OPTIONS_TYPES.TAGS
    },
    transformValues: (values: string[]) => quickTags.filter(({ id }) => values.includes(id)).map(({ id, name }) => ({ value: id, name }))
  });

  useEffect(() => {
    onChange(selectedQuickFilters?.length ? selectedQuickFilters : null);
  }, [selectedQuickFilters]);

  return (
    <TagsQuickFiltersWrapper>
      <PetTagsQuickFilterContainer>
        {loading && <VerticallyCenteredLoader size={25} />}
        {!loading && (
          <>
            <Controller
              name={`selectedQuickFilters`}
              control={control}
              defaultValue={[]}
              render={form => (
                <>
                  {quickTags?.map((item, index) => (
                    <PetTagItem key={index} form={form} item={item} />
                  ))}
                </>
              )}
            />

            <AddPetTagButton onClick={() => ModalDialog.openModal({ content: () => <BranchTagsModal presetType={BRANCH_TAGS_TYPES.PET_RECORD} />, title: 'Add New Smart Tag' })}>
              <span>+ Add</span>
            </AddPetTagButton>
          </>
        )}
      </PetTagsQuickFilterContainer>
      <TagsOptionsContainer>
        <OptionDropdown
          menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
          loading={loading}
          options={[
            {
              id: 'categories',
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              items: [
                {
                  name: 'All',
                  value: 'all',
                  onClick: () => vars.petSelectedCategoryId(null),
                  green: !selectedCategoryId
                },
                ...tagCategories.map(({ id, name }) => ({
                  name,
                  value: id,
                  onClick: () => vars.petSelectedCategoryId(id),
                  green: selectedCategoryId === id
                }))
              ]
            }
          ]}
          noApplyButton
          buttonLoading={loading}
        />
      </TagsOptionsContainer>
    </TagsQuickFiltersWrapper>
  );
};

export default PetTagsQuickFilter;
