import React from 'react';
import { sentenceCase } from 'sentence-case';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { BusUserProfile } from '../../../components/Profile/types';
import { RecordBody, RecordDataContainer } from '../../styled';
import BusUserModal from './BusUserModal';

const BusUserRecord = ({ busUser }: { busUser: BusUserProfile }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <BusUserModal busUser={busUser} />,
      title: 'Staff'
    });
  };

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>{busUser?.name}</RecordBody>
      <RecordBody>{busUser?.email}</RecordBody>
      <RecordBody>{sentenceCase(busUser?.status)}</RecordBody>
    </RecordDataContainer>
  );
};

export default BusUserRecord;
