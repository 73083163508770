import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import useIcons from '../../hooks/useIcons';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { FormButtonsContainer, FormSubmitButton } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';
import { AlertContainer, AlertDescription, AlertDivider, AlertHeader, BackgroundOpacity, CloseAlert } from './styled';

let containerRef: any;
type AlertType = {
  content?: ({ toggleAlert, alert, dissmissAlert }: { toggleAlert: () => void; alert: () => void; dissmissAlert: () => void }) => JSX.Element;
  title?: string;
  description?: string;
  onAccept?: () => void;
  onDeny?: () => void;
  acceptButtonText?: string;
  denyButtonText?: string;
  options?: {
    hideDenyButton?: boolean;
  };
};

const alert = (props: AlertType) => {
  containerRef.setProps(props);
  containerRef.alert();
  return props;
};

const Alert = {
  alert,
  setContainer: (ref: Ref<any>) => (containerRef = ref),
  dissmissAlert: () => containerRef.dissmissAlert(),
  isOpen: () => containerRef.isOpen()
};

export default Alert;

export const AlertElement = forwardRef(({}, ref: React.Ref<any>) => {
  const [visible, setVisible] = useState(false);
  const icons = useIcons();
  const defaultProps = {
    content: () => <></>
  };
  const [props, setProps] = useState<AlertType>(defaultProps);
  const [animationShowStatus, setAnimationShowStatus] = useState(false);

  const { content, title, onAccept, onDeny, acceptButtonText, denyButtonText, description } = props;
  const toggleAlert = () => {
    setVisible(!visible);
  };

  const alert = () => {
    setVisible(true);
  };

  const dissmissAlert = () => {
    setVisible(false);
  };

  const isOpen = () => visible;

  useImperativeHandle(ref, () => ({
    toggleAlert,
    setProps,
    alert,
    dissmissAlert,
    isOpen
  }));

  useEffect(() => {
    if (visible) {
      setAnimationShowStatus(true);
    }
  }, [visible]);

  return (
    <>
      <BackgroundOpacity
        showAlert={visible}
        onAnimationEnd={() => {
          if (!visible) {
            setAnimationShowStatus(false);
            setProps(defaultProps);
          }
        }}
      >
        <AlertContainer>
          <AlertHeader>
            {title}
            <CloseAlert bgImage={icons.closeModal.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => dissmissAlert()} />
          </AlertHeader>
          <AlertDivider />
          {animationShowStatus && (
            <>
              <Container>
                <ModalBody>
                  {description && <AlertDescription>{description}</AlertDescription>}
                  {content && content({ toggleAlert, alert, dissmissAlert })}
                </ModalBody>
              </Container>
              <AlertDivider />
              <ModalFooter>
                <FormButtonsContainer>
                  {!props.options?.hideDenyButton && (
                    <FormSubmitButton
                      onClick={() => {
                        onDeny?.();
                        dissmissAlert();
                      }}
                      black
                      type={'button'}
                    >
                      {denyButtonText || 'Deny'}
                    </FormSubmitButton>
                  )}
                  <FormSubmitButton
                    type={'button'}
                    onClick={() => {
                      onAccept?.();
                      dissmissAlert();
                    }}
                  >
                    {acceptButtonText || 'Accept'}
                  </FormSubmitButton>
                </FormButtonsContainer>
              </ModalFooter>
            </>
          )}
        </AlertContainer>
      </BackgroundOpacity>
    </>
  );
});
