import React from 'react';
import { PetRecordOptionsContainer, PetRecordTagPill, PetRecordTagPillsContainer } from '../../../components/Pets/styled';
import useIcons from '../../../hooks/useIcons';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { BranchAppUser } from '../../Store/BranchBilling/types';
import { BranchAppUserTag } from '../../Store/BranchTags/types';
import {
  AppUserImage,
  AppUserImageAnimation,
  AppUserImageContainer,
  AppUserImageContainerAnimation,
  AppUserImageNameContainer,
  AppUserName,
  BlankAppUserImage,
  RecordBody,
  RecordDataContainer
} from '../../styled';
import AppUserRecordOptions from './AppUserRecordOptions';
import { setDrawerBar } from '../../../reactive/actions';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { UseBulkSelectOutput } from '../../../hooks/useBulkSelect';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Checkbox, CheckboxItemContainer } from '../../../components/Shared/Forms/styled';
import { Icon } from '../../../components/Shared/Forms/Forms';

const BranchAppUserRecord = ({
  branchAppUser,
  appUserQuickTags = [],
  tagsVisibleOnItem = [],
  bulkSelect
}: {
  branchAppUser: BranchAppUser;
  appUserQuickTags?: BranchAppUserTag[];
  tagsVisibleOnItem?: BranchAppUserTag[];
  bulkSelect: UseBulkSelectOutput;
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const icons = useIcons();
  const optionsRef = React.useRef<HTMLDivElement>(null);

  const { show: showBulkSelect, toggleSelectMultiple, isSelected } = bulkSelect || {};

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }

    if (showBulkSelect) {
      toggleSelectMultiple([branchAppUser?.id]);
      return;
    }

    setDrawerBar({
      drawerId: DRAWER_IDS.USER_DRAWER,
      isExtended: true,
      recordData: branchAppUser?.id
    });
  };

  const { id, profile_pic, name } = branchAppUser || {};

  const recordOptions = (
    <PetRecordOptionsContainer>
      <AppUserRecordOptions ref={optionsRef} showAlways={mobile} loadTags={false} appUsers={[branchAppUser]} appUserQuickTags={appUserQuickTags} />
    </PetRecordOptionsContainer>
  );

  const tags = branchAppUser?.BranchAppUserTags || [];
  const tagsToRender = tagsVisibleOnItem ? tags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : tags;

  const renderTags = () => {
    return (
      <PetRecordTagPillsContainer>
        {tagsToRender?.map((item, index) => (
          <PetRecordTagPill color={item.color} key={index}>
            <span>{item.name}</span>
          </PetRecordTagPill>
        ))}
      </PetRecordTagPillsContainer>
    );
  };

  return (
    <RecordDataContainer onClick={handleClick} mobileGap="0" mobilePadding="16px 8px" MobileMinHeight="auto">
      <RecordBody width="200px" mobileWidth="100%">
        <AppUserImageContainer noPadding={!showBulkSelect}>
          <AppUserImageContainerAnimation rotateOption={!!showBulkSelect} noPadding>
            <AppUserImageAnimation key={branchAppUser?.id}>
              {branchAppUser?.profile_pic && <AppUserImage src={branchAppUser?.profile_pic} index={0} />}
              {!branchAppUser?.profile_pic && <GatsbyImage image={icons.user.childImageSharp.gatsbyImageData} alt="user" />}
            </AppUserImageAnimation>
            <AppUserImageAnimation absolute>
              <CheckboxItemContainer
                checked={isSelected(branchAppUser?.id)}
                noMargin
                style={{
                  justifyContent: 'center'
                }}
              >
                <Checkbox>
                  <Icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                  </Icon>
                </Checkbox>
              </CheckboxItemContainer>
            </AppUserImageAnimation>
          </AppUserImageContainerAnimation>
        </AppUserImageContainer>
      </RecordBody>
      {!mobile && (
        <>
          <RecordBody>{branchAppUser?.email}</RecordBody>
          <RecordBody>{renderTags()}</RecordBody>
        </>
      )}
      {mobile && (
        <RecordBody mobileWidth="100%">
          <RecordBody mobileFlexDirection="column">
            <RecordBody mobileWidth="100%" marginLeft="85" fontWeight="800">
              {name}
            </RecordBody>
            <RecordBody mobileWidth="100%" marginLeft="85">
              {branchAppUser?.email}
            </RecordBody>
          </RecordBody>

          {recordOptions}
        </RecordBody>
      )}
      {!mobile && recordOptions}
    </RecordDataContainer>
  );
};

export default BranchAppUserRecord;
