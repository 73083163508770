import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import SideTabs, { SIDE_TABS_TYPES } from '../../../components/Shared/SideTabs/SideTabs';
import { AddBranchVehicle, EditBranchVehicle, GetBranchVehicle } from '../../../queries';
import { BranchVehicle, VEHICLE_MODAL_SECTIONS, VEHICLE_MODAL_TABS_TYPES } from '../types';
import BranchVehicleModalRoutes from './BranchVehicleModalRoutes';
import BranchVehicleModalSettings from './BranchVehicleModalSettings';
import { BranchVehicleFormValues, BranchVehicleRoutes, capacitySizeTypes } from './types';
import { FormSubmitButton } from '../../../components/Shared/Forms/Forms';

const BranchVehicleModal = ({ branchVehicle: defaultBranchVehicle }: { branchVehicle?: BranchVehicle }) => {
  const tabsState = useState(VEHICLE_MODAL_TABS_TYPES.ROUTES);

  const [currentTab] = tabsState;
  const { data: { getBranchVehicles: defaultBranchVehicles = [] } = {} } = useQuery<{ getBranchVehicles: BranchVehicle[] }>(GetBranchVehicle, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: defaultBranchVehicle?.id ? [defaultBranchVehicle.id] : []
    },
    skip: !defaultBranchVehicle?.id
  });

  const branchVehicle = defaultBranchVehicles[0] || defaultBranchVehicle;

  const [addBranchVehicle, { data: newBranchVehicle, loading: loadingAddBranchVehicle }] = useMutation(AddBranchVehicle, {
    refetchQueries: ['getBranchVehicles'],
    awaitRefetchQueries: true
  });

  const [editBranchVehicle, { data: editedBranchVehicle, loading: loadingEditBranchVehicle }] = useMutation(EditBranchVehicle, {
    refetchQueries: ['getBranchVehicles'],
    awaitRefetchQueries: true
  });

  const loading = loadingAddBranchVehicle || loadingEditBranchVehicle;
  const added = !!newBranchVehicle || !!editedBranchVehicle;

  const formOptions = useForm<BranchVehicleFormValues>({
    defaultValues: {
      branchVehicle: {
        BranchVehicleRoutes: branchVehicle?.BranchVehicleRoutes?.map(branchVehicleRoute => ({
          BranchRouteId: branchVehicleRoute?.BranchRoute?.id || '',
          BusUserId: branchVehicleRoute?.BusUsers?.map(busUser => busUser?.id) || []
        })),
        daily_capacity: capacitySizeTypes.map(size => ({
          capacity: branchVehicle?.daily_capacity?.find(capacity => capacity.size === size) ? branchVehicle?.daily_capacity?.find(capacity => capacity.size === size)?.capacity : 0,
          size
        })),
        color: branchVehicle?.color || '#000000',
        image: branchVehicle?.image || '',
        name: branchVehicle?.name || '',
        plate_number: branchVehicle?.plate_number || '',
        status: branchVehicle?.status || 'ACTIVE',
        type: branchVehicle?.type || 'VAN'
      }
    },
    shouldUnregister: false
  });

  const { control, handleSubmit } = formOptions;

  const branchVehicleRoutesFieldArray = useFieldArray<BranchVehicleRoutes>({
    control,
    name: 'branchVehicle.BranchVehicleRoutes'
  });

  const validate = (data: BranchVehicleFormValues) => {
    const hasRoutes = !!data.branchVehicle.BranchVehicleRoutes?.length;
    const allRoutesHaveData = data.branchVehicle.BranchVehicleRoutes?.every(route => route?.BranchRouteId && !!route?.BusUserId?.length);
    const routesIds = data.branchVehicle.BranchVehicleRoutes?.map(route => route?.BranchRouteId);
    const allRoutesAreUnique = new Set(routesIds)?.size === routesIds?.length;
    const hasName = !!data.branchVehicle.name;
    const hasPlateNumber = !!data.branchVehicle.plate_number;
    const hasColor = !!data.branchVehicle.color;
    const hasType = !!data.branchVehicle.type;
    const hasStatus = !!data.branchVehicle.status;
    const hasCapacity = !!data.branchVehicle.daily_capacity?.length;
    const allCapacityHaveData = data.branchVehicle.daily_capacity?.every(capacity => capacity?.capacity !== undefined);

    return hasRoutes && allRoutesHaveData && hasName && hasPlateNumber && hasColor && hasType && hasStatus && hasCapacity && allCapacityHaveData && allRoutesAreUnique;
  };

  const onSubmit = handleSubmit(data => {
    if (!validate(data)) {
      return;
    }

    const variables = {
      ...data.branchVehicle,
      daily_capacity: data.branchVehicle.daily_capacity?.map(capacity => ({
        size: capacity.size,
        capacity: Number(capacity.capacity)
      }))
    };

    if (!branchVehicle?.id) {
      addBranchVehicle({ variables });
      return;
    }

    editBranchVehicle({
      variables: {
        ...variables,
        id: branchVehicle.id
      }
    });
  });

  useEffect(() => {
    if (added) {
      ModalDialog.closeModal();
    }
  }, [added]);

  return (
    <ModalLayout
      sideTabs={<SideTabs sections={VEHICLE_MODAL_SECTIONS} tabsState={tabsState} type={SIDE_TABS_TYPES.STATE_BUTTONS} isModal backgroundColor="f4f4f4" />}
      buttons={<FormSubmitButton onClick={onSubmit}>{loading ? 'Saving...' : 'Save'}</FormSubmitButton>}
    >
      <>
        {currentTab === VEHICLE_MODAL_TABS_TYPES.ROUTES && <BranchVehicleModalRoutes formOptions={formOptions} branchVehicleRoutesFieldArray={branchVehicleRoutesFieldArray} />}
        {currentTab === VEHICLE_MODAL_TABS_TYPES.SETTINGS && <BranchVehicleModalSettings formOptions={formOptions} />}
      </>
    </ModalLayout>
  );
};

export default BranchVehicleModal;
