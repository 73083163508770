import { GatsbyImage } from 'gatsby-plugin-image';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { BackgroundOpacity } from '../../components/Modal/styled';
import { SlideDown, SlideDownActionsContainer } from '../../components/Shared/Shared';
import { ActivityIndicator } from '../../components/Shared/Spinner';
import useBulkSelect from '../../hooks/useBulkSelect';
import useIcons from '../../hooks/useIcons';
import { ActionBtn } from '../Store/styled';
import { Anything } from '../../utils/helpers';

type ItemSlideDownActionsProps = {
  bulkSelect: ReturnType<typeof useBulkSelect>;
  items: Anything[];
  children?: React.ReactNode;
};

export type ItemSlideDownActionsRef = {
  handleOnClickAction?: () => void;
};

const ItemSlideDownActions = forwardRef<ItemSlideDownActionsRef, ItemSlideDownActionsProps>(({ bulkSelect, items, children }, ref) => {
  const icons = useIcons();
  const { toggleShow, show: showBulkActions } = bulkSelect;
  const closeImage = icons?.closeTransparentBlack?.childImageSharp?.gatsbyImageData;
  const toggleCheckbox = icons?.toggleCheckbox?.childImageSharp?.gatsbyImageData;
  const [loading, setLoading] = useState(false);

  const handleOnClickAction = () => {
    setLoading(true);
    setTimeout(() => {
      toggleShow();
      setLoading(false);
    }, 2000);
  };

  useImperativeHandle(ref, () => ({
    handleOnClickAction
  }));

  return (
    <SlideDownActionsContainer gap={items?.length ? 10 : 0}>
      <SlideDown show={showBulkActions} loadingData={loading}>
        {loading && (
          <>
            <ActivityIndicator size={20} />
            <span>Applying Collar Magic...</span>
          </>
        )}
        {!loading && (
          <ActionBtn onClick={toggleShow} noMargin>
            <GatsbyImage alt="select button" image={closeImage} />
          </ActionBtn>
        )}
        {!loading && <span>{items?.length ? `${items.length} Selected` : 'Select Items'}</span>}
        <SlideDownActionsContainer gap={10}>{!loading && children}</SlideDownActionsContainer>
      </SlideDown>
      <BackgroundOpacity showModal={loading} style={{ zIndex: 9 }} />
      {!showBulkActions && !loading && (
        <ActionBtn onClick={toggleShow} noMargin>
          <GatsbyImage alt="select button" image={toggleCheckbox} />
        </ActionBtn>
      )}
    </SlideDownActionsContainer>
  );
});

export default ItemSlideDownActions;
